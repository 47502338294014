import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatPaginator, MatSnackBar, MatSort} from '@angular/material';
import {LocationTypesTableDatasource} from './location-types-table-datasource';
import {tap} from "rxjs/operators";
import {AreYouSureDialogComponent} from "../../ui/are-you-sure-dialog/are-you-sure-dialog.component";
import {LocationTypesService} from "../../../common/location-types.service";

@Component({
    selector: 'app-location-types-table',
    templateUrl: './location-types-table.component.html',
    styleUrls: ['./location-types-table.component.scss']
})
export class LocationTypesTableComponent implements OnInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    dataSource: LocationTypesTableDatasource;
    pageCount = 0;

    /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
    displayedColumns = ['name', 'actions'];

    constructor(private locationTypesService: LocationTypesService,
                private snackbar: MatSnackBar,
                private dialog: MatDialog) {

    }

    ngOnInit() {
        this.dataSource = new LocationTypesTableDatasource(this.locationTypesService);
        this.dataSource.load(1, 25);
    }

    ngAfterViewInit(): void {
        this.paginator.page
            .pipe(
                tap(() => this.loadPage())
            )
            .subscribe();
    }

    loadPage() {
        this.dataSource.load(this.paginator.pageIndex + 1, this.paginator.pageSize);
    }

    onDelete(row) {
        const dialogRef = this.dialog.open(AreYouSureDialogComponent, {
            width: '600px',
            data: {id: row.id, service: this.locationTypesService}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.deleted) {
                this.loadPage();
                this.snackbar.open("Location Type deleted successfully", "Close", {
                    duration: 3000
                });
            }
        });
    }
}
