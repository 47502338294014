import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {Observable} from "rxjs";
import {map, tap} from "rxjs/operators";

export interface Customer {
    id: number;
    name: string;
}

@Injectable({
    providedIn: 'root'
})
export class CustomerService {
    constructor(protected apiService: ApiService) {
    }

    public search(name = null, extraParams: object = {}) {

        
        if(name == null || name instanceof Object) {
            name = ""
        }
        
        let options = {
            params: {
                search: name,
                ...extraParams
            }
        }

        return this.apiService.get('customers', options).pipe(
            tap((response: any) => {
                response.results = response.data
                    .map(customer => {
                        return { id: customer.id, name: customer.name }
                    });

                return response;
            })
        )

    }

    public find(pageNumber = 1, limit = 25, filters = null): any {
        let options = {
            params: {
                page: pageNumber.toString(),
                limit: limit.toString(),
            }
        };

        if (filters !== null) {
            if (filters.sortOrder) {
                options.params['sort_order'] = filters.sortOrder;
            }

            if (filters.sortBy) {
                options.params['sort_by'] = filters.sortBy;
            }

            if (filters.search) {
                options.params['search'] = filters.search;
            }

            if (filters.otr) {
                options.params['is_otr'] = true;
            }
        }

        return this.apiService.get('customers', options);
    }

    public findOne(id) {
        return this.apiService.get('customers/' + id).pipe(
            map(res => res["data"])
        );
    }

    public saveOrUpdate(payload: { name: string }, id = null) {
        if (id == null) {
            return this.apiService.post('customers', payload)
        }

        return this.apiService.put('customers/' + id, payload);
    }

    public destroy(id) {
        return this.apiService.delete('customers/' + id);
    }
}
