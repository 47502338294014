import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {catchError, finalize } from 'rxjs/operators';
import {Observable, BehaviorSubject, of} from 'rxjs';
import {Training, TrainingService} from "../../../common/training.service";


/**
 * Data source for the TrainingsTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class TrainingsTableDataSource implements DataSource<Training> {

    private trainingsSubject = new BehaviorSubject<Training[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();
    public pageCount = 0;

    constructor(private trainingService: TrainingService) {
    }

    connect(collectionViewer: CollectionViewer): Observable<Training[]> {
        return this.trainingsSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.trainingsSubject.complete();
        this.loadingSubject.complete();
    }

    load(page: number, limit: number) {
        this.loadingSubject.next(true);

        this.trainingService.find('asc', page, limit).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
            .subscribe((trainings: any) => {
                this.pageCount = trainings.meta.total;
                this.trainingsSubject.next(trainings.data)
            });
    }
}
