import { ApiService } from './api.service';
import { tap } from 'rxjs/internal/operators/tap';
import * as Sentry from '@sentry/browser';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { throwError } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "./authentication.service";
var SessionService = /** @class */ (function () {
    function SessionService(apiService, authService) {
        this.apiService = apiService;
        this.authService = authService;
        this._user = null;
    }
    Object.defineProperty(SessionService.prototype, "user", {
        get: function () {
            return this._user;
        },
        set: function (user) {
            this._user = user;
            // Add a little extra sentry context
            Sentry.configureScope(function (scope) {
                scope.setUser({
                    id: user.id,
                    role: user.role,
                    title: user.title,
                    email: user.email,
                });
            });
        },
        enumerable: true,
        configurable: true
    });
    SessionService.prototype.fetch = function () {
        var _this = this;
        return this.apiService.get('me').pipe(catchError(function (error) {
            if (error.status === 401) {
                _this.authService.logout();
            }
            return throwError(error);
        }), tap(function (response) {
            _this.user = response;
        }));
    };
    SessionService.ngInjectableDef = i0.defineInjectable({ factory: function SessionService_Factory() { return new SessionService(i0.inject(i1.ApiService), i0.inject(i2.AuthenticationService)); }, token: SessionService, providedIn: "root" });
    return SessionService;
}());
export { SessionService };
