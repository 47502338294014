import { AbstractControl } from "@angular/forms";

export class PasswordValidation {
    static MatchPassword(abstractControl: AbstractControl) {
        let password = abstractControl.get('new_password').value;
        let confirmPassword = abstractControl.get('new_password_confirmation').value;

        if(password !== confirmPassword) {
            abstractControl.get('new_password_confirmation').setErrors( { matchPassword: true } );
        } else {
            return null;
        }
    }
}