import { Injectable } from '@angular/core';
import {ApiService} from "./api.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

export interface Training {
  name: string;
  id: number;
}

@Injectable({
  providedIn: 'root'
})
export class TrainingService {
  constructor(protected apiService: ApiService) {
  }

  public find(sortOrder = 'asc', pageNumber = 1, limit = 10) {


    return this.apiService.get('trainings', {
      params: {
        page: pageNumber.toString(),
        limit: limit.toString()
      }
    });
  }

  public findOne(id) {
    return this.apiService.get('trainings/' + id).pipe(
        map(res => res["data"])
    );
  }

  public saveOrUpdate(payload: { name: string }, id = null) {
    if (id == null) {
      return this.apiService.post('trainings', payload)
    }

    return this.apiService.put('trainings/' + id, payload);
  }

  public destroy(id) {
    return this.apiService.delete('trainings/' + id);
  }
}
