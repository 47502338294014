import {Injectable} from '@angular/core';
import {ApiService} from './api.service';

@Injectable({
    providedIn: 'root'
})
export class TruckBoardService {
    constructor(protected apiService: ApiService) {}

    public getArrivalTime(pageNumber = 1, limit = 25, params = {}) {
        let options = {
            params: {
                ...params,
                page: pageNumber.toString(),
                limit: limit.toString(),
            }
        };

        return this.apiService.get('truck-board/arrival-time', options);
    }

    public getDriverTime(pageNumber = 1, limit = 25, params = {}) {
        let options = {
            params: {
                ...params,
                page: pageNumber.toString(),
                limit: limit.toString(),
            }
        };

        return this.apiService.get('truck-board/arrival-time', options);
    }
}
