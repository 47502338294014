<div class="wrapper no-background">
    <ng-container *ngIf="trainings.length == 0">
        <div style="text-align: center">
            <p class="no-items">No Trainings</p>
            <button (click)="openTrainingDialog()" mat-fab color="primary">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </ng-container>

    <ng-container *ngIf="trainings.length > 0">
        <h2 class="has-items">Trainings</h2>
        <button (click)="openTrainingDialog()" mat-fab color="primary" class="add-training">
            <mat-icon>add</mat-icon>
        </button>

        <div class="clearfix" style="margin-bottom: 50px"></div>

        <mat-card *ngFor="let training of trainings" style="margin-bottom: 15px">
            <mat-card-header>
                <mat-card-title-group>
                    <mat-card-title>
                        {{ training.name }}
                    </mat-card-title>
                    <mat-card-subtitle>Expires On: {{ training.pivot.expires_at }}</mat-card-subtitle>
                </mat-card-title-group>

                <div fxFlex></div>
                <button mat-button (click)="onEdit(training)">EDIT</button>
                <button mat-button color="warn" (click)="onDelete(training)">DELETE</button>
            </mat-card-header>
        </mat-card>

    </ng-container>
</div>
