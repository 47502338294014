import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSnackBar, MatSort } from '@angular/material';
import { WellsTableDataSource } from './wells-table-datasource';
import { WellService } from '../../../common/well.service';
import { AreYouSureDialogComponent } from '../../ui/are-you-sure-dialog/are-you-sure-dialog.component';
import { tap, debounceTime } from 'rxjs/operators';
import { FormBuilder } from '@angular/forms';

@Component({
    selector: 'app-wells-table',
    templateUrl: './wells-table.component.html',
    styleUrls: ['./wells-table.component.scss']
})
export class WellsTableComponent implements OnInit, AfterViewInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    dataSource: WellsTableDataSource;

    /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
    displayedColumns = ['id', 'name', 'rig', 'is_active', 'actions'];

    public filterForm;
    public otr = false;

    constructor(private wellService: WellService,
                private snackbar: MatSnackBar,
                private formBuilder: FormBuilder,
                private dialog: MatDialog) {

    }

    ngOnInit() {
        this.dataSource = new WellsTableDataSource(this.wellService);
        this.dataSource.loadWells(1, 25);

        this.filterForm = this.formBuilder.group({
            status: 'all',
            search: '',
            isOtr: false
        });

        this.filterForm.get('search').valueChanges.pipe(
            debounceTime(600)
            ).subscribe(() => {
            if ((this.filterForm).valid) {
                this.applyFilters();
        }});

        this.filterForm.get('status').valueChanges.pipe(
            debounceTime(50)
            ).subscribe(() => {
            if ((this.filterForm).valid) {
                this.applyFilters();
        }});

        this.filterForm.get('isOtr').valueChanges.pipe(
            debounceTime(50)
            ).subscribe(() => {
            if ((this.filterForm.valid)) {
                this.applyFilters();
        }});

        this.sort.sortChange.subscribe((e) => {
            if (e.direction === '') {
                this.dataSource.filters['sortBy'] = undefined;
                this.dataSource.filters['sortOrder'] = undefined;
            } else {
                this.dataSource.filters['sortBy'] = e.active;
                this.dataSource.filters['sortOrder'] = e.direction;
            }

            this.loadPage();
        });
    }

    ngAfterViewInit(): void {
        this.paginator.page
            .pipe(
                tap(() => this.loadPage())
            )
            .subscribe();
    }

    loadPage() {
        this.dataSource.loadWells(this.paginator.pageIndex + 1, this.paginator.pageSize);
    }

    onDelete(row) {
        const dialogRef = this.dialog.open(AreYouSureDialogComponent, {
            width: '600px',
            data: { id: row.id, service: this.wellService }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.deleted) {
                this.loadPage();
                this.snackbar.open('Well deleted successfully', 'Close', {
                    duration: 3000
                });
            }
        });
    }

    applyFilters() {
        for (let filter in this.filterForm.value) {
            this.dataSource.filters[filter] = this.filterForm.value[filter];
        }
        this.dataSource.loadWells(1, 25);
    }
}
