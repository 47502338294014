<div>
  <mat-card>
    <mat-card-title class="card-title">
      Certification Notifications
    </mat-card-title>

    <mat-card-content>
      <div *ngFor="let certNotification of certNotifications">
        <div class="user-wrapper">
          <p class="user">
              {{certNotification.user.first_name}} {{certNotification.user.last_name}} | ID: {{certNotification.user.id}}
          </p>
          <div class="remove-user">
            <button [disabled]="disabled" (click)="removeUser(certNotification)" mat-icon-button><mat-icon>close</mat-icon></button>
          </div>
        </div>
      </div>
    </mat-card-content>

    <mat-card-footer>
        <mat-paginator #paginator
        [length]="total"
        [pageSize]="5"
        (page)="pageEvent($event)">
        </mat-paginator>
    </mat-card-footer>

  </mat-card>
</div>
