/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./rigs.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "./rigs-table/rigs-table.component.ngfactory";
import * as i5 from "./rigs-table/rigs-table.component";
import * as i6 from "../../common/rig.service";
import * as i7 from "@angular/material/dialog";
import * as i8 from "@angular/material/snack-bar";
import * as i9 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i10 from "@angular/material/button";
import * as i11 from "@angular/cdk/platform";
import * as i12 from "@angular/cdk/a11y";
import * as i13 from "@angular/platform-browser/animations";
import * as i14 from "@angular/router";
import * as i15 from "@angular/common";
import * as i16 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i17 from "@angular/material/icon";
import * as i18 from "./rigs.component";
var styles_RigsComponent = [i0.styles];
var RenderType_RigsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RigsComponent, data: {} });
export { RenderType_RigsComponent as RenderType_RigsComponent };
export function View_RigsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["fxLayout", "column"], ["style", "width: 80%; margin: auto"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Rigs"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-rigs-table", [], null, null, null, i4.View_RigsTableComponent_0, i4.RenderType_RigsTableComponent)), i1.ɵdid(5, 4308992, null, 0, i5.RigsTableComponent, [i6.RigService, i7.MatDialog, i8.MatSnackBar], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 6, "a", [["class", "new-button"], ["color", "primary"], ["mat-fab", ""]], [[1, "tabindex", 0], [1, "disabled", 0], [1, "aria-disabled", 0], [2, "_mat-animation-noopable", null], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7)._haltDisabledEvents($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i9.View_MatAnchor_0, i9.RenderType_MatAnchor)), i1.ɵdid(7, 180224, null, 0, i10.MatAnchor, [i11.Platform, i12.FocusMonitor, i1.ElementRef, [2, i13.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(8, 671744, null, 0, i14.RouterLinkWithHref, [i14.Router, i14.ActivatedRoute, i15.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(9, 1), (_l()(), i1.ɵeld(10, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i16.View_MatIcon_0, i16.RenderType_MatIcon)), i1.ɵdid(11, 9158656, null, 0, i17.MatIcon, [i1.ElementRef, i17.MatIconRegistry, [8, null], [2, i17.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["add"]))], function (_ck, _v) { var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); _ck(_v, 5, 0); var currVal_7 = "primary"; _ck(_v, 7, 0, currVal_7); var currVal_8 = _ck(_v, 9, 0, "./new"); _ck(_v, 8, 0, currVal_8); _ck(_v, 11, 0); }, function (_ck, _v) { var currVal_1 = (i1.ɵnov(_v, 7).disabled ? (0 - 1) : (i1.ɵnov(_v, 7).tabIndex || 0)); var currVal_2 = (i1.ɵnov(_v, 7).disabled || null); var currVal_3 = i1.ɵnov(_v, 7).disabled.toString(); var currVal_4 = (i1.ɵnov(_v, 7)._animationMode === "NoopAnimations"); var currVal_5 = i1.ɵnov(_v, 8).target; var currVal_6 = i1.ɵnov(_v, 8).href; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_9 = i1.ɵnov(_v, 11).inline; var currVal_10 = (((i1.ɵnov(_v, 11).color !== "primary") && (i1.ɵnov(_v, 11).color !== "accent")) && (i1.ɵnov(_v, 11).color !== "warn")); _ck(_v, 10, 0, currVal_9, currVal_10); }); }
export function View_RigsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-rigs", [], null, null, null, View_RigsComponent_0, RenderType_RigsComponent)), i1.ɵdid(1, 114688, null, 0, i18.RigsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RigsComponentNgFactory = i1.ɵccf("app-rigs", i18.RigsComponent, View_RigsComponent_Host_0, {}, {}, []);
export { RigsComponentNgFactory as RigsComponentNgFactory };
