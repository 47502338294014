import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    private _token = null;

    private _user = null;

    get user() {
        return this._user;
    }

    set user(user) {
        this._user = user;
    }

    get token() {
        if(this._token !== null) {
            return this._token;
        }

        this.setTokenFromLocalStorage();

        return this._token;
    }

    set token(token) {
        this._token = token;
    }

    constructor() {}

    public setTokenFromLocalStorage()
    {
        this.token = localStorage.getItem('gstyle.token');
    }

    public logout() {
        localStorage.clear();

        const loginUrl = environment.login_url;
        const clientId = environment.api_client_id;
        window.location.href = `${loginUrl}/redirect?client_id=${clientId}`;
    }
}
