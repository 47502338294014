<h1 mat-dialog-title>Add/Edit Training</h1>
<div mat-dialog-content>
    <ngx-loading [show]="loading"></ngx-loading>
    <form class="materials-edit-form" [formGroup]="form" (ngSubmit)="onSubmit()">
        <mat-form-field style="width: 100%">
            <mat-select cdkFocusInitial placeholder="Training" [formControlName]="'training'">
                <mat-option *ngFor="let training of trainings" [value]="training.id">{{training.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('training').hasError('required') && submitted">
                Training is required.
            </mat-error>
        </mat-form-field>

        <mat-form-field>
            <input matInput [matDatepicker]="picker" placeholder="Expiration date"
                   [formControlName]="'expiration_date'">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="form.get('expiration_date').hasError('required') && submitted">
                Expiration date is required.
            </mat-error>
        </mat-form-field>

    </form>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="onSubmit()">Save</button>
</div>