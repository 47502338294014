<h2 mat-dialog-title>Edit Fields</h2>
<mat-dialog-content class="pop-up-box" [formGroup]="formGroup">

  <mat-form-field>
    <mat-label>Weather Conditions</mat-label>
    <textarea matInput formControlName="weather_conditions"></textarea>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Equipment Type</mat-label>    
    <input matInput placeholder="Equipment Type" formControlName="equipment_type">
  </mat-form-field>

  <mat-form-field>
    <textarea matInput placeholder="Customer Name/Info" formControlName="customer_name_info" type="text"></textarea>
  </mat-form-field>  

  <mat-form-field>
    <mat-label>Authorized Company Representative Safety Concerns</mat-label>
    <textarea matInput formControlName="safety_concerns"></textarea>
  </mat-form-field>

  <mat-accordion class="accordion">
  <mat-expansion-panel class="expansion-panel" *ngIf="formGroup.get('other_ppe')?.controls.length > 0">
    <mat-expansion-panel-header>
      <mat-panel-title [className]="formGroup.get('other_ppe').invalid ? 'error-highlight' : '' ">
        Other PPE
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div formArrayName="other_ppe" *ngFor="let ppe of formGroup.get('other_ppe')?.controls">
      
      <mat-form-field class="list-field">
        <input matInput [formControl]="ppe">
      </mat-form-field>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel class="expansion-panel" *ngIf="formGroup.get('other_key_items')?.controls.length > 0">
    <mat-expansion-panel-header>
        <mat-panel-title [className]="formGroup.get('other_key_items').invalid ? 'error-highlight' : '' ">
          Other Key Items
        </mat-panel-title>
      </mat-expansion-panel-header>
    <div formArrayName="other_key_items" *ngFor="let item of formGroup.get('other_key_items')?.controls">
      <mat-form-field class="list-field">
        <input matInput [formControl]="item">
      </mat-form-field>
    </div>
  </mat-expansion-panel>
  
  <mat-expansion-panel class="expansion-panel" formArrayName="tasks" *ngFor="let task of formGroup.get('tasks')?.controls; let i = index">
    <mat-expansion-panel-header>
        <mat-panel-title [className]="task.invalid ? 'error-highlight' : '' ">
          Task {{formGroup.get('tasks').controls.indexOf(task) + 1}} 
        </mat-panel-title>
    </mat-expansion-panel-header>
    <div [formGroupName]="i">
      <mat-card>
        <div class="input-flex-box">
          <mat-form-field class="task-item">
            <mat-label>Task Title</mat-label> 
            <input matInput formControlName="taskTitle" placeholder="Task Title">
          </mat-form-field>

          <mat-form-field class="task-item">
            <mat-label>Hazard</mat-label> 
            <input matInput formControlName="hazard" placeholder="Hazard">
          </mat-form-field>
          
          <mat-form-field class="task-item">
            <mat-label>Controls</mat-label> 
            <input matInput formControlName="controls" placeholder="Controls">
          </mat-form-field>
        </div>  

      </mat-card> 
    </div>
  </mat-expansion-panel>
  </mat-accordion>

</mat-dialog-content>
<mat-dialog-actions>
  <button mat-dialog-close class="cancel-button" mat-stroked-button>Cancel</button>
  <button (click)="saveChanges()" class="save-button" mat-stroked-button>Save</button>
</mat-dialog-actions>
