import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CompanyService} from '../../common/company.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-edit-company',
    templateUrl: './edit-company.component.html',
    styleUrls: ['./edit-company.component.scss']
})
export class EditCompanyComponent implements OnInit {

    public companyForm: FormGroup;
    public mode: 'new' | 'edit' = 'new';
    public id?: number = null;
    public loading = true;

    public logo;
    public submitted = false;

    constructor(private formBuilder: FormBuilder,
                private companyService: CompanyService,
                private snackbar: MatSnackBar,
                private router: Router,
                private route: ActivatedRoute) {

    }

    ngOnInit() {
        this.companyForm = this.formBuilder.group({
            name: [null, Validators.compose([Validators.required, Validators.maxLength(255)])],
            address: [null , Validators.maxLength(255)],
            phone_number: [null, Validators.maxLength(255)]
        });

        const idParam = this.route.snapshot.params['id'];

        if (idParam && idParam !== 'new') {
            this.mode = 'edit';
            this.id = +idParam;
            this.findCompany(this.id);
        } else {
            this.loading = false;
        }
    }

    findCompany(id: number) {
        this.companyService.findOne(id).pipe(
            finalize(() => this.loading = false)
        ).subscribe(response => {
            this.companyForm.patchValue({
                name: response.name,
                address: response.address,
                phone_number: response.phone_number,
            });
            if (response.logo) {
                this.companyService.findOneLogo(response.logo).subscribe(logoResponse => {
                    this.logo = logoResponse;
                });
            }
        });
    }

    onSubmit() {
        this.submitted = true;

        if (!this.companyForm.valid) {
            return;
        }

        let payload = this.companyForm.value;

        payload.logo_data = this.logo || null;

        this.loading = true;

        this.saveOrUpdate(payload);
    }

    saveOrUpdate(payload) {
        this.companyService.saveOrUpdate(payload, this.id).pipe(
            finalize(() => this.loading = false)
        ).subscribe(() => {
            this.snackbar.open('Company saved successfully', 'Close', {
                duration: 3000
            });

            this.router.navigate(['../'], {
                relativeTo: this.route
            });
        }, () => {
            this.snackbar.open('Unable to save company', 'Close', {
                duration: 3000
            });
        });
    }

    selectLogo(event) {
        let reader = new FileReader();
        if (event.target.files && event.target.files.length > 0) {
            let file = event.target.files[0];
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                this.logo = reader.result;
            };
        }
    }

    removeLogo() {
        this.logo = null;
    }
}
