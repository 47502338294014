import { ApiService } from "./api.service";
import { map } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
var MaterialService = /** @class */ (function () {
    function MaterialService(apiService) {
        this.apiService = apiService;
    }
    MaterialService.prototype.find = function (pageNumber, limit, filters) {
        if (pageNumber === void 0) { pageNumber = 1; }
        if (limit === void 0) { limit = 10; }
        if (filters === void 0) { filters = null; }
        var options = {
            params: {
                page: pageNumber.toString(),
                limit: limit.toString(),
            }
        };
        if (filters !== null) {
            if (filters.sortOrder) {
                options.params['sort_order'] = filters.sortOrder;
            }
            if (filters.sortBy) {
                options.params['sort_by'] = filters.sortBy;
            }
            if (filters.search) {
                options.params['search'] = filters.search;
            }
            if (filters.otr) {
                options.params['is_otr'] = true;
            }
        }
        return this.apiService.get('materials', options);
    };
    MaterialService.prototype.findOne = function (id) {
        return this.apiService.get('materials/' + id).pipe(map(function (res) { return res["data"]; }));
    };
    MaterialService.prototype.saveOrUpdate = function (payload, id) {
        if (id === void 0) { id = null; }
        if (id == null) {
            return this.apiService.post('materials', payload);
        }
        return this.apiService.put('materials/' + id, payload);
    };
    MaterialService.prototype.destroy = function (id) {
        return this.apiService.delete('materials/' + id);
    };
    MaterialService.ngInjectableDef = i0.defineInjectable({ factory: function MaterialService_Factory() { return new MaterialService(i0.inject(i1.ApiService)); }, token: MaterialService, providedIn: "root" });
    return MaterialService;
}());
export { MaterialService };
