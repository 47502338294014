import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {CertificationService} from "../../common/certification.service";
import {TrailerType, TrailerTypeService} from "../../common/trailer-type.service";
import {MatSnackBar} from "@angular/material";
import {CustomerService} from "../../common/customer.service";
import { finalize } from 'rxjs/operators';
import { SearchableEndpoint } from '../search-select/search-select.component';

@Component({
    selector: 'app-edit-certification',
    templateUrl: './edit-certification.component.html',
    styleUrls: ['./edit-certification.component.scss']
})
export class EditCertificationComponent implements OnInit {

    public form: FormGroup;
    public mode: 'new' | 'edit' = 'new';
    public id?: number = null;
    public loading: boolean = true;
    public trailerTypes = [];
    public customers = [];
    public submitted = false;

    public showCustomerSelect = false;
    public showTrailerTypeSelect = false;

    constructor(private formBuilder: FormBuilder,
                private certificationService: CertificationService,
                private customerService: CustomerService,
                private trailerTypeService: TrailerTypeService,
                private snackbar: MatSnackBar,
                private router: Router,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            name: ['', Validators.required],
            type: ['none', Validators.required],
            trailer_type: [''],
            customer: ['']
        });

        const idParam = this.route.snapshot.params['id'];

        if (idParam && idParam !== 'new') {
            this.mode = 'edit';
            this.id = +idParam;
            this.find(this.id);
        } else {
            this.loading = false;
        }

        this.trailerTypeService.find().subscribe((response: any) => {
            this.trailerTypes = response.data;
        });

        this.customerService.find().subscribe((response: any) => {
            this.customers = response.data;
        });

        this.form.get('type').valueChanges.subscribe(value => {
            if(value === 'customer') {
                this.showTrailerTypeSelect = false;
                this.showCustomerSelect = true;
                this.form.get('customer').setValidators([
                    Validators.required
                ]);
                this.form.get('customer').updateValueAndValidity();
                this.form.get('trailer_type').clearValidators();
                this.form.get('trailer_type').setErrors(null);

                this.form.patchValue({
                    trailer_type: null,
                });
            } else if(value === 'trailer_type') {
                this.showTrailerTypeSelect = true;
                this.showCustomerSelect = false;

                this.form.get('customer').clearValidators();
                this.form.get('customer').setErrors(null);
                this.form.get('trailer_type').setValidators([Validators.required]);
                this.form.get('trailer_type').updateValueAndValidity();

                this.form.patchValue({
                    customer: null,
                });
            } else {
                this.showTrailerTypeSelect = false;
                this.showCustomerSelect = false;
                this.form.get('customer').clearValidators();
                this.form.get('customer').setErrors(null);
                this.form.get('trailer_type').setValidators([]);
                this.form.get('trailer_type').setErrors(null);
                this.form.patchValue({
                    customer: null,
                    trailer_type: null
                });
            }
        });
    }

    getEndpointTrailerType(): SearchableEndpoint {
        return ((search: String, page = 1) => {
            return this.trailerTypeService.find('asc', page, 25, search);
        }).bind(this);
    }

    getEndpointCustomer(): SearchableEndpoint {
        return ((search: String, page = 1) => {
            return this.customerService.search(search);
        }).bind(this);
    }

    find(id: number) {
        this.certificationService.findOne(id).pipe(
            finalize(() => this.loading = false)
        ).subscribe(response => {
            this.form.patchValue({
                name: response.name,
                type: response.type,
            });

            if (response.type && response.trailer_type_id) {
                this.trailerTypeService.findOne(response.trailer_type_id).subscribe((type) => {
                    this.form.patchValue({trailer_type: type});
                });
            }
            if (response.customer_id) {
                this.customerService.findOne(response.customer_id).subscribe((customer) => {
                    this.form.patchValue({customer: customer});
                });
            }
        });
    }

    onSubmit() {
        this.submitted = true;

        if( ! this.form.valid) {
            return;
        }

        let payload = this.form.value;

        if (payload.trailer_type) {
            payload.trailer_type_id = payload.trailer_type.id;
        }
        if (payload.customer) {
            payload.customer_id = payload.customer.id;
        }

        Object.keys(payload).forEach((key) => (payload[key] == null) && delete payload[key]);

        this.loading = true;
        this.certificationService.saveOrUpdate(payload, this.id).pipe(
            finalize(() => this.loading = false)
        ).subscribe(response => {
            this.snackbar.open('Certification saved successfully', 'Close', {
                duration: 3000
            });

            this.router.navigate(['../'], {
                relativeTo: this.route
            });

        }, () => {
            this.snackbar.open('Unable to save certification', 'Close', {
                duration: 3000
            });
        });
    }
}
