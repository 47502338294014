import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, Injectable, InjectionToken, Injector, NgModule } from '@angular/core';
import { getErrorHandler } from './common/SentryErrorHandler';
import { HttpErrorInterceptor } from './common/http-error.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule as GStyleCommonModule } from './common/common.module';
import { RedirectComponent } from './redirect/redirect.component';
import { DispatchModule } from './dispatch/dispatch.module';
import { AuthenticationModule } from './authentication/authentication.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { MatTableModule, MatPaginatorModule, MatSortModule } from '@angular/material';
import * as Sentry from '@sentry/browser';

@NgModule({
    declarations: [
        AppComponent,
        RedirectComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        GStyleCommonModule,
        DispatchModule,
        AuthenticationModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule
    ],
    providers: [
        {
            provide: ErrorHandler,
            useFactory: getErrorHandler
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
