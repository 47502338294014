<div class="avatar-form-container">

    <p class="avatar-label">Avatar</p>
    <img alt="User avatar" *ngIf="user?.avatar_url === null" class="edit-user-avatar" src="/assets/images/default-avatar.png" />
    <img alt="User avatar" *ngIf="user?.avatar_url !== null" class="edit-user-avatar" [src]="user.avatar_url" />
    <button class="select-user-avatar" mat-button type="button" (click)="imageInput.click()">Select Image</button>

    <br style="clear: both" />

    <label class="image-upload-container">
        <input #imageInput
               type="file"
               accept="image/*"
               (change)="fileChangeEvent($event)">
    </label>

    <div fxLayout="row">
        <div fxFlex="50">
            <p class="header" *ngIf="croppedImage">Crop</p>
            <image-cropper
                    [imageChangedEvent]="imageChangedEvent"
                    [resizeToWidth]="64"
                    format="png"
                    [roundCropper]="true"
                    [aspectRatio]="1"
                    [maintainAspectRatio]="true"
                    (imageCropped)="imageCropped($event)"
                    (imageLoaded)="imageLoaded()"
                    (cropperReady)="cropperReady()"
                    (loadImageFailed)="loadImageFailed()"
            ></image-cropper>

        </div>
        <div class="preview">
            <p *ngIf="croppedImage" class="header">Preview</p>
            <img [src]="croppedImage" style="border-radius: 100%"/>
        </div>
    </div>

    <button *ngIf="croppedImage"  type="button" mat-button color="primary" (click)="onSave()">Save Avatar</button>
</div>
