import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
var KeepTruckinUserService = /** @class */ (function () {
    function KeepTruckinUserService(apiService) {
        this.apiService = apiService;
    }
    KeepTruckinUserService.prototype.search = function (pageNumber, limit, params) {
        if (pageNumber === void 0) { pageNumber = 1; }
        if (limit === void 0) { limit = 25; }
        if (params === void 0) { params = {}; }
        var options = {
            params: tslib_1.__assign({}, params, { page: pageNumber.toString(), limit: limit.toString() })
        };
        return this.apiService.get('keep-truckin/users', options);
    };
    KeepTruckinUserService.prototype.searchTrucks = function (pageNumber, limit, params) {
        if (pageNumber === void 0) { pageNumber = 1; }
        if (limit === void 0) { limit = 25; }
        if (params === void 0) { params = {}; }
        var options = {
            params: tslib_1.__assign({}, params, { page: pageNumber.toString(), limit: limit.toString() })
        };
        return this.apiService.get('keep-truckin/vehicles', options);
    };
    KeepTruckinUserService.prototype.getTruck = function (keepTruckinId) {
        return this.apiService.get("keep-truckin/vehicles/" + keepTruckinId);
    };
    KeepTruckinUserService.prototype.truckLocation = function (truck) {
        return this.apiService.get("keep-truckin/linked-trucks/" + truck + "/location");
    };
    KeepTruckinUserService.prototype.truckLocations = function (truckIds, page, limit) {
        if (truckIds === void 0) { truckIds = null; }
        if (page === void 0) { page = 1; }
        if (limit === void 0) { limit = 25; }
        var params = {
            page: page,
            limit: limit
        };
        if (truckIds) {
            params['keep_truckin_ids'] = truckIds;
        }
        return this.apiService.get('keep-truckin/linked-trucks/locations', { params: params });
    };
    KeepTruckinUserService.prototype.availableTime = function (driverIds, page, limit) {
        if (driverIds === void 0) { driverIds = []; }
        if (page === void 0) { page = 1; }
        if (limit === void 0) { limit = 25; }
        return this.apiService.get('keep-truckin/available-time', {
            params: {
                driver_ids: driverIds,
                page: page,
                limit: limit,
            }
        });
    };
    KeepTruckinUserService.prototype.driverLocations = function (driverIds, page, limit) {
        if (driverIds === void 0) { driverIds = []; }
        if (page === void 0) { page = 1; }
        if (limit === void 0) { limit = 25; }
        return this.apiService.get('keep-truckin/driver-locations', {
            params: {
                driver_ids: driverIds,
                page: page,
                limit: limit,
            }
        });
    };
    KeepTruckinUserService.prototype.getVehicles = function () {
        //
    };
    KeepTruckinUserService.ngInjectableDef = i0.defineInjectable({ factory: function KeepTruckinUserService_Factory() { return new KeepTruckinUserService(i0.inject(i1.ApiService)); }, token: KeepTruckinUserService, providedIn: "root" });
    return KeepTruckinUserService;
}());
export { KeepTruckinUserService };
