import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TrailerTypeService } from '../../common/trailer-type.service';
import { MaterialService } from '../../common/material.service';
import { MatSnackBar } from '@angular/material';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-edit-trailer-type',
    templateUrl: './edit-trailer-type.component.html',
    styleUrls: ['./edit-trailer-type.component.scss']
})
export class EditTrailerTypeComponent implements OnInit {
    public form: FormGroup;
    public mode: 'new' | 'edit' = 'new';
    public id?: number = null;
    public loading: boolean = true;
    public materials = [];
    public submitted = false;

    constructor(private formBuilder: FormBuilder,
                private trailerTypeService: TrailerTypeService,
                private materialService: MaterialService,
                private snackbar: MatSnackBar,
                private router: Router,
                private route: ActivatedRoute) {

    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            name: ['', Validators.required],
            materials: ''
        });

        const idParam = this.route.snapshot.params['id'];

        if (idParam && idParam !== 'new') {
            this.mode = 'edit';
            this.id = +idParam;
            this.find(this.id);
        } else {
            this.loading = false;
        }

        this.materialService.find().subscribe((response: any) => {
            this.materials = response.data;
        });
    }

    find(id: number) {
        this.trailerTypeService.findOne(id).pipe(
            finalize(() => this.loading = false)
        ).subscribe(response => {
            this.form.patchValue({
                name: response.name,
                materials: response.materials.map(material => {
                    return material.id;
                })
            });
        });
    }

    onSubmit() {
        this.submitted = true;

        if (!this.form.valid) {
            return;
        }

        const payload = this.form.value;

        this.loading = true;
        this.trailerTypeService.saveOrUpdate(payload, this.id).pipe(
            finalize(() => this.loading = false)
        ).subscribe(response => {
            this.snackbar.open('Trailer type saved successfully', 'Close', {
                duration: 3000
            });

            this.router.navigate(['../'], {
                relativeTo: this.route
            });
        }, () => {
            this.snackbar.open('Unable to save trailer type', 'Close', {
                duration: 3000
            });
        });
    }
}
