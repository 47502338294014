import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material";
import {ActivatedRoute, Router} from "@angular/router";
import {LocationTypesService} from "../../common/location-types.service";
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-edit-location-type',
    templateUrl: './edit-location-type.component.html',
    styleUrls: ['./edit-location-type.component.scss']
})
export class EditLocationTypeComponent implements OnInit {

    public form: FormGroup;
    public mode: 'new' | 'edit' = 'new';
    public id?: number = null;
    public loading: boolean = true;
    public submitted = false;

    constructor(private formBuilder: FormBuilder,
                private locationTypeService: LocationTypesService,
                private snackbar: MatSnackBar,
                private router: Router,
                private route: ActivatedRoute) {

    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            name: ['', Validators.required]
        });

        const idParam = this.route.snapshot.params['id'];

        if (idParam && idParam !== 'new') {
            this.mode = 'edit';
            this.id = +idParam;
            this.find(this.id);
        } else {
            this.loading = false;
        }
    }

    find(id: number) {
        this.locationTypeService.findOne(id).pipe(
            finalize(() => this.loading = false)
        ).subscribe(response => {
            this.form.patchValue({
                name: response.name
            });
        });
    }

    onSubmit() {
        this.submitted = true;

        if ( ! this.form.valid) {
            return;
        }

        const payload = this.form.value;


        this.loading = true;
        this.locationTypeService.saveOrUpdate(payload, this.id).pipe(
            finalize(() => this.loading = false)
        ).subscribe(response => {
            this.snackbar.open("Location type saved successfully", "Close", {
                duration: 3000
            });

            this.router.navigate(['../'], {
                relativeTo: this.route
            });

        }, () => {
            this.snackbar.open("Unable to save location type", "Close", {
                duration: 3000
            });
        });
    }


}
