import { FormControl } from "@angular/forms";

export function WhitespaceValidator(): any {
    return (control: FormControl): { [key: string]: any } => {
        let errors = {};
        if(control.value !== null && control.value !== '') {
            if (String((control.value || '')).replace(/\s+/g, '') === '') {
                errors['invalidWhitespace'] = 'This field cannot be empty';
                return errors;
            }
        }
        return null;
    }
}

export function RouteValidator(): any {
    return (control: FormControl): { [key: string]: any } => {

        let errors = {};

        switch (control.value.pickup_type) {
            case 'address':
                if (!control.value.pickup_address) {
                    errors['invalidPickup'] = 'Pickup address is not valid.';
                }
                break;
            case 'well':
                if (!control.value.pickup_well) {
                    errors['invalidPickup'] = 'Pickup well is not valid.';
                }
                break;
            case 'location':
                if (!control.value.pickup_dynamic_location) {
                    errors['invalidPickup'] = 'Pickup location is not valid';
                }
        }

        if (control.value.pickup_type && typeof control.value.pickup_type === 'object') {
            if (!control.value.pickup_dynamic_location) {
                errors['invalidPickup'] = 'Pickup location is not valid.';
            }
        }

        switch (control.value.dropoff_type) {
            case 'address':
                if (!control.value.dropoff_address) {
                    errors['invalidDropoff'] = 'Dropoff address is not valid.';
                }
                break;
            case 'well':
                if (!control.value.dropoff_well) {
                    errors['invalidDropoff'] = 'Dropoff well is not valid.';
                }
                break;
            case 'location':
                if (!control.value.dropoff_dynamic_location) {
                    errors['invalidDropoff'] = 'Pickup location is not valid';
                }
        }

        if (control.value.dropoff_type && typeof control.value.dropoff_type === 'object') {
            if (!control.value.dropoff_dynamic_location) {
                errors['invalidDropoff'] = 'Dropoff location is not valid.';
            }
        }

        if (Object.entries(errors).length !== 0 ) {
            return errors;
        }

        return null;
    };
}

export function IntValidator(): any {
    return (control: FormControl): { [key: string]: any } => {

        if (control.value === null || control.value === '' || parseFloat(control.value) === parseInt(control.value) && !isNaN(control.value)) {
            return null;
        }

        return { invalidInt: true };
    };
}

export function NegativeValidator(): any {
    return (control: FormControl): { [key: string]: any } => {
        if (control.value === null || control.value === '' || parseFloat(control.value) > 0) {
            return null;
        }
        return {invalidNegative: true};
    }
}

export function NumValidator(): any {
    return (control: FormControl): { [key: string]: any } => {

        if (control.value === null || control.value === '') {
            return null;
        }

        return isNaN(control.value) ? { invalidNum: true } : null;
    };
}
