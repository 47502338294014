import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";
import { map } from "rxjs/operators";
import { Customer } from "./customer.service";

export interface Rig {
    id: number;
    name: string;
    customer: Customer
}

@Injectable({
    providedIn: 'root'
})
export class RigService {
    constructor(protected apiService: ApiService) {
    }

    public find(pageNumber = 1, limit = 10, filters = null) {

        let options = {
            params: {
                page: pageNumber.toString(),
                limit: limit.toString(),
            }
        };

        if (filters !== null) {
            if (filters.sortOrder) {
                options.params['sort_order'] = filters.sortOrder;
            }

            if (filters.sortBy) {
                options.params['sort_by'] = filters.sortBy;
            }

            if (filters.search) {
                options.params['search'] = filters.search;
            }

            if (filters.customerId) {
                options.params['customer'] = filters.customerId;
            }

            if (filters.sortByCustomer) {
                options.params['sort_by_customer'] = filters.sortByCustomer;
            }

            if(filters.is_otr) {
                options.params['is_otr'] = true;
            }
        }

        return this.apiService.get('rigs', options);
    }

    public findOne(id) {
        return this.apiService.get('rigs/' + id).pipe(
            map(res => res['data'])
        );
    }

    public saveOrUpdate(payload: { name: string }, id = null) {
        if (id == null) {
            return this.apiService.post('rigs', payload)
        }

        return this.apiService.put('rigs/' + id, payload);
    }

    public destroy(id) {
        return this.apiService.delete('rigs/' + id);
    }
}
