import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-billing-types',
  templateUrl: './billing-types.component.html',
  styleUrls: ['./billing-types.component.scss']
})
export class BillingTypesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
