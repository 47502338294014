import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CustomerService} from "../../common/customer.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material";
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-edit-customer',
    templateUrl: './edit-customer.component.html',
    styleUrls: ['./edit-customer.component.scss']
})
export class EditCustomerComponent implements OnInit {

    public customerForm: FormGroup;
    public mode: 'new' | 'edit' = 'new';
    public id?: number = null;
    public loading: boolean = true;

    public submitted = false;

    constructor(private formBuilder: FormBuilder,
                private customerService: CustomerService,
                private snackbar: MatSnackBar,
                private router: Router,
                private route: ActivatedRoute) {

    }

    ngOnInit() {
        this.customerForm = this.formBuilder.group({
            name: ['', Validators.required],
            is_otr: false
        });

        const idParam = this.route.snapshot.params['id'];

        if (idParam && idParam !== 'new') {
            this.mode = 'edit';
            this.id = +idParam;
            this.findCustomer(this.id);
        } else {
            this.loading = false;
        }
    }

    findCustomer(id: number) {
        this.customerService.findOne(id).pipe(
            finalize(() => this.loading = false)
        ).subscribe(response => {
            this.customerForm.patchValue({
                name: response.name,
                is_otr: Boolean(response.is_otr)
            });
        });
    }

    onSubmit() {
        this.submitted = true;

        if( ! this.customerForm.valid) {
            return;
        }

        const payload = this.customerForm.value;

        if(this.mode === 'edit') {
            delete payload.is_otr
        }

        this.loading = true;
        this.customerService.saveOrUpdate(payload, this.id).pipe(
            finalize(() => this.loading = false)
        ).subscribe((response: any) => {
            this.snackbar.open("Customer saved successfully", "Close", {
                duration: 3000
            });

            this.router.navigate(['../'], {
                relativeTo: this.route
            });
        }, () => {
            this.snackbar.open("Unable to save customer", "Close", {
                duration: 3000
            });
        });
    }

}
