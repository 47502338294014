import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {catchError, finalize} from 'rxjs/operators';
import {Observable, BehaviorSubject, of} from 'rxjs';
import {Customer, CustomerService} from "../../../common/customer.service";


/**
 * Data source for the CustomersTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class CustomersTableDataSource implements DataSource<Customer> {
    private customersSubject = new BehaviorSubject<Customer[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();
    public pageCount = 0;

    constructor(private customerService: CustomerService) {
    }



    connect(collectionViewer: CollectionViewer): Observable<Customer[]> {
        return this.customersSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.customersSubject.complete();
        this.loadingSubject.complete();
    }

    load(page: number, limit: number, filters: object = null) {
        this.loadingSubject.next(true);

        this.customerService.find(page, limit, filters).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
            .subscribe((customers: any) =>  {
                this.pageCount = customers.meta.total;
                this.customersSubject.next(customers.data)
            });
    }
}