import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";
import { Observable, of, ReplaySubject } from "rxjs";
import { map, shareReplay } from "rxjs/operators";

export interface Trailer {
    name: string;
    id: number;
}

@Injectable({
    providedIn: 'root'
})
export class TrailerService {

    private trailerSubject = new ReplaySubject(1);

    private cachedGetAll$ = null;


    get trailers() {
        return this.trailerSubject;
    }

    constructor(protected apiService: ApiService) {
        this.getAll();
    }

    public find(sortOrder = 'asc', pageNumber = 1, limit = 25) {

        return this.apiService.get('trailers', {
            params: {
                page: pageNumber.toString(),
                limit: limit.toString()
            }
        });
    }

    public findFiltered(sortOrder = 'asc', pageNumber = 1, limit = 25, filters = null): Observable<any> {

        let options = {
            params: {
                page: pageNumber.toString(),
                limit: limit.toString(),
            }
        };

        if (filters !== undefined && filters !== null) {
            if (filters.search !== undefined && filters.search !== null) {
                options.params['search'] = filters.search;
            }

            if (filters.driver_id !== undefined && filters.driver_id !== null) {
                options.params['driver_id'] = filters.driver_id;
            }

            if (filters.bol_id !== undefined && filters.bol_id !== null) {
                options.params['bol_id'] = filters.bol_id;
            }

            if (filters.job_id !== undefined && filters.job_id !== null) {
                options.params['job_id'] = filters.job_id;
            }

            if (filters.available_at) {
                options.params['available_at'] = filters.available_at;
            }
        }

        return this.apiService.get('bol-trailers', options);
    }

    public getAll() {
        if (this.cachedGetAll$ === null) {
            this.cachedGetAll$ = this.apiService.get('trailers').pipe(shareReplay(1));
        }

        return this.cachedGetAll$;
    }

    public findOne(id) {
        return this.apiService.get('trailers/' + id).pipe(
            map(res => res['data'])
        );
    }

    public saveOrUpdate(payload: { name: string }, id = null) {
        if (id == null) {
            return this.apiService.post('trailers', payload);
        }

        return this.apiService.put('trailers/' + id, payload);
    }

    public destroy(id) {
        return this.apiService.delete('trailers/' + id);
    }
}
