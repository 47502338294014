import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSnackBar, MatSort } from '@angular/material';
import { UsersTableDataSource } from './users-table-datasource';
import { UserService } from '../../../common/user.service';
import { tap, debounceTime } from 'rxjs/operators';
import { AreYouSureDialogComponent } from '../../ui/are-you-sure-dialog/are-you-sure-dialog.component';
import { FormBuilder } from '@angular/forms';
import { SessionService } from '../../../common/session.service';

@Component({
    selector: 'app-users-table',
    templateUrl: './users-table.component.html',
    styleUrls: ['./users-table.component.scss']
})
export class UsersTableComponent implements OnInit, AfterViewInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    dataSource: UsersTableDataSource;

    filterForm = null;

    pageCount = 0;
    public user;

    /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
    displayedColumns = ['id', 'first_name', 'last_name', 'email', 'role', 'actions'];

    constructor(private userService: UserService,
                private dialog: MatDialog,
                private sessionService: SessionService,
                private formBuilder: FormBuilder,
                private snackbar: MatSnackBar) {
    }

    ngOnInit() {
        this.user = this.sessionService.user;
        this.dataSource = new UsersTableDataSource(this.userService);
        this.dataSource.loadUsers(1, 25);

        this.filterForm = this.formBuilder.group({
            search: null,
            role: 'all'
        });

        this.filterForm.get('search').valueChanges.pipe(
            debounceTime(600)
            ).subscribe(() => {
            if ((this.filterForm).valid) {
                this.applyFilters();
        }});

        this.filterForm.get('role').valueChanges.pipe(
            debounceTime(50)
            ).subscribe(() => {
            if ((this.filterForm).valid) {
                this.applyFilters();
        }});

        this.sort.sortChange.subscribe((e) => {
            if (e.direction === '') {
                this.dataSource.filters['sortBy'] = undefined;
                this.dataSource.filters['sortOrder'] = undefined;
            } else {
                this.dataSource.filters['sortBy'] = e.active;
                this.dataSource.filters['sortOrder'] = e.direction;
            }

            this.loadPage();
        });
    }

    ngAfterViewInit(): void {
        this.paginator.page
            .pipe(
                tap(() => this.loadPage())
            )
            .subscribe();
    }

    loadPage() {
        this.dataSource.loadUsers(this.paginator.pageIndex + 1, this.paginator.pageSize);
    }

    onDelete(row) {
        const dialogRef = this.dialog.open(AreYouSureDialogComponent, {
            width: '600px',
            data: {id: row.id, service: this.userService}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.deleted) {
                this.loadPage();
                this.snackbar.open('User deleted successfully', 'Close', {
                    duration: 3000
                });
            }
        });
    }

    applyFilters() {
        this.dataSource.filters.search = this.filterForm.get('search').value;
        this.dataSource.filters.role = this.filterForm.get('role').value;
        this.dataSource.loadUsers(1, this.paginator.pageSize);
    }
}
