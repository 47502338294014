import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
var JobService = /** @class */ (function () {
    function JobService(apiService) {
        this.apiService = apiService;
    }
    JobService.prototype.getJobs = function (options) {
        if (options === void 0) { options = {}; }
        return this.apiService.get('jobs', options);
    };
    JobService.prototype.findOne = function (id, params) {
        if (params === void 0) { params = null; }
        var options = {};
        if (params) {
            options['params'] = params;
        }
        return this.apiService.get('jobs/' + id, options).pipe(map(function (res) { return res['data']; }));
    };
    JobService.prototype.saveOrUpdate = function (payload, id) {
        if (id === void 0) { id = null; }
        if (id == null) {
            return this.apiService.post('jobs', payload);
        }
        return this.apiService.put('jobs/' + id, payload);
    };
    JobService.prototype.finalize = function (id) {
        return this.apiService.put('jobs/' + id, {
            is_complete: true
        });
    };
    JobService.ngInjectableDef = i0.defineInjectable({ factory: function JobService_Factory() { return new JobService(i0.inject(i1.ApiService)); }, token: JobService, providedIn: "root" });
    return JobService;
}());
export { JobService };
export function sleep(time) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        return tslib_1.__generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve) { return setTimeout(resolve, time); })];
        });
    });
}
