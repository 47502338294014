import { ApiService } from "./api.service";
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
var LocationsService = /** @class */ (function () {
    function LocationsService(apiService) {
        this.apiService = apiService;
    }
    LocationsService.prototype.find = function (sortOrder, pageNumber, limit, filters) {
        if (sortOrder === void 0) { sortOrder = 'asc'; }
        if (pageNumber === void 0) { pageNumber = 1; }
        if (limit === void 0) { limit = 10; }
        if (filters === void 0) { filters = null; }
        var options = {
            params: {
                page: pageNumber.toString(),
                limit: limit.toString(),
            }
        };
        if (filters !== undefined && filters !== null) {
            if (filters.search !== undefined && filters.search !== null) {
                options.params['search'] = filters.search;
            }
            if (filters.type !== undefined && filters.type !== null) {
                options.params['type'] = filters.type;
            }
        }
        return this.apiService.get('locations', options);
    };
    LocationsService.prototype.getByType = function (type, search) {
        return this.apiService.get('locations', {
            params: {
                type: type,
                search: search,
                mode: 'autocomplete'
            }
        });
    };
    LocationsService.prototype.findOne = function (id) {
        return this.apiService.get('locations/' + id);
    };
    LocationsService.prototype.saveOrUpdate = function (payload, id) {
        if (id === void 0) { id = null; }
        if (id == null) {
            return this.apiService.post('locations', payload);
        }
        return this.apiService.put('locations/' + id, payload);
    };
    LocationsService.prototype.destroy = function (id) {
        return this.apiService.delete('locations/' + id);
    };
    LocationsService.ngInjectableDef = i0.defineInjectable({ factory: function LocationsService_Factory() { return new LocationsService(i0.inject(i1.ApiService)); }, token: LocationsService, providedIn: "root" });
    return LocationsService;
}());
export { LocationsService };
