import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {MatPaginator, MatSort} from '@angular/material';
import {catchError, finalize, map} from 'rxjs/operators';
import {Observable, of as observableOf, merge, BehaviorSubject, of} from 'rxjs';
import {MaterialService} from "../../../common/material.service";

export interface Material {
    name: string;
    id: number;
}

/**
 * Data source for the MaterialsTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class MaterialsTableDataSource implements DataSource<Material> {

    private materialsSubject = new BehaviorSubject<Material[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();

    public pageCount = 0;

    constructor(private materialService: MaterialService) {

    }

    connect(collectionViewer: CollectionViewer): Observable<Material[]> {
        return this.materialsSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.materialsSubject.complete();
        this.loadingSubject.complete();
    }

    loadMaterials(page: number, limit: number, filters: object = null) {
        this.loadingSubject.next(true);

        this.materialService.find(page, limit, filters).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
            .subscribe((materials: any) => {
                this.pageCount = materials.meta.total;
                this.materialsSubject.next(materials.data)
            });
    }
}

