import { ApiService } from "./api.service";
import { map } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
var BillingTypeService = /** @class */ (function () {
    function BillingTypeService(apiService) {
        this.apiService = apiService;
    }
    BillingTypeService.prototype.find = function (pageNumber, limit, filters) {
        if (pageNumber === void 0) { pageNumber = 1; }
        if (limit === void 0) { limit = 10; }
        if (filters === void 0) { filters = null; }
        var options = {
            params: {
                page: pageNumber.toString(),
                limit: limit.toString(),
            }
        };
        if (filters !== null) {
            if (filters.sortOrder !== undefined && filters.sortOrder !== null) {
                options.params['sort_order'] = filters.sortOrder;
            }
            if (filters.sortBy !== undefined && filters.sortBy !== null) {
                options.params['sort_by'] = filters.sortBy;
            }
            if (filters.search !== undefined && filters.search !== null && filters.search !== '') {
                options.params['search'] = filters.search;
            }
        }
        return this.apiService.get('billing-types', options);
    };
    BillingTypeService.prototype.findOne = function (id) {
        return this.apiService.get('billing-types/' + id).pipe(map(function (res) { return res["data"]; }));
    };
    BillingTypeService.prototype.saveOrUpdate = function (payload, id) {
        if (id === void 0) { id = null; }
        if (id == null) {
            return this.apiService.post('billing-types', payload);
        }
        return this.apiService.put('billing-types/' + id, payload);
    };
    BillingTypeService.prototype.destroy = function (id) {
        return this.apiService.delete('billing-types/' + id);
    };
    BillingTypeService.ngInjectableDef = i0.defineInjectable({ factory: function BillingTypeService_Factory() { return new BillingTypeService(i0.inject(i1.ApiService)); }, token: BillingTypeService, providedIn: "root" });
    return BillingTypeService;
}());
export { BillingTypeService };
