import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {KeepTruckinUserService} from '../../../common/keep-truckin-user.service';
import { SearchableEndpoint } from '../../search-select/search-select.component';

@Component({
    selector: 'app-keep-truckin-dialog',
    templateUrl: './keep-truckin-dialog.component.html',
    styleUrls: ['./keep-truckin-dialog.component.scss']
})
export class KeepTruckinDialogComponent implements OnInit {

    public form;
    public filteredUsers = [];
    public isLoading = false;

    constructor(
        private formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<KeepTruckinDialogComponent>,
        private keepTruckinUserService: KeepTruckinUserService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            user: null
        });
    }

    displayFn(user) {
        if (user) { return user.first_name; }
    }

    onSubmit() {
        this.dialogRef.close({ selected: true, driver: this.form.value.user});
    }

    onNoClick(): void {
        this.dialogRef.close({ selected: false});
    }

    formatFieldName() {
        return (user) => {
            if (user) {
                return (user.last_name + ', ' + user.first_name +
                    '  |  ' + user.email +
                    '  |  Keep Truckin Id: ' + user.keep_truckin_id
                );
            }
        };
    }

    getEndpoint(): SearchableEndpoint {
        return ((search: String, page = 1) => {
            return this.keepTruckinUserService.search(page, 25, { search: search });
        }).bind(this);
      }
}
