<ngx-loading-bar></ngx-loading-bar>
<mat-toolbar>
    <img alt="G-Style" src="/assets/images/logo.png" style="width: 150px" />
    <span fxFlex></span>

    <app-notification-dropdown></app-notification-dropdown>

    <button mat-button #userMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="userMenu">
        <img alt="User avatar" *ngIf="user.avatar_url === null" class="user-avatar" src="/assets/images/default-avatar.png" />
        <img alt="User avatar" *ngIf="user.avatar_url !== null" class="user-avatar" [src]="user.avatar_url" />
        Welcome, {{ user.first_name }}
        <i class="material-icons">
            keyboard_arrow_down
        </i>
    </button>

    <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
        <a [routerLink]="['/dispatch/profile']" mat-menu-item>Profile</a>
        <a [routerLink]="['/auth/logout']" mat-menu-item>Logout</a>
    </mat-menu>

</mat-toolbar>
<mat-toolbar class="bottom-toolbar">
    <div>
        <button mat-button #jobMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="jobMenu">
            Job
            <i class="material-icons">
                keyboard_arrow_down
            </i>
        </button>
        <mat-menu #jobMenu="matMenu" [overlapTrigger]="false">
            <a mat-button routerLink="/dispatch/jobs/new">Create A Job</a>
            <a mat-button routerLink="/dispatch/jobs/new" [queryParams]="{is_otr: true}">Create An OTR Job</a>
            <a mat-button routerLink="/dispatch/jobs" [queryParams]="{ assigned_to: user.id, status: 'open' }">Check Jobs</a>
            <a mat-button routerLink="/dispatch/jobs" [queryParams]="{ assigned_to: user.id, status: 'finalized' }">Review Jobs</a>
        </mat-menu>
        
        <button mat-button #vehicleMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="vehicleMenu">
            Vehicle
            <i class="material-icons">
                keyboard_arrow_down
            </i>
        </button>
        <mat-menu #vehicleMenu="matMenu" [overlapTrigger]="false">
            <a mat-button routerLink="/dispatch/trucks">Trucks</a>
            <a mat-button routerLink="/dispatch/trailers">Trailers</a>
            <a mat-button routerLink="/dispatch/trailer-types">Trailer Types</a>
            <a mat-button routerLink="/dispatch/truck-snapshot">View Truck Board</a>
        </mat-menu>
        
        <button mat-button #locationMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="locationMenu">
            Location
            <i class="material-icons">
                keyboard_arrow_down
            </i>
        </button>
        <mat-menu #locationMenu="matMenu" [overlapTrigger]="false">
            <a mat-button routerLink="/dispatch/locations">Locations</a>
            <a mat-button routerLink="/dispatch/location-types">Location Types</a>
        </mat-menu>
        
        <button mat-button #peopleMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="peopleMenu">
            People
            <i class="material-icons">
                keyboard_arrow_down
            </i>
        </button>
        <mat-menu #peopleMenu="matMenu" [overlapTrigger]="false">
            <a mat-button routerLink="/dispatch/trainings">Trainings</a>
            <a mat-button routerLink="/dispatch/certifications">Certifications</a>
            <a mat-button routerLink="/dispatch/users">Users</a>
        </mat-menu>

        <button mat-button #workMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="workMenu">
            Work
            <i class="material-icons">
                keyboard_arrow_down
            </i>
        </button>
        <mat-menu #workMenu="matMenu" [overlapTrigger]="false">
                <a mat-button routerLink="/dispatch/materials">Materials</a>
                <a mat-button routerLink="/dispatch/customers">Customers</a>
                <a mat-button routerLink="/dispatch/rigs">Rigs</a>
                <a mat-button routerLink="/dispatch/wells">Wells</a>
                <a mat-button routerLink="/dispatch/billing-types">Billing Types</a>
                <a mat-button routerLink="/dispatch/companies">Companies</a>
        </mat-menu>


    </div>
</mat-toolbar>
<router-outlet></router-outlet>
