/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./trainings.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i8 from "@angular/material/icon";
import * as i9 from "../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i10 from "@angular/material/card";
import * as i11 from "@angular/flex-layout/flex";
import * as i12 from "@angular/flex-layout/core";
import * as i13 from "@angular/common";
import * as i14 from "./trainings.component";
import * as i15 from "../../../common/training-user.service";
import * as i16 from "@angular/material/dialog";
var styles_UserTrainingsComponent = [i0.styles];
var RenderType_UserTrainingsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserTrainingsComponent, data: {} });
export { RenderType_UserTrainingsComponent as RenderType_UserTrainingsComponent };
function View_UserTrainingsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["style", "text-align: center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [["class", "no-items"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No Trainings"])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "button", [["color", "primary"], ["mat-fab", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openTrainingDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(5, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(6, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i7.View_MatIcon_0, i7.RenderType_MatIcon)), i1.ɵdid(7, 9158656, null, 0, i8.MatIcon, [i1.ElementRef, i8.MatIconRegistry, [8, null], [2, i8.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["add"]))], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 5, 0, currVal_2); _ck(_v, 7, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 5).disabled || null); var currVal_1 = (i1.ɵnov(_v, 5)._animationMode === "NoopAnimations"); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 7).inline; var currVal_4 = (((i1.ɵnov(_v, 7).color !== "primary") && (i1.ɵnov(_v, 7).color !== "accent")) && (i1.ɵnov(_v, 7).color !== "warn")); _ck(_v, 6, 0, currVal_3, currVal_4); }); }
function View_UserTrainingsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "mat-card", [["class", "mat-card"], ["style", "margin-bottom: 15px"]], null, null, null, i9.View_MatCard_0, i9.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i10.MatCard, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 17, "mat-card-header", [["class", "mat-card-header"]], null, null, null, i9.View_MatCardHeader_0, i9.RenderType_MatCardHeader)), i1.ɵdid(3, 49152, null, 0, i10.MatCardHeader, [], null, null), (_l()(), i1.ɵeld(4, 0, null, 2, 7, "mat-card-title-group", [["class", "mat-card-title-group"]], null, null, null, i9.View_MatCardTitleGroup_0, i9.RenderType_MatCardTitleGroup)), i1.ɵdid(5, 49152, null, 0, i10.MatCardTitleGroup, [], null, null), (_l()(), i1.ɵeld(6, 0, null, 0, 2, "mat-card-title", [["class", "mat-card-title"]], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i10.MatCardTitle, [], null, null), (_l()(), i1.ɵted(8, null, [" ", " "])), (_l()(), i1.ɵeld(9, 0, null, 0, 2, "mat-card-subtitle", [["class", "mat-card-subtitle"]], null, null, null, null, null)), i1.ɵdid(10, 16384, null, 0, i10.MatCardSubtitle, [], null, null), (_l()(), i1.ɵted(11, null, ["Expires On: ", ""])), (_l()(), i1.ɵeld(12, 0, null, 2, 1, "div", [["fxFlex", ""]], null, null, null, null, null)), i1.ɵdid(13, 671744, null, 0, i11.DefaultFlexDirective, [i1.ElementRef, i12.StyleUtils, i12.LAYOUT_CONFIG, i11.FlexStyleBuilder, i12.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵeld(14, 0, null, 2, 2, "button", [["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onEdit(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(15, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["EDIT"])), (_l()(), i1.ɵeld(17, 0, null, 2, 2, "button", [["color", "warn"], ["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDelete(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(18, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["DELETE"]))], function (_ck, _v) { var currVal_2 = ""; _ck(_v, 13, 0, currVal_2); var currVal_7 = "warn"; _ck(_v, 18, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 8, 0, currVal_0); var currVal_1 = _v.context.$implicit.pivot.expires_at; _ck(_v, 11, 0, currVal_1); var currVal_3 = (i1.ɵnov(_v, 15).disabled || null); var currVal_4 = (i1.ɵnov(_v, 15)._animationMode === "NoopAnimations"); _ck(_v, 14, 0, currVal_3, currVal_4); var currVal_5 = (i1.ɵnov(_v, 18).disabled || null); var currVal_6 = (i1.ɵnov(_v, 18)._animationMode === "NoopAnimations"); _ck(_v, 17, 0, currVal_5, currVal_6); }); }
function View_UserTrainingsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [["class", "has-items"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Trainings"])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "button", [["class", "add-training"], ["color", "primary"], ["mat-fab", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openTrainingDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(4, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(5, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i7.View_MatIcon_0, i7.RenderType_MatIcon)), i1.ɵdid(6, 9158656, null, 0, i8.MatIcon, [i1.ElementRef, i8.MatIconRegistry, [8, null], [2, i8.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["add"])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "clearfix"], ["style", "margin-bottom: 50px"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserTrainingsComponent_3)), i1.ɵdid(10, 278528, null, 0, i13.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "primary"; _ck(_v, 4, 0, currVal_2); _ck(_v, 6, 0); var currVal_5 = _co.trainings; _ck(_v, 10, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 4).disabled || null); var currVal_1 = (i1.ɵnov(_v, 4)._animationMode === "NoopAnimations"); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 6).inline; var currVal_4 = (((i1.ɵnov(_v, 6).color !== "primary") && (i1.ɵnov(_v, 6).color !== "accent")) && (i1.ɵnov(_v, 6).color !== "warn")); _ck(_v, 5, 0, currVal_3, currVal_4); }); }
export function View_UserTrainingsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "wrapper no-background"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserTrainingsComponent_1)), i1.ɵdid(2, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserTrainingsComponent_2)), i1.ɵdid(4, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.trainings.length == 0); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.trainings.length > 0); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_UserTrainingsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-trainings", [], null, null, null, View_UserTrainingsComponent_0, RenderType_UserTrainingsComponent)), i1.ɵdid(1, 114688, null, 0, i14.UserTrainingsComponent, [i15.TrainingUserService, i16.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserTrainingsComponentNgFactory = i1.ɵccf("app-user-trainings", i14.UserTrainingsComponent, View_UserTrainingsComponent_Host_0, { userId: "userId" }, {}, []);
export { UserTrainingsComponentNgFactory as UserTrainingsComponentNgFactory };
