import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {TrainingService} from "../../../common/training.service";
import {TrainingUserService} from "../../../common/training-user.service";
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-edit-training-dialog',
    templateUrl: './edit-training-dialog.component.html',
    styleUrls: ['./edit-training-dialog.component.scss']
})
export class EditTrainingDialogComponent implements OnInit {

    public form;
    public trainings = [];
    public loading = false;
    public submitted = false;

    constructor(
        private formBuilder: FormBuilder,
        private trainingService: TrainingService,
        private trainingUserService: TrainingUserService,
        public dialogRef: MatDialogRef<EditTrainingDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit(): void {

        this.form = this.formBuilder.group({
            training: ['', Validators.required],
            expiration_date: ['', Validators.required]
        });

        this.trainingService.find().subscribe((response: any) => {
            this.trainings = response.data;
        });

        if(this.data.training) {
            this.form.patchValue({
                training: this.data.training.pivot.training_id,
                expiration_date: new Date(this.data.training.pivot.expires_at)
            });
        }
    }

    onSubmit() {
        this.submitted = true;

        if( ! this.form.valid) {
            return;
        }

        this.loading = true;
        const formValue = this.form.value;
        const payload = {
            training_id: formValue.training,
            expires_at: formValue.expiration_date.toLocaleDateString("en-us")
        };

        let trainingId = null;
        if(this.data.training) {
            trainingId = this.data.training.id;
        }

        this.trainingUserService.saveOrUpdate(this.data.userId, payload, trainingId).pipe(
            finalize(() => this.loading = false)
        ).subscribe(response => {
            this.dialogRef.close();
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }


}
