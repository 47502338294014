import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class LocationTypesService {

    constructor(protected apiService: ApiService) {
    }

    public find(sortOrder = 'asc', pageNumber = 1, limit = 10) {

        return this.apiService.get('location-types', {
            params: {
                page: pageNumber.toString(),
                limit: limit.toString()
            }
        });
    }

    public findOne(id) {
        return this.apiService.get('location-types/' + id).pipe(
            map(res => res["data"])
        );
    }

    public saveOrUpdate(payload: { name: string }, id = null) {
        if (id == null) {
            return this.apiService.post('location-types', payload)
        }

        return this.apiService.put('location-types/' + id, payload);
    }

    public destroy(id) {
        return this.apiService.delete('location-types/' + id);
    }

}
