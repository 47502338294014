import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {BillingTypeService} from "../../common/billing-type.service";
import {MatSnackBar} from "@angular/material";
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-edit-billing-type',
    templateUrl: './edit-billing-type.component.html',
    styleUrls: ['./edit-billing-type.component.scss']
})
export class EditBillingTypeComponent implements OnInit {

    public billingTypeForm: FormGroup;
    public mode: 'new' | 'edit' = 'new';
    public id?: number = null;
    public loading: boolean = true;
    public submitted = false;

    constructor(private formBuilder: FormBuilder,
                private billingTypeService: BillingTypeService,
                private snackbar: MatSnackBar,
                private router: Router,
                private route: ActivatedRoute) {

    }

    ngOnInit() {
        this.billingTypeForm = this.formBuilder.group({
            name: ['', Validators.required],
            rate: null
        });

        const idParam = this.route.snapshot.params['id'];

        if (idParam && idParam !== 'new') {
            this.mode = 'edit';
            this.id = +idParam;
            this.findBillingType(this.id);
        } else {
            this.loading = false;
        }
    }

    findBillingType(id: number) {
        this.billingTypeService.findOne(id).pipe(
            finalize(() => this.loading = false)
        ).subscribe(response => {
            this.billingTypeForm.patchValue({
                name: response.name,
                rate: response.rate
            });
        });
    }

    onSubmit() {
        this.submitted = true;

        if( ! this.billingTypeForm.valid) {
            return;
        }

        const payload = this.billingTypeForm.value;

        Object.keys(payload).forEach((key) => (payload[key] == null) && delete payload[key]);

        this.loading = true;
        this.billingTypeService.saveOrUpdate(payload, this.id).pipe(
            finalize(() => this.loading = false)
        ).subscribe(response => {
            this.snackbar.open('Billing Type saved successfully', 'Close', {
                duration: 3000
            });
            this.router.navigate(['../'], {
                relativeTo: this.route
            });
        }, () => {
            this.snackbar.open('Unable to save billing type', 'Close', {
                duration: 3000
            });
        });
    }

}
