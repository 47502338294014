import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

export interface TrailerType {
    name: string;
    id: number;
}

@Injectable({
    providedIn: 'root'
})
export class TrailerTypeService {
    constructor(protected apiService: ApiService) {
    }

    public find(sortOrder = 'asc', pageNumber = 1, limit = 10, search = null) : any {
        var params = {
            page: pageNumber.toString(),
            limit: limit.toString()
        }
        if(search !== null && search !== ''){
            params['search'] = search;
        }

        return this.apiService.get('trailer-types', {params});
    }

    public findOne(id) {
        return this.apiService.get('trailer-types/' + id).pipe(
            map(res => res["data"])
        );
    }

    public saveOrUpdate(payload: { name: string }, id = null) {
        if (id == null) {
            return this.apiService.post('trailer-types', payload)
        }

        return this.apiService.put('trailer-types/' + id, payload);
    }

    public destroy(id) {
        return this.apiService.delete('trailer-types/' + id);
    }
}
