import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatPaginator, MatSnackBar, MatSort} from '@angular/material';
import {TrailerTypesTableDataSource} from './trailer-types-table-datasource';
import {TrailerTypeService} from "../../../common/trailer-type.service";
import {AreYouSureDialogComponent} from "../../ui/are-you-sure-dialog/are-you-sure-dialog.component";
import {tap} from "rxjs/operators";

@Component({
    selector: 'app-trailer-types-table',
    templateUrl: './trailer-types-table.component.html',
    styleUrls: ['./trailer-types-table.component.scss']
})
export class TrailerTypesTableComponent implements OnInit, AfterViewInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    dataSource: TrailerTypesTableDataSource;
    pageCount = 0;

    /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
    displayedColumns = ['id', 'name', 'actions'];

    constructor(private trailerTypeService: TrailerTypeService,
                private dialog: MatDialog,
                private snackbar: MatSnackBar) {}

    ngOnInit() {
        this.dataSource = new TrailerTypesTableDataSource(this.trailerTypeService);
        this.dataSource.loadTrailerTypes(1, 25);
    }

    ngAfterViewInit(): void {
        this.paginator.page
            .pipe(
                tap(() => this.loadPage())
            )
            .subscribe();
    }

    loadPage() {
        this.dataSource.loadTrailerTypes(this.paginator.pageIndex + 1, this.paginator.pageSize);
    }

    onDelete(row) {
        const dialogRef = this.dialog.open(AreYouSureDialogComponent, {
            width: '600px',
            data: { id: row.id, service: this.trailerTypeService }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result && result.deleted) {
                this.loadPage();
                this.snackbar.open("Trailer type deleted successfully", "Close", {
                    duration: 3000
                });
            }
        });
    }
}
