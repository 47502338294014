import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatPaginator, MatSnackBar, MatSort} from '@angular/material';
import {TrucksTableDataSource} from './trucks-table-datasource';
import {TruckService} from "../../../common/truck.service";
import {AreYouSureDialogComponent} from "../../ui/are-you-sure-dialog/are-you-sure-dialog.component";
import {tap} from "rxjs/operators";

@Component({
    selector: 'app-trucks-table',
    templateUrl: './trucks-table.component.html',
    styleUrls: ['./trucks-table.component.scss']
})
export class TrucksTableComponent implements OnInit, AfterViewInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    dataSource: TrucksTableDataSource;
    pageCount = 0;

    /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
    displayedColumns = ['id', 'truck_number', 'actions'];

    constructor(private truckService: TruckService,
                private dialog: MatDialog,
                private snackbar: MatSnackBar) {}

    ngOnInit() {
        this.dataSource = new TrucksTableDataSource(this.truckService);
        this.dataSource.load(1, 25);
    }

    ngAfterViewInit(): void {
        this.paginator.page
            .pipe(
                tap(() => this.loadPage())
            )
            .subscribe();
    }

    loadPage() {
        this.dataSource.load(this.paginator.pageIndex + 1, this.paginator.pageSize);
    }

    onDelete(row) {
        const dialogRef = this.dialog.open(AreYouSureDialogComponent, {
            width: '600px',
            data: {id: row.id, service: this.truckService}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.deleted) {
                this.loadPage();
                this.snackbar.open("Truck deleted successfully", "Close", {
                    duration: 3000
                });
            }
        });
    }
}
