import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatPaginator, MatSnackBar, MatSort} from '@angular/material';
import {RigsTableDataSource} from './rigs-table-datasource';
import {RigService} from "../../../common/rig.service";
import {tap} from "rxjs/operators";
import {AreYouSureDialogComponent} from "../../ui/are-you-sure-dialog/are-you-sure-dialog.component";

@Component({
    selector: 'app-rigs-table',
    templateUrl: './rigs-table.component.html',
    styleUrls: ['./rigs-table.component.scss']
})
export class RigsTableComponent implements OnInit, AfterViewInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    dataSource: RigsTableDataSource;
    pageCount = 0;
    otr = false;
    filters = null;

    /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
    displayedColumns = ['id', 'name', 'customer', 'actions'];

    constructor(private rigService: RigService, private dialog: MatDialog, private snackbar: MatSnackBar) {

    }

    ngOnInit() {
        this.dataSource = new RigsTableDataSource(this.rigService);
        this.dataSource.loadRigs(1, 25);
    }

    ngAfterViewInit(): void {
        this.paginator.page
            .pipe(
                tap(() => this.loadPage())
            )
            .subscribe();
    }

    loadPage() {
        this.dataSource.loadRigs(this.paginator.pageIndex + 1, this.paginator.pageSize, this.filters);
    }

    onDelete(row) {
        const dialogRef = this.dialog.open(AreYouSureDialogComponent, {
            width: '600px',
            data: { id: row.id, service: this.rigService }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result && result.deleted) {
                this.loadPage();
                this.snackbar.open("Rig deleted successfully", "Close", {
                    duration: 3000
                });
            }
        });
    }

    toggleOtr(e) {
        this.otr = e.checked
        this.paginator.pageIndex = 0
        this.filters = this.otr ? {is_otr: true} : null
        this.loadPage()
    }
}
