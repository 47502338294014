import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {catchError, finalize} from 'rxjs/operators';
import {Observable, BehaviorSubject, of} from 'rxjs';
import {User, UserService} from "../../../common/user.service";

/**
 * Data source for the UsersTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class UsersTableDataSource implements DataSource<User> {
    private usersSubject = new BehaviorSubject<User[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();
    public pageCount = 0;

    public filters = {
        search: '',
        role: 'all'
    };

    constructor(private userService: UserService) {

    }

    connect(collectionViewer: CollectionViewer): Observable<User[]> {
        return this.usersSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.usersSubject.complete();
        this.loadingSubject.complete();
    }

    loadUsers(page: number, limit: number) {
        this.loadingSubject.next(true);

        this.userService.find(page, limit, this.filters).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
            .subscribe((users: any) => {
                this.pageCount = users.meta.total;
                this.usersSubject.next(users.data);
            });
    }
}

