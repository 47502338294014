<mat-slide-toggle class="toggle-button" color="primary" (change)="toggleOtr($event)"> OTR</mat-slide-toggle>
<div class="mat-elevation-z8" style="position: relative">
    <ngx-loading [show]="dataSource.loading$ | async"></ngx-loading>
    <table mat-table class="full-width-table" [dataSource]="dataSource" matSort aria-label="Materials">
        <!-- Id Column -->
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>Id</th>
            <td mat-cell *matCellDef="let row">{{row.id}}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let row">{{row.name}}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let element">
                <a class="edit-button" mat-stroked-button [routerLink]="['./', element.id]">
                    Edit
                </a>
                <a mat-stroked-button color="warn" (click)="onDelete(element)">
                    <ng-container *ngIf=" ! element.isLoading">
                        Delete
                    </ng-container>
                    <ng-container *ngIf="element.isLoading">
                        <mat-spinner size="sm" mode="indeterminate" color="primary"></mat-spinner>
                    </ng-container>
                </a>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <!--<mat-card *ngIf="dataSource.loading$ | async"-->
              <!--style="display: flex; justify-content: center; align-items: center">-->
        <!--<mat-progress-spinner-->
                <!--color="primary"-->
                <!--mode="indeterminate">-->
        <!--</mat-progress-spinner>-->
    <!--</mat-card>-->

    <mat-paginator #paginator
                   [length]="dataSource.pageCount"
                   [pageIndex]="0"
                   [pageSize]="25"
                   [pageSizeOptions]="[25, 50, 100, 250]">
    </mat-paginator>

</div>
