import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatPaginator, MatSnackBar, MatSort} from '@angular/material';
import {CompaniesTableDataSource} from './companies-table-datasource';
import {CompanyService} from '../../../common/company.service';
import {tap} from 'rxjs/operators';
import {AreYouSureDialogComponent} from '../../ui/are-you-sure-dialog/are-you-sure-dialog.component';

@Component({
    selector: 'app-companies-table',
    templateUrl: './companies-table.component.html',
    styleUrls: ['./companies-table.component.scss']
})
export class CompaniesTableComponent implements OnInit, AfterViewInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    dataSource: CompaniesTableDataSource;
    pageCount = 0;

    /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
    displayedColumns = ['id', 'name', 'actions'];

    constructor(private companyService: CompanyService, private dialog: MatDialog, private snackbar: MatSnackBar) {
    }

    ngOnInit() {
        this.dataSource = new CompaniesTableDataSource(this.companyService);
        this.dataSource.load(1, 25);
    }

    ngAfterViewInit(): void {
        this.paginator.page
            .pipe(
                tap(() => this.loadPage())
            )
            .subscribe();
    }

    loadPage() {
        this.dataSource.load(this.paginator.pageIndex + 1, this.paginator.pageSize);
    }

    onDelete(row) {
        const dialogRef = this.dialog.open(AreYouSureDialogComponent, {
            width: '600px',
            data: { id: row.id, service: this.companyService}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.deleted) {
                this.snackbar.open('Company deleted successfully', 'Close', {
                    duration: 3000
                });

                this.loadPage();
            }
        });
    }
}
