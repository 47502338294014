<div class="materials-edit mat-elevation-z2 wrapper">

    <h1 *ngIf="mode === 'edit'">Edit Trailer</h1>
    <h1 *ngIf="mode === 'new'">New Trailer</h1>

    <form class="materials-edit-form" [formGroup]="form" (ngSubmit)="onSubmit()">

        <mat-form-field [floatLabel]="'auto'" style="width: 100%">
            <input name="trailer_name" matInput placeholder="Name" required [formControlName]="'name'">
            <mat-error *ngIf="form.get('name').hasError('required') && submitted">
                Trailer name is required.
            </mat-error>
        </mat-form-field>

        <app-search-select required formControlName="type_id" [endpoint]="getEndpoint()" [placeholder]="'Trailer Type'" [default]="defaultTypeId" ></app-search-select>
        <span *ngIf="form.get('type_id').hasError('required') && submitted" class="mat-error custom-error">Trailer Type is required.</span>

        <mat-slide-toggle style="margin-bottom: 20px; margin-top: 20px;"
                formControlName="active"
                class="example-margin"
                color="primary">
            Active
        </mat-slide-toggle>


        <mat-form-field [floatLabel]="'auto'" style="width: 100%">
            <input name="trailer_year" matInput placeholder="Year" formControlName="year">
        </mat-form-field>

        <mat-form-field [floatLabel]="'auto'" style="width: 100%">
            <input name="trailer_make" matInput placeholder="Make" formControlName="make">
        </mat-form-field>

        <mat-form-field [floatLabel]="'auto'" style="width: 100%">
            <input name="trailer_model" matInput placeholder="Model" formControlName="model">
        </mat-form-field>

        <mat-form-field [floatLabel]="'auto'" style="width: 100%">
            <input name="trailer_vin" matInput placeholder="VIN" formControlName="vin">
        </mat-form-field>

        <mat-form-field [floatLabel]="'auto'" style="width: 100%">
            <input name="trailer_tire_size" matInput placeholder="Tire Size" formControlName="tire_size">
        </mat-form-field>

        <mat-form-field [floatLabel]="'auto'" style="width: 100%">
            <input name="trailer_license_plate" matInput placeholder="License Plate" formControlName="license_plate">
        </mat-form-field>

        <mat-form-field [floatLabel]="'auto'" style="width: 100%">
            <input name="trailer_lienholder" matInput placeholder="Lienholder" formControlName="lienholder">
        </mat-form-field>

        <mat-form-field style="width: 100%">
            <input matInput [matDatepicker]="lastServicePicker" placeholder="Last Service Date" formControlName="last_service_date">
            <mat-datepicker-toggle matSuffix [for]="lastServicePicker"></mat-datepicker-toggle>
            <mat-datepicker #lastServicePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field style="width: 100%">
            <input matInput [matDatepicker]="inspectionPicker" placeholder="Last Annual Inspection" formControlName="last_annual_inspection">
            <mat-datepicker-toggle matSuffix [for]="inspectionPicker"></mat-datepicker-toggle>
            <mat-datepicker #inspectionPicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field [floatLabel]="'auto'" style="width: 100%">
            <textarea name="trailer_notes" matInput placeholder="Notes" formControlName="notes" [rows]="5"></textarea>
        </mat-form-field>

        <button mat-stroked-button [disabled]="loading">
            Save
        </button>
    </form>

</div>
