import { ApiService } from "./api.service";
import { map } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
var TrainingUserService = /** @class */ (function () {
    function TrainingUserService(apiService) {
        this.apiService = apiService;
    }
    TrainingUserService.prototype.find = function (userId, sortOrder, pageNumber, limit) {
        if (sortOrder === void 0) { sortOrder = 'asc'; }
        if (pageNumber === void 0) { pageNumber = 1; }
        if (limit === void 0) { limit = 10; }
        return this.apiService.get('users/' + userId + '/trainings', {
            params: {
                page: pageNumber.toString(),
                limit: limit.toString()
            }
        }).pipe(map(function (res) { return res["data"]; }));
    };
    TrainingUserService.prototype.saveOrUpdate = function (userId, payload, id) {
        if (id === void 0) { id = null; }
        if (id == null) {
            return this.apiService.post('users/' + userId + '/trainings', payload);
        }
        return this.apiService.put('users/' + userId + '/trainings/' + id, payload);
    };
    TrainingUserService.prototype.destroy = function (id, userId) {
        return this.apiService.delete("users/" + userId + "/trainings/" + id);
    };
    TrainingUserService.ngInjectableDef = i0.defineInjectable({ factory: function TrainingUserService_Factory() { return new TrainingUserService(i0.inject(i1.ApiService)); }, token: TrainingUserService, providedIn: "root" });
    return TrainingUserService;
}());
export { TrainingUserService };
