import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(private snackbar: MatSnackBar) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    let errorMessage = 'Sorry, there was a problem. Please try again later.';
                    let duration = 3000;
                    switch (error.status) {
                        case 429:
                            errorMessage = 'There were too many requests to the server. Wait a minute and try again.';
                            duration = 12000;
                            break;
                    }

                    this.snackbar.open(errorMessage, 'Close', {
                        duration: duration,
                        panelClass: 'error',
                    });
                    if (error.error instanceof ErrorEvent) {
                        // client-side error
                        let e: any = Error(`Error: ${error.error.message}`);
                        e.error = error;
                        return throwError(e);
                    } else {
                        // server-side error
                        let e: any = Error(`Error Code: ${error.status}\nMessage: ${error.message}`);
                        e.status = error.status;
                        e.error = error;
                        return throwError(e);
                    }
                })
            );
    }
}
