import { Injectable } from '@angular/core';
import {ApiService} from './api.service';

export interface CertExpiration {
  id: number;
  user_id: number;
}

@Injectable({
  providedIn: 'root'
})
export class CertExpirationService {

  private uri = 'cert-expiration-subscriptions';

  constructor(protected apiService: ApiService) {}

  find(pageNumber = 1, limit = 5, embed = 'user'): any {
    let options = {
      params: {
        page: pageNumber,
        limit: limit,
        embed: embed
      }
    };
    return this.apiService.get(this.uri, options);
  }

  show(id) {
    return this.apiService.get(this.uri + '/' + id);
  }

  delete(id) {
    return this.apiService.delete(this.uri + '/' + id);
  }

  save(certExpiration): any {
    let payload = {
      user_id: certExpiration.user_id
    };
    return this.apiService.post(this.uri, payload);
  }



}
