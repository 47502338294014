import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {catchError, finalize} from 'rxjs/operators';
import {Observable, BehaviorSubject, of} from 'rxjs';
import {Truck, TruckService} from "../../../common/truck.service";

/**
 * Data source for the TrucksTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class TrucksTableDataSource implements DataSource<Truck> {
    private trucksSubject = new BehaviorSubject<Truck[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();
    public pageCount = 0;

    constructor(private truckService: TruckService) {}

    connect(collectionViewer: CollectionViewer): Observable<Truck[]> {
        return this.trucksSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.trucksSubject.complete();
        this.loadingSubject.complete();
    }

    load(page: number, limit: number) {
        this.loadingSubject.next(true);

        this.truckService.find('asc', page, limit).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
            .subscribe((trucks: any) => {
                this.pageCount = trucks.meta.total;
                this.trucksSubject.next(trucks.data);
            });
    }
}