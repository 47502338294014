<div class="materials-edit mat-elevation-z2 wrapper">

    <h1 *ngIf="mode === 'edit'">Edit Truck</h1>
    <h1 *ngIf="mode === 'new'">New Truck</h1>

    <form class="materials-edit-form" [formGroup]="form" (ngSubmit)="onSubmit()">

        <mat-form-field [floatLabel]="'auto'" style="width: 100%">
            <input name="truck_number" matInput placeholder="Truck Number" required [formControlName]="'truck_number'">
            <mat-error *ngIf="form.get('truck_number').hasError('required') && submitted">
                Truck number is required.
            </mat-error>
        </mat-form-field>

        <mat-form-field [floatLabel]="'auto'" style="width: 100%">
            <input name="truck_year" type="number" matInput placeholder="Year" [formControlName]="'year'">
        </mat-form-field>

        <mat-slide-toggle style="margin-bottom: 20px"
                          formControlName="active"
                          class="example-margin"
                          color="primary">
            Active
        </mat-slide-toggle>


        <mat-form-field [floatLabel]="'auto'" style="width: 100%">
            <input name="truck_make" matInput placeholder="Make" [formControlName]="'make'">
        </mat-form-field>

        <mat-form-field [floatLabel]="'auto'" style="width: 100%">
            <input name="truck_model" matInput placeholder="Model" [formControlName]="'model'">
        </mat-form-field>

        <mat-form-field [floatLabel]="'auto'" style="width: 100%">
            <input name="truck_vin" matInput placeholder="VIN" [formControlName]="'vin'">
        </mat-form-field>

        <mat-form-field [floatLabel]="'auto'" style="width: 100%">
            <input name="truck_tire_size" matInput placeholder="Tire Size" [formControlName]="'tire_size'">
        </mat-form-field>

        <mat-form-field [floatLabel]="'auto'" style="width: 100%">
            <input name="truck_license_plate" matInput placeholder="License Plate" [formControlName]="'license_plate'">
        </mat-form-field>

        <mat-form-field [floatLabel]="'auto'" style="width: 100%">
            <input name="truck_miles" type="number" matInput placeholder="Miles" [formControlName]="'miles'">
        </mat-form-field>

        <mat-form-field [floatLabel]="'auto'" style="width: 100%">
            <input name="truck_lienholder" matInput placeholder="Lienholder" [formControlName]="'lienholder'">
        </mat-form-field>

        <mat-form-field [floatLabel]="'auto'" style="width: 100%">
            <input name="truck_engine_size" matInput placeholder="Engine Size" [formControlName]="'engine_size'">
        </mat-form-field>

        <mat-form-field [floatLabel]="'auto'" style="width: 100%">
            <input name="truck_transmission" matInput placeholder="Transmission" [formControlName]="'transmission'">
        </mat-form-field>

        <mat-form-field style="width: 100%">
            <input matInput [matDatepicker]="lastServicePicker" placeholder="Last Service Date" [formControlName]="'last_service_date'">
            <mat-datepicker-toggle matSuffix [for]="lastServicePicker"></mat-datepicker-toggle>
            <mat-datepicker #lastServicePicker></mat-datepicker>
            <mat-error *ngIf="form.get('last_service_date').hasError('invalidDate') && submitted">
                Date format is not correct. Please use MM/DD/YYYY
            </mat-error>
            <mat-error *ngIf="form.get('last_service_date').hasError('matDatepickerParse') && submitted">
                Date format is not correct. Please use MM/DD/YYYY
            </mat-error>
        </mat-form-field>

        <mat-form-field style="width: 100%">
            <input matInput [matDatepicker]="inspectionPicker" placeholder="Last Annual Inspection" [formControlName]="'last_annual_inspection'">
            <mat-datepicker-toggle matSuffix [for]="inspectionPicker"></mat-datepicker-toggle>
            <mat-datepicker #inspectionPicker></mat-datepicker>
            <mat-error *ngIf="form.get('last_annual_inspection').hasError('invalidDate') && submitted">
                Date format is not correct. Please use MM/DD/YYYY
            </mat-error>
            <mat-error *ngIf="form.get('last_annual_inspection').hasError('matDatepickerParse') && submitted">
                Date format is not correct. Please use MM/DD/YYYY
            </mat-error>
        </mat-form-field>


        <mat-form-field [floatLabel]="'auto'" style="width: 100%">
            <textarea name="truck_notes" matInput placeholder="Notes" formControlName="notes" [rows]="5"></textarea>
        </mat-form-field>

        <button mat-stroked-button [disabled]="loading">
            Save
        </button>

        <div style="width: 100%">
            <ng-container *ngIf="form.value.keep_truckin_id === null">
                Not linked to Keep Truckin' Truck
                <button mat-button type="button" (click)="linkKeepTruckin()">Link</button>
            </ng-container>
            <ng-container *ngIf="form.value.keep_truckin_id !== null">
                Linked to Keep Truckin' Truck Id: {{ form.value.keep_truckin_id }}

                <button mat-button type="button" (click)="linkKeepTruckin()">Change</button>
                <ul>
                    <li>Number: {{ this.keepTruckinTruck?.number }}</li>
                    <li>License Plate: {{ this.keepTruckinTruck?.license_plate_number }}</li>
                    <li>Vin: {{ this.keepTruckinTruck?.vin }}</li>
                </ul>

            </ng-container>
        </div>
    </form>
</div>