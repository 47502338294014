import { ApiService } from "./api.service";
import { map } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
var LocationTypesService = /** @class */ (function () {
    function LocationTypesService(apiService) {
        this.apiService = apiService;
    }
    LocationTypesService.prototype.find = function (sortOrder, pageNumber, limit) {
        if (sortOrder === void 0) { sortOrder = 'asc'; }
        if (pageNumber === void 0) { pageNumber = 1; }
        if (limit === void 0) { limit = 10; }
        return this.apiService.get('location-types', {
            params: {
                page: pageNumber.toString(),
                limit: limit.toString()
            }
        });
    };
    LocationTypesService.prototype.findOne = function (id) {
        return this.apiService.get('location-types/' + id).pipe(map(function (res) { return res["data"]; }));
    };
    LocationTypesService.prototype.saveOrUpdate = function (payload, id) {
        if (id === void 0) { id = null; }
        if (id == null) {
            return this.apiService.post('location-types', payload);
        }
        return this.apiService.put('location-types/' + id, payload);
    };
    LocationTypesService.prototype.destroy = function (id) {
        return this.apiService.delete('location-types/' + id);
    };
    LocationTypesService.ngInjectableDef = i0.defineInjectable({ factory: function LocationTypesService_Factory() { return new LocationTypesService(i0.inject(i1.ApiService)); }, token: LocationTypesService, providedIn: "root" });
    return LocationTypesService;
}());
export { LocationTypesService };
