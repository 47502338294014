import {NgModule} from '@angular/core';
import {CommonModule as AngularCommonModule} from '@angular/common';
import {AuthenticationGuard} from "./guards/authentication.guard";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {SocketService} from "./sockets/socket.service";
import {UnauthorizedInterceptor} from "./unauthorized.interceptor";
import {UserResolver} from "./resolvers/user.resolver";
import {SessionService} from "./session.service";
import { TruncatePipe } from './truncate.pipe';
import {PasswordValidation} from "./password-validation";

@NgModule({
    declarations: [TruncatePipe],
    imports: [
        HttpClientModule,
        AngularCommonModule
    ],
    providers: [
        AuthenticationGuard,
        UserResolver,
        SessionService,
        SocketService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UnauthorizedInterceptor,
            multi: true
        }
    ],
})
export class CommonModule {
}
