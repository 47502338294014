<div class="mat-elevation-z2 wrapper" style="position: relative">

    <ngx-loading [show]="loading"></ngx-loading>

    <h1 *ngIf="mode === 'edit'">Edit {{ form.value.name }}</h1>
    <h1 *ngIf="mode === 'new'">New Location Type</h1>

    <form class="materials-edit-form" [formGroup]="form" (ngSubmit)="onSubmit()">

        <mat-form-field [floatLabel]="'auto'" style="width: 100%">
            <input name="location_type_name" matInput placeholder="Name" required formControlName="name">
            <mat-error *ngIf="form.get('name').hasError('required') && submitted">
                Location type name is required.
            </mat-error>
        </mat-form-field>

        <button mat-stroked-button [disabled]="loading" style="margin-top: 15px">
            Save
        </button>
    </form>

</div>