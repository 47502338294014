import {Injectable} from '@angular/core';
import {ReplaySubject, Subject} from "rxjs";
import {ApiService} from "./api.service";
import {tap} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    public subject = new ReplaySubject();

    public markAllAsReadSubject = new Subject();
    public markAsReadSubject = new Subject();

    get unreadNotifications() {
        return this.subject;
    }

    public onMarkAllAsRead() {
        return this.markAllAsReadSubject;
    }

    public onMarkAsRead() {
        return this.markAsReadSubject;
    }

    constructor(private apiService: ApiService) {
    }

    public addUnreadNotification(notification, read = false) {
        notification.read = read;
        return this.subject.next(notification);
    }

    public fetchUnread() {
        this.apiService.get('me/notifications?type=unread').subscribe((response: any) => {
            for(let notification of response) {
                this.addUnreadNotification(notification, notification.read_at !== null);
            }
        });
    }

    public fetchAll(page = 1) {
        return this.apiService.get(`me/notifications?page=${page}`);
    }

    public markAllAsRead(from) {

        return this.apiService.put('me/notifications').pipe(tap( () => {
            this.markAllAsReadSubject.next({
                from: from
            });
        }));
    }

    public deleteAllRead() {
        return this.apiService.delete('me/notifications');
    }

    public markAsRead(notification, from) {
        return this.apiService.put(`me/notifications/${notification.id}`, {
            read: true
        }).pipe(tap( () => {
            this.markAsReadSubject.next({
                from: from,
                id: notification.id
            });
        }));
    }
}
