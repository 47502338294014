import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {map} from 'rxjs/operators';

export interface Certification {
    name: string;
    trailer_type_id?: number;
    id: number;
}


@Injectable({
    providedIn: 'root'
})
export class CertificationService {
    constructor(protected apiService: ApiService) {
    }

    public find(pageNumber = 1, limit = 10, filters = null) {

        let options =  {
            params: {
                page: pageNumber.toString(),
                limit: limit.toString()
            }
        };

        if (filters) {
            if (filters.sortOrder) {
                options.params['sort_order'] = filters.sortOrder;
            }

            if (filters.sortBy) {
                options.params['sort_by'] = filters.sortBy;
            }

            if (filters.search) {
                options.params['search'] = filters.search;
            }
        }

        return this.apiService.get('certifications', options);
    }

    public findOne(id) {
        return this.apiService.get('certifications/' + id).pipe(
            map(res => res['data'])
        );
    }

    public saveOrUpdate(payload: { name: string }, id = null) {
        if (id == null) {
            return this.apiService.post('certifications', payload)
        }

        return this.apiService.put('certifications/' + id, payload);
    }

    public destroy(id) {
        return this.apiService.delete('certifications/' + id);
    }
}
