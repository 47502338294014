import { ReplaySubject, Subject } from "rxjs";
import { ApiService } from "./api.service";
import { tap } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
var NotificationService = /** @class */ (function () {
    function NotificationService(apiService) {
        this.apiService = apiService;
        this.subject = new ReplaySubject();
        this.markAllAsReadSubject = new Subject();
        this.markAsReadSubject = new Subject();
    }
    Object.defineProperty(NotificationService.prototype, "unreadNotifications", {
        get: function () {
            return this.subject;
        },
        enumerable: true,
        configurable: true
    });
    NotificationService.prototype.onMarkAllAsRead = function () {
        return this.markAllAsReadSubject;
    };
    NotificationService.prototype.onMarkAsRead = function () {
        return this.markAsReadSubject;
    };
    NotificationService.prototype.addUnreadNotification = function (notification, read) {
        if (read === void 0) { read = false; }
        notification.read = read;
        return this.subject.next(notification);
    };
    NotificationService.prototype.fetchUnread = function () {
        var _this = this;
        this.apiService.get('me/notifications?type=unread').subscribe(function (response) {
            for (var _i = 0, response_1 = response; _i < response_1.length; _i++) {
                var notification = response_1[_i];
                _this.addUnreadNotification(notification, notification.read_at !== null);
            }
        });
    };
    NotificationService.prototype.fetchAll = function (page) {
        if (page === void 0) { page = 1; }
        return this.apiService.get("me/notifications?page=" + page);
    };
    NotificationService.prototype.markAllAsRead = function (from) {
        var _this = this;
        return this.apiService.put('me/notifications').pipe(tap(function () {
            _this.markAllAsReadSubject.next({
                from: from
            });
        }));
    };
    NotificationService.prototype.deleteAllRead = function () {
        return this.apiService.delete('me/notifications');
    };
    NotificationService.prototype.markAsRead = function (notification, from) {
        var _this = this;
        return this.apiService.put("me/notifications/" + notification.id, {
            read: true
        }).pipe(tap(function () {
            _this.markAsReadSubject.next({
                from: from,
                id: notification.id
            });
        }));
    };
    NotificationService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.inject(i1.ApiService)); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}());
export { NotificationService };
