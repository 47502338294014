import { Injectable } from '@angular/core';

import { Resolve, Router, ActivatedRouteSnapshot } from '@angular/router';

import {Observable} from 'rxjs';
import {SessionService} from "../session.service";

@Injectable()
export class UserResolver implements Resolve<any> {
    constructor(private sessionService: SessionService, private router: Router) {}
    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.sessionService.fetch();
    }
}