import { Component, OnInit, Inject, ViewChild, ElementRef, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { EventEmitter } from 'events';
import { JobSafetyAnalysisService } from '../../../../../common/job-safety-analysis.service';

@Component({
  selector: 'app-review-edit',
  templateUrl: './review-edit.component.html',
  styleUrls: ['./review-edit.component.scss']
})
export class ReviewEditComponent implements OnInit {

  public jsa;
  public job_index;
  public formGroup: FormGroup;

  constructor(public dialogRef: MatDialogRef<ReviewEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder, private jsaService: JobSafetyAnalysisService) {
    this.jsa = data.jsa;
  }

  @Output() update = new EventEmitter();

  ngOnInit() {
    this.initializeForm();
    this.patchValues();
  }

  initializeForm() {
    this.formGroup = this.formBuilder.group({
      weather_conditions: ['', Validators.required],
      equipment_type: ['', Validators.required],
      safety_concerns: ['', Validators.required],
      customer_name_info: ['', Validators.required],
      tasks: this.formBuilder.array([]),
      other_ppe: this.formBuilder.array([]),
      other_key_items: this.formBuilder.array([])
    });
  }

  patchValues() {
    // placing everything in form groups for easier error handling and organization
    this.insertTasks();
    this.insertOtherPpe();
    this.insertOtherKeyItems();

    this.formGroup.patchValue({
      weather_conditions: this.jsa.weather_conditions,
      equipment_type: this.jsa.equipment_type,
      safety_concerns: this.jsa.safety_concerns,
      customer_name_info: this.jsa.customer_name_info
    });

  }

  insertTasks() {
    for (let task = 0; task < this.jsa.tasks.length; task++) {
      let tasks = this.formGroup.get('tasks') as FormArray;
      let taskToPush = this.formBuilder.group({
        taskTitle: [this.jsa.tasks[task].taskTitle, Validators.required],
        hazard: [this.jsa.tasks[task].hazard, Validators.required],
        controls: [this.jsa.tasks[task].controls, Validators.required]
      });
      tasks.push(taskToPush);
    }
  }

  insertOtherPpe() {
    for (let ppe = 0; ppe < this.jsa.other_ppe.length; ppe++) {
      let otherPpe = this.formGroup.get('other_ppe') as FormArray;
      let ppeToPush = new FormControl(this.jsa.other_ppe[ppe]);
      ppeToPush.setValidators([Validators.required]);
      otherPpe.push(ppeToPush);
    }
  }

  insertOtherKeyItems() {
    for (let item = 0; item < this.jsa.other_key_items.length; item++) {
      let otherKeyItems = this.formGroup.get('other_key_items') as FormArray;
      let itemToPush = new FormControl(this.jsa.other_key_items[item]);
      itemToPush.setValidators([Validators.required]);
      otherKeyItems.push(itemToPush);
    }
  }

  saveChanges() {
    if (this.formGroup.invalid) {
      return;
    }

    let updatedData = this.formGroup.value;

    // you're going to have to fix the controller to check for the data before it starts json encoding it
    this.jsaService.saveOrUpdate(updatedData, this.jsa.id).subscribe();

    this.dialogRef.close(updatedData);

  }
}
