import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from "./common/guards/authentication.guard";
import {RedirectComponent} from "./redirect/redirect.component";

const routes: Routes = [
    { path: '', component: RedirectComponent, canActivate: [AuthenticationGuard] },
    { path: '*', component: RedirectComponent, canActivate: [AuthenticationGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
