import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticationComponent } from './authentication.component';
import { CallbackComponent } from './callback/callback.component';
import { RouterModule } from "@angular/router";

import { authenticationRoutes } from "./authentication.routing";
import { LogoutComponent } from './logout/logout.component';

@NgModule({
    declarations: [AuthenticationComponent, CallbackComponent, LogoutComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(authenticationRoutes)
    ]
})
export class AuthenticationModule {
}
