<div class="wrapper no-background">
    <ngx-loading [show]="loading"></ngx-loading>
    <h1>Notifications</h1>

    <div class="notification-actions">
        <button mat-flat-button (click)="markAllAsRead()" color="primary">Mark all as read</button>
        <button mat-flat-button (click)="deleteAllRead()" color="warn">Delete all read notifications</button>
    </div>


    <mat-list>
        <app-notification-card *ngFor="let notification of notifications" [notification]="notification" (read)="onRead($event)"></app-notification-card>
    </mat-list>

    <mat-paginator
            class="mat-elevation-z1"
            [length]="length"
                   [pageSize]="pageSize"
                   [pageSizeOptions]="pageSizeOptions"
                   (page)="pageEvent($event)">
    </mat-paginator>


</div>
