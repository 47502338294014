<div class="materials-edit mat-elevation-z2 wrapper" style="position: relative">
    <ngx-loading [show]="loading"></ngx-loading>

    <h1 *ngIf="mode === 'edit'">Edit Training</h1>
    <h1 *ngIf="mode === 'new'">New Training</h1>

    <form class="materials-edit-form" [formGroup]="form" (ngSubmit)="onSubmit()">

        <mat-form-field [floatLabel]="'auto'" style="width: 100%">
            <input name="trianing_name" matInput placeholder="Name" required [formControlName]="'name'">
            <mat-error *ngIf="form.get('name').hasError('required') && submitted">
                Training name is required.
            </mat-error>
        </mat-form-field>

        <button mat-stroked-button [disabled]="loading" style="margin-top: 20px">
            Save
        </button>
    </form>

</div>