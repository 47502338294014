import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-are-you-sure-dialog',
    templateUrl: './are-you-sure-dialog.component.html',
    styleUrls: ['./are-you-sure-dialog.component.scss']
})
export class AreYouSureDialogComponent implements OnInit {

    public loading = false;

    constructor(public dialogRef: MatDialogRef<AreYouSureDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
    }

    onSubmit() {
        this.loading = true;
        this.data.service.destroy(this.data.id).pipe(
            finalize(() => this.loading = false)
        ).subscribe(response => {
            this.dialogRef.close({ deleted: true, error: false});
        });
    }

    onNoClick(): void {
        this.dialogRef.close( { submitted: false, error: false });
    }

}
