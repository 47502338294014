import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {Observable} from "rxjs";
import {ApiResponse} from "./interfaces/api-response.interface";
import {map} from "rxjs/operators";


export interface Material {
    data: {
        name: string
        id: number;
    }[];

    meta: any;
}


@Injectable({
    providedIn: 'root'
})
export class MaterialService {

    constructor(protected apiService: ApiService) {
    }

    public find(pageNumber = 1, limit = 10, filters = null) {

        let options = {
            params: {
                page: pageNumber.toString(),
                limit: limit.toString(),
            }
        };

        if (filters !== null) {
            if (filters.sortOrder) {
                options.params['sort_order'] = filters.sortOrder;
            }

            if (filters.sortBy) {
                options.params['sort_by'] = filters.sortBy;
            }

            if (filters.search) {
                options.params['search'] = filters.search;
            }

            if (filters.otr) {
                options.params['is_otr'] = true;
            }
        }

        return this.apiService.get('materials', options);
    }

    public findOne(id) {
        return this.apiService.get('materials/' + id).pipe(
            map(res => res["data"])
        );
    }

    public saveOrUpdate(payload: { name: string }, id = null) {
        if(id == null) {
            return this.apiService.post('materials', payload)
        }

        return this.apiService.put('materials/' + id, payload);
    }

    public destroy(id) {
        return this.apiService.delete('materials/' + id);
    }

}
