<div class="mat-elevation-z2 wrapper" style="position: relative">
    <ngx-loading [show]="loading"></ngx-loading>

    <h1 *ngIf="mode === 'edit'">Edit Company</h1>
    <h1 *ngIf="mode === 'new'">New Company</h1>

    <form class="company-edit-form" [formGroup]="companyForm" (ngSubmit)="onSubmit()">

        <mat-form-field>
            <input matInput placeholder="Name" required formControlName="name" name="name">
            <mat-error *ngIf="companyForm.get('name').hasError('required') && submitted">
                Company name is required.
            </mat-error>
            <mat-error *ngIf="companyForm.get('name').hasError('maxLength')">
                Name can't be more than 255 characters.
            </mat-error>
        </mat-form-field>
        <mat-form-field>
            <input matInput placeholder="Address" formControlName="address" name="address">
            <mat-error *ngIf="companyForm.get('address').hasError('maxLength')">
                Address can't be more than 255 characters.
            </mat-error>
        </mat-form-field>
        <mat-form-field>
            <input matInput placeholder="Dispatcher Phone Number" formControlName="phone_number" name="phone_number">
            <mat-error *ngIf="companyForm.get('phone_number').hasError('maxLength')">
                Phone number can't be more than 255 characters.
            </mat-error>
        </mat-form-field>
        <div>
            <label *ngIf="logo" class="label" for="fileInput">Change Logo</label>
            <label *ngIf="!logo" class="label" for="fileInput">Add Logo</label>
            <input #fileInput class="choose-logo" type="file" accept="image/*" (change)="selectLogo($event)">
            <button mat-icon-button type="button" (click)="fileInput.click()">
                <mat-icon color="primary">perm_media</mat-icon>
            </button>
        </div>

        <img alt="Company Logo" *ngIf="logo" class="company-logo" [src]="logo">

        <div *ngIf="logo">
            <span>Remove Logo</span>
            <button mat-icon-button type="button" (click)="removeLogo()">
                <mat-icon color="warn">remove_circle</mat-icon>
            </button>
        </div>

        <button mat-stroked-button [disabled]="loading" style="margin-top: 10px;">
            Save
        </button>
    </form>

</div>