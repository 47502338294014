import { Component, Input, OnInit } from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {ApiService} from "../../../common/api.service";
import {PasswordValidation} from "../../../common/password-validation";
import {MatSnackBar} from "@angular/material";
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'profile-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

    @Input() user;

    public form;

    public submitted = false;
    public loading = false;

    constructor(private formBuilder: FormBuilder, private apiService: ApiService, private snackbar: MatSnackBar) {
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            current_password: '',
            new_password: '',
            new_password_confirmation: ''
        },{
            validator: PasswordValidation.MatchPassword
        });
    }

    submit() {
        this.submitted = true;

        if( ! this.form.valid) {
            return;
        }

        const form = this.form.value;
        this.loading = true;

        this.apiService.post('me/password', {
            new_password: form.new_password,
            current_password: form.current_password
        }).pipe(
            finalize(() => this.loading = false)
        ).subscribe(response => {
            this.snackbar.open('Password updated successfully', 'Close', {
                duration: 3000
            });


            this.submitted = false;
            this.form.patchValue({
                current_password: '',
                new_password: '',
                new_password_confirmation: ''
            });
            this.form.resetForm();

        }, (response) => {
            if(response.status === 400) {
                this.snackbar.open('Invalid password', 'Close', {
                    duration: 3000
                });
            }
        });

    }

}
