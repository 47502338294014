import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {catchError, finalize, map} from 'rxjs/operators';
import {Observable, BehaviorSubject, of} from 'rxjs';
import {TrailerType,} from "../../../common/trailer-type.service";
import {Certification, CertificationService} from "../../../common/certification.service";

/**
 * Data source for the CertificationsTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class CertificationsTableDataSource implements DataSource<Certification> {
    private certificationsSubject = new BehaviorSubject<TrailerType[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();
    public pageCount = 0;

    constructor(private certificationService: CertificationService) {

    }

    connect(collectionViewer: CollectionViewer): Observable<Certification[]> {
        return this.certificationsSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.certificationsSubject.complete();
        this.loadingSubject.complete();
    }

    load(page: number, limit: number) {
        this.loadingSubject.next(true);

        this.certificationService.find(page, limit).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false)),
            map((response: any) => {
                response.data = response.data.map((certification: any) => {
                    let type = certification.type;
                    let typeDisplay = type.charAt(0).toUpperCase() + type.slice(1);
                    typeDisplay = typeDisplay.replace(/_/g, ' ');
                    certification.type_display = typeDisplay;
                    return certification;
                });

                return response;
            })
        )
            .subscribe((certifications: any) => {
                this.pageCount = certifications.meta.total;
                this.certificationsSubject.next(certifications.data)
            });
    }
}
