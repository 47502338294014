import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {catchError, finalize} from 'rxjs/operators';
import {Observable, BehaviorSubject, of} from 'rxjs';
import {Trailer, TrailerService} from "../../../common/trailer.service";

/**
 * Data source for the TrailersTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class TrailersTableDataSource implements DataSource<Trailer> {
    private trailersSubject = new BehaviorSubject<Trailer[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();

    public pageCount = 0;

    constructor(private trailerService: TrailerService) {

    }

    connect(collectionViewer: CollectionViewer): Observable<Trailer[]> {
        return this.trailersSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.trailersSubject.complete();
        this.loadingSubject.complete();
    }

    load(page: number, limit: number = null) {
        this.loadingSubject.next(true);

        this.trailerService.find('asc', page, limit).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
            .subscribe((rigs: any) => {
                this.pageCount = rigs.meta.total;
                this.trailersSubject.next(rigs.data);
            });
    }
}
