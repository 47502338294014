import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {map, tap} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class LocationsService {

    constructor(protected apiService: ApiService) {
    }

    public find(sortOrder = 'asc', pageNumber = 1, limit = 10, filters = null) {

        let options = {
            params: {
                page: pageNumber.toString(),
                limit: limit.toString(),
            }
        };

        if (filters !== undefined && filters !== null) {
            if (filters.search !== undefined && filters.search !== null) {
                options.params['search'] = filters.search;
            }

            if (filters.type !== undefined && filters.type !== null) {
                options.params['type'] = filters.type;
            }
        }

        return this.apiService.get('locations', options);
    }

    public getByType(type, search): any {
        return this.apiService.get('locations', {
            params: {
                type: type,
                search: search,
                mode: 'autocomplete'
            }
        });
    }

    public findOne(id) {
        return this.apiService.get('locations/' + id);
    }

    public saveOrUpdate(payload: { name: string }, id = null) {
        if (id == null) {
            return this.apiService.post('locations', payload)
        }

        return this.apiService.put('locations/' + id, payload);
    }

    public destroy(id) {
        return this.apiService.delete('locations/' + id);
    }

}
