import {Component, Input, OnInit} from '@angular/core';
import {CertificationUserService} from "../../../common/certification-user.service";
import {MatDialog} from "@angular/material";
import {EditCertificationDialogComponent} from "../edit-certification-dialog/edit-certification-dialog.component";

@Component({
    selector: 'app-user-certifications',
    templateUrl: './certifications.component.html',
    styleUrls: ['./certifications.component.scss']
})
export class UserCertificationsComponent implements OnInit {

    public certifications = [];

    @Input() userId;

    constructor(private dataService: CertificationUserService, public dialog: MatDialog) {
    }

    ngOnInit() {

        this.dataService.find(this.userId).subscribe(response => {
            this.certifications = response;
        });
    }

    openCertificationDialog() {
        const dialogRef = this.dialog.open(EditCertificationDialogComponent, {
            width: '600px',
            data: { userId: this.userId }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.dataService.find(this.userId).subscribe(response => {
                this.certifications = response;
            });
        });
    }

    onEdit(certification) {
        const dialogRef = this.dialog.open(EditCertificationDialogComponent, {
            width: '600px',
            data: { userId: this.userId, certification: certification }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.dataService.find(this.userId).subscribe(response => {
                this.certifications = response;
            });
        });
    }

    onDelete(certification) {
        const index = this.certifications.indexOf(certification);
        this.dataService.destroy(certification.id, this.userId).subscribe(response => {
            this.certifications.splice(index, 1);
        }, () => {

        });
    }
}
