<h1 mat-dialog-title>Add/Edit Certification</h1>
<div mat-dialog-content>
    <ngx-loading [show]="loading"></ngx-loading>
    <form class="materials-edit-form" [formGroup]="form" (ngSubmit)="onSubmit()">
        <app-search-select class="search-select" [multiple]="multiple" formControlName="certification" [endpoint]="getCertificationEndpoint()" [placeholder]="multiple ? 'Certifications' : 'Certification'" ></app-search-select>
        <mat-error *ngIf="form.get('certification').hasError('required') && submitted">
            Certification is required.
        </mat-error>

        <mat-form-field>
            <input matInput [matDatepicker]="picker" placeholder="Expiration date" [formControlName]="'expiration_date'">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="form.get('expiration_date').hasError('required') && submitted">
                Expiration date is required.
            </mat-error>
        </mat-form-field>

    </form>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="onSubmit()">Save</button>
</div>