import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService() {
        this._token = null;
        this._user = null;
    }
    Object.defineProperty(AuthenticationService.prototype, "user", {
        get: function () {
            return this._user;
        },
        set: function (user) {
            this._user = user;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthenticationService.prototype, "token", {
        get: function () {
            if (this._token !== null) {
                return this._token;
            }
            this.setTokenFromLocalStorage();
            return this._token;
        },
        set: function (token) {
            this._token = token;
        },
        enumerable: true,
        configurable: true
    });
    AuthenticationService.prototype.setTokenFromLocalStorage = function () {
        this.token = localStorage.getItem('gstyle.token');
    };
    AuthenticationService.prototype.logout = function () {
        localStorage.clear();
        var loginUrl = environment.login_url;
        var clientId = environment.api_client_id;
        window.location.href = loginUrl + "/redirect?client_id=" + clientId;
    };
    AuthenticationService.ngInjectableDef = i0.defineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}());
export { AuthenticationService };
