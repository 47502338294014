<div class="materials-edit mat-elevation-z2 wrapper" style="position: relative">
    <ngx-loading [show]="loading"></ngx-loading>

    <h1 *ngIf="mode === 'edit'">Edit Certification</h1>
    <h1 *ngIf="mode === 'new'">New Certification</h1>

    <form class="materials-edit-form" [formGroup]="form" (ngSubmit)="onSubmit()">

        <mat-form-field [floatLabel]="'auto'" style="width: 100%">
            <input name="certification_name" matInput placeholder="Name" required [formControlName]="'name'">
            <mat-error *ngIf="form.get('name').hasError('required') && submitted">
                Certification name is required.
            </mat-error>
        </mat-form-field>


        <mat-form-field style="width: 100%">
            <mat-select placeholder="Type" [formControlName]="'type'">
                <mat-option value="none">None</mat-option>
                <mat-option value="customer">Customer</mat-option>
                <mat-option value="trailer_type">Trailer Type</mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('type').hasError('required') && submitted">
                Type is required.
            </mat-error>
        </mat-form-field>

        <app-search-select *ngIf="showCustomerSelect" class="search-select" formControlName="customer" [endpoint]="getEndpointCustomer()" [placeholder]="'Customer'" ></app-search-select>
        
        <app-search-select *ngIf="showTrailerTypeSelect" class="search-select" formControlName="trailer_type" [endpoint]="getEndpointTrailerType()" [placeholder]="'TrailerType'" ></app-search-select>

        <button mat-stroked-button [disabled]="loading" style="margin-top: 20px">
            Save
        </button>
    </form>

</div>
