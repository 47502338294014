/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./view-users-certification.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i8 from "@angular/material/icon";
import * as i9 from "../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i10 from "@angular/material/card";
import * as i11 from "@angular/common";
import * as i12 from "../../../../../node_modules/@angular/material/paginator/typings/index.ngfactory";
import * as i13 from "@angular/material/paginator";
import * as i14 from "./view-users-certification.component";
var styles_ViewUsersCertificationComponent = [i0.styles];
var RenderType_ViewUsersCertificationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ViewUsersCertificationComponent, data: {} });
export { RenderType_ViewUsersCertificationComponent as RenderType_ViewUsersCertificationComponent };
function View_ViewUsersCertificationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "user-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [["class", "user"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " ", " | ID: ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "remove-user"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "button", [["mat-icon-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeUser(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(6, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵeld(7, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i7.View_MatIcon_0, i7.RenderType_MatIcon)), i1.ɵdid(8, 9158656, null, 0, i8.MatIcon, [i1.ElementRef, i8.MatIconRegistry, [8, null], [2, i8.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"]))], function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.disabled; _ck(_v, 6, 0, currVal_5); _ck(_v, 8, 0); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.user.first_name; var currVal_1 = _v.context.$implicit.user.last_name; var currVal_2 = _v.context.$implicit.user.id; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = (i1.ɵnov(_v, 6).disabled || null); var currVal_4 = (i1.ɵnov(_v, 6)._animationMode === "NoopAnimations"); _ck(_v, 5, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵnov(_v, 8).inline; var currVal_7 = (((i1.ɵnov(_v, 8).color !== "primary") && (i1.ɵnov(_v, 8).color !== "accent")) && (i1.ɵnov(_v, 8).color !== "warn")); _ck(_v, 7, 0, currVal_6, currVal_7); }); }
export function View_ViewUsersCertificationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "mat-card", [["class", "mat-card"]], null, null, null, i9.View_MatCard_0, i9.RenderType_MatCard)), i1.ɵdid(2, 49152, null, 0, i10.MatCard, [], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "mat-card-title", [["class", "card-title mat-card-title"]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i10.MatCardTitle, [], null, null), (_l()(), i1.ɵted(-1, null, [" Certification Notifications "])), (_l()(), i1.ɵeld(6, 0, null, 0, 3, "mat-card-content", [["class", "mat-card-content"]], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i10.MatCardContent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewUsersCertificationComponent_1)), i1.ɵdid(9, 278528, null, 0, i11.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(10, 0, null, 1, 3, "mat-card-footer", [["class", "mat-card-footer"]], null, null, null, null, null)), i1.ɵdid(11, 16384, null, 0, i10.MatCardFooter, [], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "mat-paginator", [["class", "mat-paginator"]], null, [[null, "page"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("page" === en)) {
        var pd_0 = (_co.pageEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_MatPaginator_0, i12.RenderType_MatPaginator)), i1.ɵdid(13, 245760, [["paginator", 4]], 0, i13.MatPaginator, [i13.MatPaginatorIntl, i1.ChangeDetectorRef], { length: [0, "length"], pageSize: [1, "pageSize"] }, { page: "page" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.certNotifications; _ck(_v, 9, 0, currVal_0); var currVal_1 = _co.total; var currVal_2 = 5; _ck(_v, 13, 0, currVal_1, currVal_2); }, null); }
export function View_ViewUsersCertificationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-view-users-certification", [], null, null, null, View_ViewUsersCertificationComponent_0, RenderType_ViewUsersCertificationComponent)), i1.ɵdid(1, 114688, null, 0, i14.ViewUsersCertificationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ViewUsersCertificationComponentNgFactory = i1.ɵccf("app-view-users-certification", i14.ViewUsersCertificationComponent, View_ViewUsersCertificationComponent_Host_0, { certNotifications: "certNotifications", total: "total", disabled: "disabled" }, { remove: "remove", paginate: "paginate" }, []);
export { ViewUsersCertificationComponentNgFactory as ViewUsersCertificationComponentNgFactory };
