import { Component, OnInit } from '@angular/core';
import { SessionService } from "../../common/session.service";

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

    public user;

    constructor(private sessionService: SessionService) {
    }

    ngOnInit() {
        this.user = this.sessionService.user;
    }

}
