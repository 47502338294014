import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatPaginator, MatSnackBar, MatSort} from '@angular/material';
import {LocationsTableDatasource} from './locations-table-datasource';
import {LocationTypesTableDatasource} from "../../location-types/location-types-table/location-types-table-datasource";
import {LocationTypesService} from "../../../common/location-types.service";
import {tap} from "rxjs/operators";
import {AreYouSureDialogComponent} from "../../ui/are-you-sure-dialog/are-you-sure-dialog.component";
import {LocationsService} from "../../../common/locations.service";

@Component({
    selector: 'app-locations-table',
    templateUrl: './locations-table.component.html',
    styleUrls: ['./locations-table.component.scss']
})
export class LocationsTableComponent implements OnInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    dataSource: LocationsTableDatasource;
    pageCount = 0;

    /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
    displayedColumns = ['name', 'actions'];

    constructor(private locationsService: LocationsService,
                private snackbar: MatSnackBar,
                private dialog: MatDialog) {

    }

    ngOnInit() {
        this.dataSource = new LocationsTableDatasource(this.locationsService);
        this.dataSource.load(1, 25);
    }

    ngAfterViewInit(): void {
        this.paginator.page
            .pipe(
                tap(() => this.loadPage())
            )
            .subscribe();
    }

    loadPage() {
        this.dataSource.load(this.paginator.pageIndex + 1, this.paginator.pageSize);
    }

    onDelete(row) {
        const dialogRef = this.dialog.open(AreYouSureDialogComponent, {
            width: '600px',
            data: {id: row.id, service: this.locationsService}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.deleted) {
                this.loadPage();
                this.snackbar.open("Location deleted successfully", "Close", {
                    duration: 3000
                });
            }
        });
    }
}
