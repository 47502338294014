<div class="materials-edit mat-elevation-z2 wrapper">

    <h1 *ngIf="mode === 'edit'">Edit Location</h1>
    <h1 *ngIf="mode === 'new'">New Location</h1>

    <form class="materials-edit-form" [formGroup]="form" (ngSubmit)="onSubmit()">

        <mat-form-field [floatLabel]="'auto'" style="width: 100%">
            <input name="location_name" matInput placeholder="Name" required [formControlName]="'name'">
            <mat-error *ngIf="form.get('name').hasError('required') && submitted">
                Location name is required
            </mat-error>
        </mat-form-field>

        <mat-form-field style="width: 100%">
            <mat-select placeholder="Type" [formControlName]="'type_id'">
                <mat-option *ngFor="let type of types" [value]="type.id">{{type.name}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field style="width: 100%">
            <mat-select placeholder="Format" [formControlName]="'format'">
                <mat-option value="gps">GPS</mat-option>
                <mat-option value="address">Address</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field [hidden]="addressEnabled" [floatLabel]="'auto'" style="width: 100%">
            <mat-label>Address</mat-label>
            <input matInput
                   #addressInput
                   matGoogleMapsAutocomplete
                   [country]="'us'"
                   type="address"
                   (onAutocompleteSelected)="onAutocompleteSelected($event)"
                   (onLocationSelected)="onLocationSelected($event)">
        </mat-form-field>

        <mat-form-field  [floatLabel]="'auto'" style="width: 100%">
            <input name="location" matInput placeholder="Location" required [formControlName]="'location'">
            <mat-error *ngIf="form.get('location').hasError('required') && submitted">
                Location is required
            </mat-error>
            <mat-error *ngIf="form.get('location').errors &&
            form.get('location').dirty &&
            form.get('location').errors.invalidCoordinates">
                Field must contain valid GPS coordinates.
            </mat-error>
            <mat-hint>GPS Coordinates</mat-hint>
        </mat-form-field>

        <mat-form-field [floatLabel]="'auto'" style="width: 100%">
            <textarea name="location_notes" matInput placeholder="Notes" formControlName="notes" [rows]="5"></textarea>
        </mat-form-field>

        <button type="submit" mat-stroked-button [disabled]="loading">
            Save
        </button>
    </form>

</div>