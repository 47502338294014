import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {MaterialService} from "../../common/material.service";

@Component({
    selector: 'app-materials',
    templateUrl: './materials.component.html',
    styleUrls: ['./materials.component.scss']
})
export class MaterialsComponent implements OnInit {

    constructor(private materialService: MaterialService) {
    }

    ngOnInit() {
    }

}
