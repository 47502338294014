import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {catchError, finalize} from 'rxjs/operators';
import {Observable, BehaviorSubject, of} from 'rxjs';
import {BillingType, BillingTypeService} from "../../../common/billing-type.service";

/**
 * Data source for the MaterialsTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class BillingTypesTableDatasource implements DataSource<BillingType> {

    private billingTypeSubject = new BehaviorSubject<BillingType[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();
    public pageCount = 0;

    constructor(private billingTypeService: BillingTypeService) {

    }

    connect(collectionViewer: CollectionViewer): Observable<BillingType[]> {
        return this.billingTypeSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.billingTypeSubject.complete();
        this.loadingSubject.complete();
    }

    load(page: number, limit: number) {
        this.loadingSubject.next(true);

        this.billingTypeService.find(page, limit).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
            .subscribe((response: any) => {
                this.pageCount = response.meta.total;
                this.billingTypeSubject.next(response.data);
            });
    }
}

