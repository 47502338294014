import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ImageCroppedEvent} from "ngx-image-cropper";

@Component({
    selector: 'user-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent {

    @Input() user;
    @Output() save = new EventEmitter();

    public form;

    imageChangedEvent: any = '';
    croppedImage: any = '';

    @ViewChild('imageInput') imageInput;

    constructor() {}

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }

    imageLoaded() {
        // show cropper
    }

    cropperReady() {
        // cropper ready
    }

    loadImageFailed() {
        // show message
    }

    onSave() {
        if (!this.croppedImage) {
            return;
        }

        this.save.emit({image: this.croppedImage});
        this.imageChangedEvent = null;
        this.croppedImage = '';
    }

}
