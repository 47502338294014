<div fxLayout="column" style="width: 80%; margin: auto">
  <div class="header-wrapper">
    <form class="form-group" [formGroup]="form">
      <div class="search-select">
        <app-search-select formControlName="user" [endpoint]="getEndpointUser()" [placeholder]="'Users'" [formatFieldName]="formatFieldName" ></app-search-select>
        <button class="button-wrapper" mat-icon-button (click)="addUser()" color="primary"><mat-icon>add_circle</mat-icon></button>
      </div>
    </form>
  
    <div class="user-table-wrapper">
        <app-view-users-certification [disabled]="removing" (paginate)="paginate($event)" (remove)="remove($event)" [total]="total" [certNotifications]="certNotifications"></app-view-users-certification>
    </div>
  </div>

  <h1>Certifications</h1>
  <app-certifications-table></app-certifications-table>
</div>

<a [routerLink]="['./new']" mat-fab  color="primary" class="new-button"><mat-icon>add</mat-icon></a>
