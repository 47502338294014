import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Rig, RigService } from '../../common/rig.service';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { CustomerService } from '../../common/customer.service';
import { WellService } from '../../common/well.service';
import { ValidateCoordinates } from '../../common/coordinates.validator';
import { MatSnackBar } from '@angular/material';
import { SearchableEndpoint } from '../search-select/search-select.component';
import { Subject } from 'rxjs';
import { WhitespaceValidator } from '../../common/validators';


@Component({
    selector: 'app-edit-well',
    templateUrl: './edit-well.component.html',
    styleUrls: ['./edit-well.component.scss']
})
export class EditWellComponent implements OnInit {


    public form: FormGroup;
    public mode: 'new' | 'edit' = 'new';
    public id?: number = null;
    public loading = true;
    public submitted = false;
    public shouldRefresh = new Subject<object>();

    constructor(private formBuilder: FormBuilder,
                private snackbar: MatSnackBar,
                private wellService: WellService,
                private rigService: RigService,
                private customerService: CustomerService,
                private router: Router,
                private route: ActivatedRoute) {

    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            is_otr: false,
            name: ['', [Validators.required, WhitespaceValidator()]],
            location: ['', Validators.compose([Validators.required, ValidateCoordinates])],
            active: true,
            rig: [null],
            customer: [null, Validators.required]
        });

        const idParam = this.route.snapshot.params['id'];

        if (idParam && idParam !== 'new') {
            this.mode = 'edit';
            this.id = +idParam;
            this.find(this.id);
        } else {
            this.loading = false;
        }

        this.form.get('rig').valueChanges.subscribe(value => {
            this.form.patchValue({
                customer: value ? value.customer: value
            });
        });

        this.form.get('is_otr').valueChanges.subscribe(isOtr => {
            if(this.form.get('customer').value && Boolean(this.form.get('customer').value.is_otr) !== isOtr) {
                this.form.get('customer').patchValue(null);
            }
            if(this.form.get('rig').value && Boolean(this.form.get('rig').value.is_otr) !== isOtr) {
                this.form.get('rig').patchValue(null);
            }
            this.shouldRefresh.next(isOtr ? {is_otr: true} : null)
        });
    }

    getEndpointCustomer(): SearchableEndpoint {
        return ((search: String, page = 1, extraParams = null) => {
            return this.customerService.search(search, extraParams);
        }).bind(this);
    }

    getEndpointRig(): SearchableEndpoint {
        return ((search: String, page = 1, extraParams) => {
            return this.rigService.find(page, 25, {search, sortBy: 'name', ...extraParams});
        }).bind(this);
    }


    find(id: number) {
        this.wellService.findOne(id).pipe(
            finalize(() => this.loading = false)
        ).subscribe((response: any) => {
            this.form.patchValue({
                name: response.name,
                location: response.location,
                rig: response.rig,
                customer: response.customer,
                active: response.is_active,
                is_otr: response.is_otr,
            });
            // this.updateReplaySubject(this.filteredRigs, response.rig);
            // this.updateReplaySubject(this.filteredCustomers, response.customer);
        });
    }

    // updateReplaySubject(subject: ReplaySubject<any>, newItem: any ) {
    //     subject.pipe(take(1)).subscribe(list => {
    //       if (!list.includes(newItem)) {
    //         list.push(newItem);
    //         subject.next(list);
    //       }
    //     });
    //   }

    onSubmit() {
        this.submitted = true;

        if ( ! this.form.valid) {
            return;
        }

        const formValue = this.form.value;

        let position = null;

        const payload: any = {
            name: formValue.name,
            customer_id: formValue.customer.id,
            is_active: formValue.active,
            location: formValue.location,
            is_otr: formValue.is_otr
        };

        if (formValue.rig !== null) {
            payload['rig_id'] = formValue.rig.id;
        }

        if(this.mode === 'edit') {
            delete payload.is_otr
        }

        this.loading = true;
        this.wellService.saveOrUpdate(payload, this.id).pipe(
            finalize(() => this.loading = false)
        ).subscribe(response => {
            this.snackbar.open('Well saved successfully', 'Close', {
                duration: 3000
            });
            this.router.navigate(['../'], {
                relativeTo: this.route
            });
        }, () => {
            this.snackbar.open('Unable to save well', 'Close', {
                duration: 3000
            });
        });
    }

    displayFn(rig: Rig) {
        if (rig) {
            return rig.name;
        }
    }

    customerDisplayFn(customer) {
        if (customer) { return customer.name; }
    }

}
