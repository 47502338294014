import { AbstractControl } from '@angular/forms';
import * as Coordinates from 'coordinate-parser';

export function ValidateCoordinates(control: AbstractControl) {

    if (control.value === undefined || control.value === '' || control.value === null) {
        return null;
    }

    let latLong = control.value.split(',');

    if (latLong.length !== 2) {
        return { invalidCoordinates: true };
    }

    let lat = latLong[0].trim();
    let long = latLong[1].trim();

    if (lat === '' || long === '' || isNaN(lat) || isNaN(long) || lat < -90 || lat > 90 || long < -180 || long > 180) {
        return { invalidCoordinates: true };
    }

    return null;
}
