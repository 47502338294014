import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
var TruckBoardService = /** @class */ (function () {
    function TruckBoardService(apiService) {
        this.apiService = apiService;
    }
    TruckBoardService.prototype.getArrivalTime = function (pageNumber, limit, params) {
        if (pageNumber === void 0) { pageNumber = 1; }
        if (limit === void 0) { limit = 25; }
        if (params === void 0) { params = {}; }
        var options = {
            params: tslib_1.__assign({}, params, { page: pageNumber.toString(), limit: limit.toString() })
        };
        return this.apiService.get('truck-board/arrival-time', options);
    };
    TruckBoardService.prototype.getDriverTime = function (pageNumber, limit, params) {
        if (pageNumber === void 0) { pageNumber = 1; }
        if (limit === void 0) { limit = 25; }
        if (params === void 0) { params = {}; }
        var options = {
            params: tslib_1.__assign({}, params, { page: pageNumber.toString(), limit: limit.toString() })
        };
        return this.apiService.get('truck-board/arrival-time', options);
    };
    TruckBoardService.ngInjectableDef = i0.defineInjectable({ factory: function TruckBoardService_Factory() { return new TruckBoardService(i0.inject(i1.ApiService)); }, token: TruckBoardService, providedIn: "root" });
    return TruckBoardService;
}());
export { TruckBoardService };
