<div class="trucks-filter-wrapper">
  <mat-form-field [formGroup]="form">
    <mat-label>Mode</mat-label>
    <mat-select formControlName="mode">
      <mat-option value="driverTime">Driver Duty Time</mat-option>
      <mat-option value="arrivalTime">Arrival Time</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div class="main-map">
  <agm-map #map
    [latitude]="lat"
    [longitude]="lng"
    [zoom]="zoom">

    <ng-container *ngIf="form.get('mode').value === 'driverTime'">
      <agm-marker *ngFor="let truck of trucks" 
      [iconUrl]="truck.render.symbol || defaultSymbol"
      (markerClick)="displayMessage()"
      [latitude]="truck.location.lat"
      [longitude]="truck.location.lng">
        <agm-info-window>
          <p>Truck: {{ truck.truck_number }}</p>
          <p>Trailer: {{ truck.trailer.name }}</p>
          <p>Trailer Type: {{ truck.trailer.type.name }}</p>
          <p>Driver: {{ truck.driver.last_name }}, {{ truck.driver.first_name }}</p>
          <p *ngIf="truck.driver.keep_truckin_user">Duty Hours Remaining: {{ truck.driver.keep_truckin_user.available_time.shift_hours }}</p>
          <p *ngIf="!truck.driver.keep_truckin_user">User not linked to Keep Truckin.</p>
        </agm-info-window>
      </agm-marker>
    </ng-container>

    <ng-container *ngIf="form.get('mode').value === 'arrivalTime'">
      <ng-container  *ngFor="let truck of trucks">
        <agm-marker
        [iconUrl]="truck.render.symbol || defaultSymbol"
        (markerClick)="displayMessage()"
        [latitude]="truck.location.lat"
        [longitude]="truck.location.lng">
          <agm-info-window>
            <p>Truck: {{ truck.truck_number }}</p>
            <p>Trailer: {{ truck.trailer.name }}</p>
            <p>Trailer Type: {{ truck.trailer.type.name }}</p>
            <p>Driver: {{ truck.driver.last_name }}, {{ truck.driver.first_name }}</p>
            <p>Estimated Arrival Time: {{ truck.destination?.arrival_time_formatted || 'Unknown' }}</p>
          </agm-info-window>
        </agm-marker>
        <agm-marker 
        *ngIf="truck.destination"
        [latitude]="truck.destination.lat"
        [longitude]="truck.destination.lng">
        </agm-marker>
      </ng-container>
    </ng-container>
  </agm-map>
  <button (click)="refresh()" mat-button> <mat-icon>refresh</mat-icon> </button>
</div>


<div class="trucks-filter-wrapper" [formGroup]="form">
        <div class="search-select-wrapper">
          <app-search-select class="search-select" formControlName="trailerType" [endpoint]="getTrailerEndpoint()" placeholder="Trailer Type"></app-search-select>
        </div>

        <div class="search-select-wrapper">
          <app-search-select class="search-select" formControlName="job" [formatFieldName]="displayJob" [endpoint]="getJobEndpoint()" placeholder="Job"></app-search-select>
        </div>

        <mat-form-field>
            <mat-label>Location</mat-label>
            <input matInput formControlName='location' placeholder="Location" matGoogleMapsAutocomplete (onLocationSelected)="setCoordinates($event)">
        </mat-form-field>
        <button class="mat-button" (click)="clearFilters()">
            <i class="material-icons">
              close
            </i>
        </button>
</div>


