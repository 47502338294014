import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
var CertificationService = /** @class */ (function () {
    function CertificationService(apiService) {
        this.apiService = apiService;
    }
    CertificationService.prototype.find = function (pageNumber, limit, filters) {
        if (pageNumber === void 0) { pageNumber = 1; }
        if (limit === void 0) { limit = 10; }
        if (filters === void 0) { filters = null; }
        var options = {
            params: {
                page: pageNumber.toString(),
                limit: limit.toString()
            }
        };
        if (filters) {
            if (filters.sortOrder) {
                options.params['sort_order'] = filters.sortOrder;
            }
            if (filters.sortBy) {
                options.params['sort_by'] = filters.sortBy;
            }
            if (filters.search) {
                options.params['search'] = filters.search;
            }
        }
        return this.apiService.get('certifications', options);
    };
    CertificationService.prototype.findOne = function (id) {
        return this.apiService.get('certifications/' + id).pipe(map(function (res) { return res['data']; }));
    };
    CertificationService.prototype.saveOrUpdate = function (payload, id) {
        if (id === void 0) { id = null; }
        if (id == null) {
            return this.apiService.post('certifications', payload);
        }
        return this.apiService.put('certifications/' + id, payload);
    };
    CertificationService.prototype.destroy = function (id) {
        return this.apiService.delete('certifications/' + id);
    };
    CertificationService.ngInjectableDef = i0.defineInjectable({ factory: function CertificationService_Factory() { return new CertificationService(i0.inject(i1.ApiService)); }, token: CertificationService, providedIn: "root" });
    return CertificationService;
}());
export { CertificationService };
