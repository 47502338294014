import { Component, OnInit, Input, forwardRef, ViewChild, Renderer2, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap, takeUntil, first } from 'rxjs/operators';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelect } from '@angular/material';

export interface SearchableEndpoint {
  (search: String, page: BigInteger, extraParams?: object): Observable<any>;
}

@Component({
  selector: 'app-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchSelectComponent),
      multi: true
    }
  ]
})
export class SearchSelectComponent implements ControlValueAccessor, OnInit, OnChanges, AfterViewInit {
  @Input() endpoint: SearchableEndpoint;
  @Input() placeholder: String;
  @Input() default: any;
  @Input() readOnly: Boolean = null;
  @Input() multiple = false;
  @Input() shouldRefresh: Subject<object> = null;
  @Input() required: Boolean = false;

  @ViewChild('main_select') mainSelect: MatSelect;

  private readonly scrollLoadThreshhold = 300;
  private isLoadingMore = false;

  private valueChangesObs;

  public isLoading = true;
  public page = 1;
  private lastPage = 1;
  public list = [];
  public inputControl = new FormControl('');
  public defaultValue;
  public defaultReady;
  public changed = false;
  public itemSize = 25;
  public extraParams = null;

  public value: any;

  @Input() formatFieldName =  listItem => listItem.name;

  onChange: any = () => {};
  onTouch: any = () => {};

  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    this.valueChangesObs = this.inputControl.valueChanges.pipe(
      debounceTime(600),
      distinctUntilChanged(),
      tap(() => {
        this.isLoading = true;
        this.page = 1;
      }),
      switchMap(search => this.endpoint(search, this.page as any as BigInteger, this.extraParams))
    );

    if (!this.readOnly) {
      this.initializeData();
    }

    // load data whenever search value changes
    this.valueChangesObs.subscribe((response) => {
      if (!this.readOnly) {
        if (response.meta) {
          this.lastPage = response.meta.last_page;
        }
        this.writeList(response.data);
        this.isLoading = false;
      }
    });

    if(this.shouldRefresh) {
      this.shouldRefresh.subscribe((extraParams = null) => {
        this.extraParams = extraParams
        this.initializeData()
      })
    }
  }

  ngAfterViewInit() {
    this.mainSelect.openedChange.subscribe((opened) => {
      if (opened) {
        this.mainSelect.panel.nativeElement.addEventListener('scroll', event => {
          if (event.target.scrollTop + event.target.offsetHeight >= event.target.scrollHeight - this.scrollLoadThreshhold) {
            if (!this.isLoadingMore && !this.isLoading) {
              this.loadMore();
            }
          }
        });
      }
    });
  }

  initializeData() {
    // load data beforehand
    this.isLoading = true;
    this.endpoint('', 1 as any as BigInteger, this.extraParams).pipe(
      takeUntil(this.valueChangesObs)
    ).subscribe((response) => {
      if (response.meta) {
        this.lastPage = response.meta.last_page;
      }
      this.writeList(response.data);
      this.isLoading = false;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    // If readonly is enabled then switched to disabled, initialize
    if (changes.readOnly && changes.readOnly.previousValue && !changes.readOnly.currentValue) {
      this.initializeData();
    }
  }

  writeList(list) {
    let data = list.slice();
    if (!this.multiple) {
      if (this.value && !(this.value in data)) {
        data = data.filter( el => el.id !== this.value.id );
        data.unshift(this.value);
      }
    } else {
      if (Array.isArray(this.value)) {
        for (let value of this.value) {
          data = data.filter( el => el.id !== value.id );
        }
        data = this.value.concat(data);
      }
    }

    this.list = data;
  }

  appendList(list) {
    this.list = this.list.concat(list);
  }

  writeValue(value) {
    if (!this.readOnly) {
      this.value = value;
      this.writeList(this.list);
      this.onChange(this.value);
    } else if (value !== null) {
      this.isLoading = false;
      this.value = value;
      this.writeList([]);
    }
  }

  loadMore() {
    // only load more if there is more to load
    if (this.page < this.lastPage) {
      this.isLoadingMore = true;
      this.page++;
      this.endpoint(this.inputControl.value, this.page as any as BigInteger, this.extraParams).pipe(
        first()
      ).subscribe((response) => {
        this.appendList(response.data);
        this.isLoadingMore = false;
      });
    }
  }

  registerOnChange(fn) {
    this.onChange = val => {
      fn(val);
    };
    // this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouch = fn;
  }

  public setDisabledState?(): void {
    throw new Error('not implementing');
  }


  makeSelection(event): void {
    if (!this.readOnly) {
      this.changed = true;
      this.writeValue(event.value);
    }
  }

  clearControl(): void {
    this.writeValue(null);
    this.inputControl.patchValue(null);
    this.mainSelect.close();
  }

  focus(): void {
    // there has to be a better way to trigger this.
    this.onTouch();
    if(this.list.length === 0) {
      // basically what's happening is that there are weird cases where if no selection is found, it won't let open again after a focus
      this.writeValue(null);
      this.inputControl.patchValue(null);
      this.mainSelect.focus();
    }
    setTimeout(() => {
      try {
        this.renderer.selectRootElement('#search_select_filter').focus();
      } catch (e) {
       // do nothing :(
      }
    }, 400);
  }

  /**
  * Handles the key down event with MatSelect.
  * Allows e.g. selecting with enter key, navigation with arrow keys, etc.
  */
  _handleKeydown(event: KeyboardEvent) {
    // Prevent propagation for all alphanumeric characters in order to avoid selection issues
    if (event.key && event.key === ' ') {
      event.stopPropagation();
    }
  }

}
