<div class="materials-edit mat-elevation-z2 wrapper">

    <h1 *ngIf="mode === 'edit'">Edit Trailer Type</h1>
    <h1 *ngIf="mode === 'new'">New Trailer Type</h1>

    <form class="materials-edit-form" [formGroup]="form" (ngSubmit)="onSubmit()">

        <mat-form-field [floatLabel]="'auto'" style="width: 100%">
            <input name="trailer_type_name" matInput placeholder="Name" required [formControlName]="'name'">
            <mat-error *ngIf="form.get('name').hasError('required') && submitted">
                Trailer type name is required.
            </mat-error>
        </mat-form-field>

        <mat-form-field style="width: 100%">
            <mat-select placeholder="Materials" [formControlName]="'materials'" multiple>
                <mat-option *ngFor="let material of materials" [value]="material.id">{{material.name}}</mat-option>
            </mat-select>
        </mat-form-field>

        <button mat-stroked-button [disabled]="loading">
            Save
        </button>
    </form>

</div>