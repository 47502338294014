<div class="filter-form-container" >
    <form [formGroup]="filterForm" (ngSubmit)="applyFilters()" fxLayout="row" fxFlex="100">
        <mat-slide-toggle fxFlex="20" class="toggle-button" [formControlName]="'isOtr'" color="primary"> OTR</mat-slide-toggle>
        <mat-form-field [floatLabel]="'auto'" fxFlex="30">
            <input matInput placeholder="Search" [formControlName]="'search'">
        </mat-form-field>

        <mat-form-field fxFlex="30">
            <mat-select placeholder="Status" [formControlName]="'status'">
                <mat-option value="all">All</mat-option>
                <mat-option value="active">Active</mat-option>
                <mat-option value="inactive">Inactive</mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</div>

<div class="mat-elevation-z8" style="position: relative">
    <ngx-loading [show]="dataSource.loading$ | async"></ngx-loading>
    <table mat-table matSort class="full-width-table" [dataSource]="dataSource" matSort aria-label="Rigs">
        <!-- Id Column -->
        <ng-container matColumnDef="id">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Id</th>
            <td mat-cell *matCellDef="let row">{{row.id}}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let row">{{row.name}}</td>
        </ng-container>

        <!-- Rig Column -->
        <ng-container matColumnDef="rig">
            <th mat-header-cell *matHeaderCellDef>Rig</th>
            <td mat-cell *matCellDef="let row">{{row.rig == null ? 'None': row.rig.name}}</td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="is_active">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let row">{{row.is_active == true ? 'Active': 'Inactive' }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let element">
                <a class="edit-button" mat-stroked-button [routerLink]="['./', element.id]">
                    Edit
                </a>
                <a mat-stroked-button color="warn" (click)="onDelete(element)">
                    Delete
                </a>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>


    <mat-paginator #paginator
                   [length]="dataSource.pageCount"
                   [pageIndex]="0"
                   [pageSize]="25"
                   [pageSizeOptions]="[25, 50, 100, 250]">
    </mat-paginator>
</div>
