/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./are-you-sure-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/ngx-loading/ngx-loading.ngfactory";
import * as i3 from "ngx-loading";
import * as i4 from "@angular/material/dialog";
import * as i5 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "./are-you-sure-dialog.component";
var styles_AreYouSureDialogComponent = [i0.styles];
var RenderType_AreYouSureDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AreYouSureDialogComponent, data: {} });
export { RenderType_AreYouSureDialogComponent as RenderType_AreYouSureDialogComponent };
export function View_AreYouSureDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngx-loading", [], null, null, null, i2.View_NgxLoadingComponent_0, i2.RenderType_NgxLoadingComponent)), i1.ɵdid(1, 114688, null, 0, i3.NgxLoadingComponent, [i3.NgxLoadingService, i1.ChangeDetectorRef], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(3, 81920, null, 0, i4.MatDialogTitle, [[2, i4.MatDialogRef], i1.ElementRef, i4.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["Are you sure?"])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i4.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Deleting this entity may also remove other related entities. Are you sure you'd like to continue?"])), (_l()(), i1.ɵeld(9, 0, null, null, 7, "div", [["class", "mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(10, 16384, null, 0, i4.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 2, "button", [["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNoClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(12, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Cancel"])), (_l()(), i1.ɵeld(14, 0, null, null, 2, "button", [["color", "warn"], ["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSubmit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(15, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Delete"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 1, 0, currVal_0); _ck(_v, 3, 0); var currVal_6 = "warn"; _ck(_v, 15, 0, currVal_6); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 3).id; _ck(_v, 2, 0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 12).disabled || null); var currVal_3 = (i1.ɵnov(_v, 12)._animationMode === "NoopAnimations"); _ck(_v, 11, 0, currVal_2, currVal_3); var currVal_4 = (i1.ɵnov(_v, 15).disabled || null); var currVal_5 = (i1.ɵnov(_v, 15)._animationMode === "NoopAnimations"); _ck(_v, 14, 0, currVal_4, currVal_5); }); }
export function View_AreYouSureDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-are-you-sure-dialog", [], null, null, null, View_AreYouSureDialogComponent_0, RenderType_AreYouSureDialogComponent)), i1.ɵdid(1, 114688, null, 0, i10.AreYouSureDialogComponent, [i4.MatDialogRef, i4.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AreYouSureDialogComponentNgFactory = i1.ɵccf("app-are-you-sure-dialog", i10.AreYouSureDialogComponent, View_AreYouSureDialogComponent_Host_0, {}, {}, []);
export { AreYouSureDialogComponentNgFactory as AreYouSureDialogComponentNgFactory };
