import { ApiService } from './api.service';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
var BolService = /** @class */ (function () {
    function BolService(apiService) {
        this.apiService = apiService;
    }
    BolService.prototype.find = function (status, trailerTypeIds, ids) {
        if (status === void 0) { status = null; }
        if (trailerTypeIds === void 0) { trailerTypeIds = []; }
        if (ids === void 0) { ids = []; }
        var params = {};
        if (status != null) {
            params['status'] = status;
        }
        if (ids != null && (Array.isArray(ids) && ids.length !== 0)) {
            params['ids'] = ids;
        }
        if (trailerTypeIds != null && (Array.isArray(trailerTypeIds) && trailerTypeIds.length !== 0)) {
            params['trailerTypeIds'] = trailerTypeIds;
        }
        return this.apiService.get("jobs.bols", { params: params });
    };
    BolService.prototype.createJobBol = function (id, options) {
        return this.apiService.post("jobs/" + id + "/bols", options);
    };
    BolService.prototype.create = function (options) {
        return this.apiService.post('bols/', options);
    };
    BolService.prototype.update = function (id, options) {
        return this.apiService.put("bols/" + id, options);
    };
    BolService.ngInjectableDef = i0.defineInjectable({ factory: function BolService_Factory() { return new BolService(i0.inject(i1.ApiService)); }, token: BolService, providedIn: "root" });
    return BolService;
}());
export { BolService };
