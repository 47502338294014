import {AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatDialog, MatPaginator, MatSnackBar, MatSort} from '@angular/material';
import {BillingTypesTableDatasource} from './billing-types-table-datasource';
import {BillingTypeService} from "../../../common/billing-type.service";
import {tap} from "rxjs/operators";
import {AreYouSureDialogComponent} from "../../ui/are-you-sure-dialog/are-you-sure-dialog.component";

@Component({
    selector: 'app-billing-types-table',
    templateUrl: './billing-types-table.component.html',
    styleUrls: ['./billing-types-table.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BillingTypesTableComponent implements OnInit, AfterViewInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    dataSource: BillingTypesTableDatasource;
    pageCount = 0;

    /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
    displayedColumns = ['id', 'name', 'rate', 'actions'];

    constructor(private billingTypeService: BillingTypeService,
                private dialog: MatDialog,
                private snackbar: MatSnackBar) {
    }

    ngOnInit() {
        this.dataSource = new BillingTypesTableDatasource(this.billingTypeService);
        this.dataSource.load(1, 25);
    }

    ngAfterViewInit(): void {
        this.paginator.page
            .pipe(
                tap(() => this.loadPage())
            )
            .subscribe();
    }

    loadPage() {
        this.dataSource.load(this.paginator.pageIndex + 1, this.paginator.pageSize);
    }

    onDelete(row) {
        const dialogRef = this.dialog.open(AreYouSureDialogComponent, {
            width: '600px',
            data: { id: row.id, service: this.billingTypeService }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result && result.deleted) {
                this.loadPage();
                this.snackbar.open("Billing Type deleted successfully", "Close", {
                    duration: 3000
                });
            }
        });
    }
}
