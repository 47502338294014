import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-notification-card',
    templateUrl: './notification-card.component.html',
    styleUrls: ['./notification-card.component.scss']
})
export class NotificationCardComponent implements OnInit {

    @Input() notification;

    @Output() read = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

    toggleRead($event, notification) {
        this.read.emit(notification);
    }

}
