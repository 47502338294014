import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material";
import {TrainingUserService} from "../../../common/training-user.service";
import {EditTrainingDialogComponent} from "../edit-training-dialog/edit-training-dialog.component";
import {EditCertificationDialogComponent} from "../edit-certification-dialog/edit-certification-dialog.component";

@Component({
    selector: 'app-user-trainings',
    templateUrl: './trainings.component.html',
    styleUrls: ['./trainings.component.scss']
})
export class UserTrainingsComponent implements OnInit {

    public trainings = [];

    @Input() public userId;

    constructor(private dataService: TrainingUserService, public dialog: MatDialog) {
    }

    ngOnInit() {

        this.dataService.find(this.userId).subscribe(response => {
            this.trainings = response;
        });
    }

    openTrainingDialog() {
        const dialogRef = this.dialog.open(EditTrainingDialogComponent, {
            width: '600px',
            data: {userId: this.userId}
        });

        dialogRef.afterClosed().subscribe(result => {
            this.dataService.find(this.userId).subscribe(response => {
                this.trainings = response;
            });
        });
    }

    onEdit(training) {
        const dialogRef = this.dialog.open(EditTrainingDialogComponent, {
            width: '600px',
            data: { userId: this.userId, training: training }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.dataService.find(this.userId).subscribe(response => {
                this.trainings = response;
            });
        });
    }

    onDelete(training) {
        const index = this.trainings.indexOf(training);
        this.dataService.destroy(training.id, this.userId).subscribe(response => {
            this.trainings.splice(index, 1);
        }, () => {

        });
    }

}
