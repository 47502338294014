import { Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {RigService} from '../../common/rig.service';
import {finalize} from 'rxjs/operators';
import {Customer, CustomerService} from '../../common/customer.service';
import {MatSnackBar} from '@angular/material';
import { SearchableEndpoint } from '../search-select/search-select.component';
import { Subject } from 'rxjs';
import { WhitespaceValidator } from '../../common/validators';

@Component({
    selector: 'app-edit-rig',
    templateUrl: './edit-rig.component.html',
    styleUrls: ['./edit-rig.component.scss']
})
export class EditRigComponent implements OnInit {

    public form: FormGroup;
    public mode: 'new' | 'edit' = 'new';
    public id?: number = null;
    public loading = true;
    public filteredCustomers;

    public submitted = false;
    public isLoading: boolean;
    public shouldRefresh = new Subject<any>();

    constructor(private formBuilder: FormBuilder,
                private rigService: RigService,
                private customerService: CustomerService,
                private snackbar: MatSnackBar,
                private router: Router,
                private route: ActivatedRoute) {

    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            name: ['', [Validators.required, WhitespaceValidator()]],
            customer: [null, Validators.required],
            is_otr: false
        });

        const idParam = this.route.snapshot.params['id'];

        if (idParam && idParam !== 'new') {
            this.mode = 'edit';
            this.id = +idParam;
            this.find(this.id);
        } else {
            this.loading = false;
        }

        this.form.get('is_otr').valueChanges.subscribe(isOtr => {
            if(this.form.get('customer').value && this.form.get('customer').value.is_otr !== isOtr) {
                this.form.get('customer').patchValue(null);
            }
            this.shouldRefresh.next(isOtr ? {is_otr: true} : null);
        })

    }

    getEndpoint(): SearchableEndpoint {
        return ((search: String, page = 1, extraParams = null) => {
            return this.customerService.search(search, extraParams);
        }).bind(this);
    }

    find(id: number) {
        this.rigService.findOne(id).pipe(
            finalize(() => this.loading = false)
        ).subscribe((response: any) => {
            this.form.patchValue({
                name: response.name,
                customer: response.customer,
                is_otr: response.is_otr
            });
        });
    }

    onSubmit() {
        this.submitted = true;

        if ( !this.form.valid) {
            return;
        }

        const formValue = this.form.value;

        const payload = {
            name: formValue.name,
            customer_id: formValue.customer.id,
            is_otr: formValue.is_otr
        };

        if(this.mode === 'edit') {
            delete payload.is_otr
        }

        this.loading = true;
        this.rigService.saveOrUpdate(payload, this.id).pipe(
            finalize(() => this.loading = false)
        ).subscribe(response => {
            this.snackbar.open('Rig saved successfully', 'Close', {
                duration: 3000
            });
            this.router.navigate(['../'], {
                relativeTo: this.route
            });
        }, () => {
            this.snackbar.open('Unable to save rig', 'Close', {
                duration: 3000
            });
        });
    }

    displayFn(customer: Customer) {
        if (customer) { return customer.name; }
    }
}
