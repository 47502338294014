import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {catchError, finalize} from 'rxjs/operators';
import {Observable, BehaviorSubject, of} from 'rxjs';
import {TrailerType, TrailerTypeService} from "../../../common/trailer-type.service";


/**
 * Data source for the TrailerTypesTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class TrailerTypesTableDataSource implements DataSource<TrailerType> {

    private trailerTypesSubject = new BehaviorSubject<TrailerType[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();
    public pageCount = 0;

    constructor(private trailerTypeService: TrailerTypeService) {

    }

    connect(collectionViewer: CollectionViewer): Observable<TrailerType[]> {
        return this.trailerTypesSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.trailerTypesSubject.complete();
        this.loadingSubject.complete();
    }

    loadTrailerTypes(page: number, limit: number) {
        this.loadingSubject.next(true);

        this.trailerTypeService.find('asc', page, limit).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe((trailerTypes: any) => {
            this.pageCount = trailerTypes.meta.total;
            this.trailerTypesSubject.next(trailerTypes.data)
        });
    }
}
