import {Injectable} from '@angular/core';
import Echo from "laravel-echo";
import * as Pusher from 'pusher-js';

import {SessionService} from "../session.service";
import {AuthenticationService} from "../authentication.service";
import {NotificationService} from "../notification.service";
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class SocketService {

    private echo;

    constructor(private sessionService: SessionService,
                private notificationService: NotificationService,
                private authService: AuthenticationService) {}

    initializeConnection() {

        const wsHost = environment.websocket_host;
        const wsPort = environment.websocket_port;
        const authEndpoint = `${environment.api_url}/broadcasting/auth`;
        const pusherKey = environment.pusher_key;

        const user = this.sessionService.user;
        this.echo = new Echo({
            broadcaster: 'pusher',
            authEndpoint: authEndpoint,
            namespace: 'GStyle',
            disableStats: true,
            client: new Pusher(pusherKey, {
                wsHost: wsHost,
                disableStats: true,
                authEndpoint: authEndpoint,
                wsPort: wsPort,
                enabledTransports:['ws'],
                auth: {
                    headers: {
                        Authorization: 'Bearer ' + this.authService.token
                    },
                },
            })
        });

        this.echo.private(`users.${user.id}`)
            .notification((notification) => {
                this.notificationService.addUnreadNotification(notification);
            });
    }
}
