import * as tslib_1 from "tslib";
import { ApiService } from "./api.service";
import { map, tap } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
var CustomerService = /** @class */ (function () {
    function CustomerService(apiService) {
        this.apiService = apiService;
    }
    CustomerService.prototype.search = function (name, extraParams) {
        if (name === void 0) { name = null; }
        if (extraParams === void 0) { extraParams = {}; }
        if (name == null || name instanceof Object) {
            name = "";
        }
        var options = {
            params: tslib_1.__assign({ search: name }, extraParams)
        };
        return this.apiService.get('customers', options).pipe(tap(function (response) {
            response.results = response.data
                .map(function (customer) {
                return { id: customer.id, name: customer.name };
            });
            return response;
        }));
    };
    CustomerService.prototype.find = function (pageNumber, limit, filters) {
        if (pageNumber === void 0) { pageNumber = 1; }
        if (limit === void 0) { limit = 25; }
        if (filters === void 0) { filters = null; }
        var options = {
            params: {
                page: pageNumber.toString(),
                limit: limit.toString(),
            }
        };
        if (filters !== null) {
            if (filters.sortOrder) {
                options.params['sort_order'] = filters.sortOrder;
            }
            if (filters.sortBy) {
                options.params['sort_by'] = filters.sortBy;
            }
            if (filters.search) {
                options.params['search'] = filters.search;
            }
            if (filters.otr) {
                options.params['is_otr'] = true;
            }
        }
        return this.apiService.get('customers', options);
    };
    CustomerService.prototype.findOne = function (id) {
        return this.apiService.get('customers/' + id).pipe(map(function (res) { return res["data"]; }));
    };
    CustomerService.prototype.saveOrUpdate = function (payload, id) {
        if (id === void 0) { id = null; }
        if (id == null) {
            return this.apiService.post('customers', payload);
        }
        return this.apiService.put('customers/' + id, payload);
    };
    CustomerService.prototype.destroy = function (id) {
        return this.apiService.delete('customers/' + id);
    };
    CustomerService.ngInjectableDef = i0.defineInjectable({ factory: function CustomerService_Factory() { return new CustomerService(i0.inject(i1.ApiService)); }, token: CustomerService, providedIn: "root" });
    return CustomerService;
}());
export { CustomerService };
