import { FormControl } from '@angular/forms';
import * as moment from 'moment';

export function DateValidator(format = null): any {
    return (control: FormControl): { [key: string]: any } => {

        if (control.value == null || control.value === '') {
            return null;
        }

        let val;

        if (format == null) {
            val = moment(control.value);
        } else {
            val = moment(control.value, format);
        }

        if (!val.isValid()) {
            return { invalidDate: true };
        }

        return null;
    };
}
