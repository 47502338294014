<div class="wrapper no-background">
    <ng-container *ngIf="certifications.length == 0">
        <div style="text-align: center">
            <p class="no-items">No Certifications</p>
            <button (click)="openCertificationDialog()" mat-fab color="primary"><mat-icon>add</mat-icon></button>
        </div>
    </ng-container>

    <ng-container *ngIf="certifications.length > 0">
        <h2 class="has-items">Certifications</h2>
        <button (click)="openCertificationDialog()" mat-fab color="primary" class="add-certification"><mat-icon>add</mat-icon></button>

        <div class="clearfix" style="margin-bottom: 50px"></div>

        <mat-card *ngFor="let certification of certifications" style="margin-bottom: 15px">
            <mat-card-header>
                <mat-card-title-group>
                    <mat-card-title>
                        {{ certification.name }}
                    </mat-card-title>
                    <mat-card-subtitle>Expires On: {{ certification.pivot.expires_at }}</mat-card-subtitle>
                </mat-card-title-group>

                <div fxFlex></div>
                <button mat-button (click)="onEdit(certification)">EDIT</button>
                <button mat-button color="warn" (click)="onDelete(certification)">DELETE</button>
            </mat-card-header>
        </mat-card>

    </ng-container>
</div>
