import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

export interface Company {
    id: number;
    name: string;
}

@Injectable({
    providedIn: 'root'
})
export class CompanyService {
    constructor(protected apiService: ApiService) {
    }

    public find(pageNumber = 1, limit = 25, filters = null): any {
        let options = {
            params: {
                page: pageNumber.toString(),
                limit: limit.toString(),
            }
        };

        if (filters !== null) {
            if (filters.sortOrder !== undefined && filters.sortOrder !== null) {
                options.params['sort_order'] = filters.sortOrder;
            }

            if (filters.sortBy !== undefined && filters.sortBy !== null) {
                options.params['sort_by'] = filters.sortBy;
            }

            if (filters.search !== undefined && filters.search !== null && filters.search !== '') {
                options.params['search'] = filters.search;
            }
        }

        return this.apiService.get('companies', options);
    }

    public findOne(id) {
        return this.apiService.get('companies/' + id).pipe(
            map(res => res['data'])
        );
    }

    public saveOrUpdate(payload: { name: string }, id = null) {
        if (id == null) {
            return this.apiService.post('companies', payload);
        }

        return this.apiService.put('companies/' + id, payload);
    }

    public destroy(id) {
        return this.apiService.delete('companies/' + id);
    }

    public findOneLogo($url) {
        return this.apiService.get($url, {
            responseType: 'blob',
            headers: {
              accept: 'image/*'
            }
        }).pipe(
            mergeMap((blobResponse) => {
                return new Observable(observer => {
                    let reader = new FileReader();
                    reader.onloadend = function() {
                        observer.next(reader.result);
                        observer.complete();
                    };
                    reader.readAsDataURL(blobResponse as any as Blob);
                  });
            })
        );
    }
}
