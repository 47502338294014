<div class="materials-edit mat-elevation-z2 wrapper" style="position: relative">

    <ngx-loading [show]="loading"></ngx-loading>

    <h1 *ngIf="mode === 'edit'">Edit {{ materialForm.value.name }}</h1>
    <h1 *ngIf="mode === 'new'">New Material</h1>

    <form class="materials-edit-form" [formGroup]="materialForm" (ngSubmit)="onSubmit()">

        <mat-form-field [floatLabel]="'auto'" style="width: 100%" >
            <input name="material_name" matInput placeholder="Name" required formControlName="name">
            <mat-error *ngIf="materialForm.get('name').hasError('required') && submitted">
                Material name is required.
            </mat-error>
        </mat-form-field>
        <mat-checkbox *ngIf="mode === 'new'" formControlName='is_otr' color="primary" class="otr-checkbox">Is OTR</mat-checkbox>

        <button mat-stroked-button [disabled]="loading" style="margin-top: 15px">
            Save
        </button>
    </form>

</div>