import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
var CertificationUserService = /** @class */ (function () {
    function CertificationUserService(apiService) {
        this.apiService = apiService;
    }
    CertificationUserService.prototype.find = function (userId, sortOrder, pageNumber, limit) {
        if (sortOrder === void 0) { sortOrder = 'asc'; }
        if (pageNumber === void 0) { pageNumber = 1; }
        if (limit === void 0) { limit = 10; }
        return this.apiService.get('users/' + userId + '/certifications', {
            params: {
                page: pageNumber.toString(),
                limit: limit.toString()
            }
        }).pipe(map(function (res) { return res['data']; }));
    };
    CertificationUserService.prototype.saveOrUpdate = function (userId, payload, id) {
        if (id === void 0) { id = null; }
        if (id == null) {
            return this.apiService.post('users/' + userId + '/certifications', payload);
        }
        return this.apiService.put('users/' + userId + '/certifications/' + id, payload);
    };
    CertificationUserService.prototype.destroy = function (id, userId) {
        return this.apiService.delete("users/" + userId + "/certifications/" + id);
    };
    CertificationUserService.ngInjectableDef = i0.defineInjectable({ factory: function CertificationUserService_Factory() { return new CertificationUserService(i0.inject(i1.ApiService)); }, token: CertificationUserService, providedIn: "root" });
    return CertificationUserService;
}());
export { CertificationUserService };
