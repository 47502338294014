import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

export interface CertificationUser {
    id: number;
}

@Injectable({
    providedIn: 'root'
})
export class CertificationUserService {

    constructor(protected apiService: ApiService) {
    }

    public find(userId, sortOrder = 'asc', pageNumber = 1, limit = 10): Observable<CertificationUser[]> {
        return this.apiService.get('users/' + userId + '/certifications', {
            params: {
                page: pageNumber.toString(),
                limit: limit.toString()
            }
        }).pipe(
            map(res => res['data'])
        );
    }

    public saveOrUpdate(userId, payload, id = null) {
        if (id == null) {
            return this.apiService.post('users/' + userId + '/certifications', payload);
        }

        return this.apiService.put('users/' + userId + '/certifications/' + id, payload);
    }

    public destroy(id, userId) {
        return this.apiService.delete(`users/${userId}/certifications/${id}`);
    }
}
