import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {catchError, finalize} from 'rxjs/operators';
import {Observable, BehaviorSubject, of} from 'rxjs';
import {Rig, RigService} from "../../../common/rig.service";

/**
 * Data source for the RigsTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class RigsTableDataSource implements DataSource<Rig> {
    private rigsSubject = new BehaviorSubject<Rig[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();
    public pageCount = 0;

    constructor(private rigService: RigService) {

    }

    connect(collectionViewer: CollectionViewer): Observable<Rig[]> {
        return this.rigsSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.rigsSubject.complete();
        this.loadingSubject.complete();
    }

    loadRigs(page: number, limit: number, filters: object = null) {
        this.loadingSubject.next(true);

        this.rigService.find(page, limit, filters).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
            .subscribe((rigs: any) => {
                this.pageCount = rigs.meta.total;
                this.rigsSubject.next(rigs.data)
            });
    }
}

