import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import {AuthenticationService} from "../../common/authentication.service";
import {ApiService} from "../../common/api.service";

@Component({
    selector: 'app-callback',
    templateUrl: './callback.component.html',
    styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit {

    constructor(private route: ActivatedRoute,
                private router: Router,
                private authenticationService: AuthenticationService,
                private apiService: ApiService) {
    }

    ngOnInit() {
        const fragment = this.route.snapshot.fragment;
        const response = new URLSearchParams(fragment);
        const token = response.get('access_token');

        localStorage.setItem('gstyle.token', token);

        this.authenticationService.token = token;

        this.apiService.get('me').subscribe(response => {
            this.authenticationService.user = response;
            this.router.navigate(['/dispatch']);
        });
    }

}
