import { ApiService } from "./api.service";
import { map } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
var RigService = /** @class */ (function () {
    function RigService(apiService) {
        this.apiService = apiService;
    }
    RigService.prototype.find = function (pageNumber, limit, filters) {
        if (pageNumber === void 0) { pageNumber = 1; }
        if (limit === void 0) { limit = 10; }
        if (filters === void 0) { filters = null; }
        var options = {
            params: {
                page: pageNumber.toString(),
                limit: limit.toString(),
            }
        };
        if (filters !== null) {
            if (filters.sortOrder) {
                options.params['sort_order'] = filters.sortOrder;
            }
            if (filters.sortBy) {
                options.params['sort_by'] = filters.sortBy;
            }
            if (filters.search) {
                options.params['search'] = filters.search;
            }
            if (filters.customerId) {
                options.params['customer'] = filters.customerId;
            }
            if (filters.sortByCustomer) {
                options.params['sort_by_customer'] = filters.sortByCustomer;
            }
            if (filters.is_otr) {
                options.params['is_otr'] = true;
            }
        }
        return this.apiService.get('rigs', options);
    };
    RigService.prototype.findOne = function (id) {
        return this.apiService.get('rigs/' + id).pipe(map(function (res) { return res['data']; }));
    };
    RigService.prototype.saveOrUpdate = function (payload, id) {
        if (id === void 0) { id = null; }
        if (id == null) {
            return this.apiService.post('rigs', payload);
        }
        return this.apiService.put('rigs/' + id, payload);
    };
    RigService.prototype.destroy = function (id) {
        return this.apiService.delete('rigs/' + id);
    };
    RigService.ngInjectableDef = i0.defineInjectable({ factory: function RigService_Factory() { return new RigService(i0.inject(i1.ApiService)); }, token: RigService, providedIn: "root" });
    return RigService;
}());
export { RigService };
