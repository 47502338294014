import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import {environment} from "../../../environments/environment";

@Injectable()
export class AuthenticationGuard implements CanActivate {

    constructor(private router: Router) {}

    canActivate() {
        if (localStorage.getItem('gstyle.token')) {
            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page
        const loginUrl = environment.login_url;
        const clientId = environment.api_client_id;

        window.location.href = `${loginUrl}/redirect?client_id=${clientId}`;
        return false;
    }
}