<div class="filter-form-container" >
    <form [formGroup]="filterForm" fxLayout="row" fxFlex="100">
        <mat-form-field [floatLabel]="'auto'" fxFlex="30">
            <input matInput placeholder="Search" [formControlName]="'search'">
        </mat-form-field>

        <mat-form-field fxFlex="30">
            <mat-select placeholder="Role" [formControlName]="'role'">
                <mat-option value="all">All</mat-option>
                <mat-option value="root" *ngIf="user.role === 'root'">Root Administrator</mat-option>
                <mat-option value="administrator" *ngIf="user.role === 'administrator' || user.role === 'root'">Administrator</mat-option>
                <mat-option value="dispatcher">Dispatcher</mat-option>
                <mat-option value="driver">Driver</mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</div>

<div class="mat-elevation-z8" style="position: relative">

    <ngx-loading [show]="dataSource.loading$ | async"></ngx-loading>

    <table mat-table matSort class="full-width-table" [dataSource]="dataSource" matSort aria-label="Users">
        <!-- Id Column -->
        <ng-container matColumnDef="id">
            <th mat-header-cell mat-sort-header *matHeaderCellDef mat-sort-header>Id</th>
            <td mat-cell *matCellDef="let row">{{row.id}}</td>
        </ng-container>

        <!-- First name Column -->
        <ng-container matColumnDef="first_name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>First</th>
            <td mat-cell *matCellDef="let row">{{row.first_name}}</td>
        </ng-container>

        <!-- Last name Column -->
        <ng-container matColumnDef="last_name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Last</th>
            <td mat-cell *matCellDef="let row">{{row.last_name}}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="email">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Email</th>
            <td mat-cell *matCellDef="let row">{{row.email}}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="role">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Role</th>
            <td mat-cell *matCellDef="let row">{{row.role}}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let element">
                <a class="edit-button" mat-stroked-button [routerLink]="['./', element.id]">
                    Edit
                </a>
                <a mat-stroked-button color="warn" (click)="onDelete(element)">
                    Delete
                </a>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>


    <mat-paginator #paginator
                   [length]="dataSource.pageCount"
                   [pageIndex]="0"
                   [pageSize]="25"
                   [pageSizeOptions]="[25, 50, 100, 250]">
    </mat-paginator>
</div>
