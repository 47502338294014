import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {catchError, finalize} from 'rxjs/operators';
import {Observable, BehaviorSubject, of} from 'rxjs';
import {Company, CompanyService} from '../../../common/company.service';


/**
 * Data source for the CompaniesTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class CompaniesTableDataSource implements DataSource<Company> {
    private companiesSubject = new BehaviorSubject<Company[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();
    public pageCount = 0;

    constructor(private companieservice: CompanyService) {
    }



    connect(collectionViewer: CollectionViewer): Observable<Company[]> {
        return this.companiesSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.companiesSubject.complete();
        this.loadingSubject.complete();
    }

    load(page: number, limit: number) {
        this.loadingSubject.next(true);

        this.companieservice.find(page, limit).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
            .subscribe((companies: any) =>  {
                this.pageCount = companies.meta.total;
                this.companiesSubject.next(companies.data);
            });
    }
}
