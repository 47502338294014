import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatPaginator, MatSnackBar, MatSort} from '@angular/material';
import {CertificationsTableDataSource} from './certifications-table-datasource';
import {CertificationService} from "../../../common/certification.service";
import {tap} from "rxjs/operators";
import {AreYouSureDialogComponent} from "../../ui/are-you-sure-dialog/are-you-sure-dialog.component";

@Component({
    selector: 'app-certifications-table',
    templateUrl: './certifications-table.component.html',
    styleUrls: ['./certifications-table.component.scss']
})
export class CertificationsTableComponent implements OnInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    dataSource: CertificationsTableDataSource;
    pageCount = 0;

    /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
    displayedColumns = ['id', 'name', 'type', 'actions'];

    constructor(private certificationService: CertificationService,
                private snackbar: MatSnackBar,
                private dialog: MatDialog) {}

    ngOnInit() {
        this.dataSource = new CertificationsTableDataSource(this.certificationService);
        this.dataSource.load(1, 25);
    }

    ngAfterViewInit(): void {
        this.paginator.page
            .pipe(
                tap(() => this.loadPage())
            )
            .subscribe();
    }

    loadPage() {
        this.dataSource.load(this.paginator.pageIndex + 1, this.paginator.pageSize);
    }

    onDelete(row) {
        const dialogRef = this.dialog.open(AreYouSureDialogComponent, {
            width: '600px',
            data: { id: row.id, service: this.certificationService }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result && result.deleted) {
                this.loadPage();
                this.snackbar.open("Certification deleted successfully", "Close", {
                    duration: 3000
                });
            }
        });
    }
}
