<div class="materials-edit mat-elevation-z2 wrapper" style="position: relative">
    <ngx-loading [show]="loading"></ngx-loading>

    <h1 *ngIf="mode === 'edit'">Edit Customer</h1>
    <h1 *ngIf="mode === 'new'">New Customer</h1>

    <form class="materials-edit-form" [formGroup]="customerForm" (ngSubmit)="onSubmit()">

        <mat-form-field [floatLabel]="'auto'" style="width: 100%">
            <input matInput placeholder="Name" required formControlName="name" name="name">
            <mat-error *ngIf="customerForm.get('name').hasError('required') && submitted">
                Customer name is required.
            </mat-error>
        </mat-form-field>
        <mat-checkbox *ngIf="mode === 'new'" formControlName='is_otr' color="primary" class="otr-checkbox">Is OTR</mat-checkbox>

        <button mat-stroked-button [disabled]="loading" style="margin-top: 10px;">
            Save
        </button>
    </form>

</div>