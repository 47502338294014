import { ApiService } from './api.service';
import { map, shareReplay } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
var TruckService = /** @class */ (function () {
    function TruckService(apiService) {
        this.apiService = apiService;
        this.cachedGetAll$ = null;
        this.getAll();
    }
    TruckService.prototype.find = function (sortOrder, pageNumber, limit) {
        if (sortOrder === void 0) { sortOrder = 'asc'; }
        if (pageNumber === void 0) { pageNumber = 1; }
        if (limit === void 0) { limit = 25; }
        return this.apiService.get('trucks', {
            params: {
                page: pageNumber.toString(),
                limit: limit.toString()
            }
        });
    };
    TruckService.prototype.findFiltered = function (sortOrder, pageNumber, limit, filters) {
        if (sortOrder === void 0) { sortOrder = 'asc'; }
        if (pageNumber === void 0) { pageNumber = 1; }
        if (limit === void 0) { limit = 25; }
        if (filters === void 0) { filters = null; }
        var options = {
            params: {
                page: pageNumber.toString(),
                limit: limit.toString(),
            }
        };
        if (filters !== null && filters.search !== undefined && filters.search !== null) {
            options.params['search'] = filters.search;
        }
        if (filters !== null && filters.availableAt !== undefined && filters.availableAt !== null) {
            options.params['available_at'] = filters.availableAt;
        }
        return this.apiService.get('bol-trucks', options);
    };
    TruckService.prototype.getAll = function () {
        if (this.cachedGetAll$ === null) {
            this.cachedGetAll$ = this.apiService.get('trucks').pipe(shareReplay(1));
        }
        return this.cachedGetAll$;
    };
    TruckService.prototype.findOne = function (id) {
        return this.apiService.get('trucks/' + id).pipe(map(function (res) { return res['data']; }));
    };
    TruckService.prototype.saveOrUpdate = function (payload, id) {
        if (id === void 0) { id = null; }
        if (id == null) {
            return this.apiService.post('trucks', payload);
        }
        return this.apiService.put('trucks/' + id, payload);
    };
    TruckService.prototype.destroy = function (id) {
        return this.apiService.delete('trucks/' + id);
    };
    TruckService.ngInjectableDef = i0.defineInjectable({ factory: function TruckService_Factory() { return new TruckService(i0.inject(i1.ApiService)); }, token: TruckService, providedIn: "root" });
    return TruckService;
}());
export { TruckService };
