/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./profile.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i3 from "@angular/material/tabs";
import * as i4 from "./change-password/change-password.component.ngfactory";
import * as i5 from "./change-password/change-password.component";
import * as i6 from "@angular/forms";
import * as i7 from "../../common/api.service";
import * as i8 from "@angular/material/snack-bar";
import * as i9 from "./profile.component";
import * as i10 from "../../common/session.service";
var styles_ProfileComponent = [i0.styles];
var RenderType_ProfileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProfileComponent, data: {} });
export { RenderType_ProfileComponent as RenderType_ProfileComponent };
export function View_ProfileComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "materials-edit mat-elevation-z2 wrapper"], ["style", "position: relative"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "mat-tab-group", [["class", "mat-tab-group"]], [[2, "mat-tab-group-dynamic-height", null], [2, "mat-tab-group-inverted-header", null]], null, null, i2.View_MatTabGroup_0, i2.RenderType_MatTabGroup)), i1.ɵdid(2, 3325952, null, 1, i3.MatTabGroup, [i1.ElementRef, i1.ChangeDetectorRef, [2, i3.MAT_TABS_CONFIG]], null, null), i1.ɵqud(603979776, 1, { _tabs: 1 }), (_l()(), i1.ɵeld(4, 16777216, null, null, 5, "mat-tab", [["label", "Change Password"]], null, null, null, i2.View_MatTab_0, i2.RenderType_MatTab)), i1.ɵdid(5, 770048, [[1, 4]], 2, i3.MatTab, [i1.ViewContainerRef], { textLabel: [0, "textLabel"] }, null), i1.ɵqud(335544320, 2, { templateLabel: 0 }), i1.ɵqud(335544320, 3, { _explicitContent: 0 }), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "profile-change-password", [], null, null, null, i4.View_ChangePasswordComponent_0, i4.RenderType_ChangePasswordComponent)), i1.ɵdid(9, 114688, null, 0, i5.ChangePasswordComponent, [i6.FormBuilder, i7.ApiService, i8.MatSnackBar], { user: [0, "user"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "Change Password"; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.user; _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).dynamicHeight; var currVal_1 = (i1.ɵnov(_v, 2).headerPosition === "below"); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_ProfileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-profile", [], null, null, null, View_ProfileComponent_0, RenderType_ProfileComponent)), i1.ɵdid(1, 114688, null, 0, i9.ProfileComponent, [i10.SessionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProfileComponentNgFactory = i1.ɵccf("app-profile", i9.ProfileComponent, View_ProfileComponent_Host_0, {}, {}, []);
export { ProfileComponentNgFactory as ProfileComponentNgFactory };
