import { FormControl } from '@angular/forms';

export function YearValidator(): any {
    return (control: FormControl): { [key: string]: any } => {

        if (control.value == null || control.value === '') {
            return null;
        }
        // to match the backends year(4) https://dev.mysql.com/doc/refman/5.7/en/year.html
        if (control.value < 1901 || control.value > 2155) {
            return { invalidYear: true };
        }

        return null;
    };
}
