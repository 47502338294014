import { ApiService } from './api.service';
import { map, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
var CompanyService = /** @class */ (function () {
    function CompanyService(apiService) {
        this.apiService = apiService;
    }
    CompanyService.prototype.find = function (pageNumber, limit, filters) {
        if (pageNumber === void 0) { pageNumber = 1; }
        if (limit === void 0) { limit = 25; }
        if (filters === void 0) { filters = null; }
        var options = {
            params: {
                page: pageNumber.toString(),
                limit: limit.toString(),
            }
        };
        if (filters !== null) {
            if (filters.sortOrder !== undefined && filters.sortOrder !== null) {
                options.params['sort_order'] = filters.sortOrder;
            }
            if (filters.sortBy !== undefined && filters.sortBy !== null) {
                options.params['sort_by'] = filters.sortBy;
            }
            if (filters.search !== undefined && filters.search !== null && filters.search !== '') {
                options.params['search'] = filters.search;
            }
        }
        return this.apiService.get('companies', options);
    };
    CompanyService.prototype.findOne = function (id) {
        return this.apiService.get('companies/' + id).pipe(map(function (res) { return res['data']; }));
    };
    CompanyService.prototype.saveOrUpdate = function (payload, id) {
        if (id === void 0) { id = null; }
        if (id == null) {
            return this.apiService.post('companies', payload);
        }
        return this.apiService.put('companies/' + id, payload);
    };
    CompanyService.prototype.destroy = function (id) {
        return this.apiService.delete('companies/' + id);
    };
    CompanyService.prototype.findOneLogo = function ($url) {
        return this.apiService.get($url, {
            responseType: 'blob',
            headers: {
                accept: 'image/*'
            }
        }).pipe(mergeMap(function (blobResponse) {
            return new Observable(function (observer) {
                var reader = new FileReader();
                reader.onloadend = function () {
                    observer.next(reader.result);
                    observer.complete();
                };
                reader.readAsDataURL(blobResponse);
            });
        }));
    };
    CompanyService.ngInjectableDef = i0.defineInjectable({ factory: function CompanyService_Factory() { return new CompanyService(i0.inject(i1.ApiService)); }, token: CompanyService, providedIn: "root" });
    return CompanyService;
}());
export { CompanyService };
