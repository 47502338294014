import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

export interface Bol {
  id: number;
  job_id: number;
  truck_id: number;
  trailer_id: number;
  jsa_id: number;
}

@Injectable({
  providedIn: 'root'
})
export class BolService {

  constructor(private apiService: ApiService) {
  }

  public find( status = null, trailerTypeIds = [], ids = []) {

    let params = {};

    if (status != null) {
      params['status'] = status;
    }

    if (ids != null && (Array.isArray(ids) && ids.length !== 0)) {
      params['ids'] = ids;
    }
    if (trailerTypeIds != null && (Array.isArray(trailerTypeIds) && trailerTypeIds.length !== 0)) {
      params['trailerTypeIds'] = trailerTypeIds;

    }

    return this.apiService.get(`jobs.bols`, { params });
  }

  public createJobBol(id: number, options) {
      return this.apiService.post(`jobs/${id}/bols`, options);
  }

  public create(options) {
    return this.apiService.post('bols/', options);
  }

  public update(id, options) {
    return this.apiService.put(`bols/${id}`, options);
  }

}
