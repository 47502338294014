import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavigationComponent} from './navigation/navigation.component';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {DayViewSchedulerComponent} from "./day-view-scheduler/day-view-scheduler.component";
import { StickyModule } from "ng2-sticky-kit";
import { AreYouSureDialogComponent } from './are-you-sure-dialog/are-you-sure-dialog.component';
import {
    MatBadgeModule,
    MatButtonModule,
    MatDialogModule,
    MatListModule,
    MatMenuModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
} from "@angular/material";
import {NgxLoadingModule} from "ngx-loading";
import { NotificationDropdownComponent } from './notification-dropdown/notification-dropdown.component';
import { RouterModule } from "@angular/router";
import { NotificationCardComponent } from './notification-card/notification-card.component';
import { FlexLayoutModule } from "@angular/flex-layout";
import {GooglePlacesDirective} from "./google-places.directive";
import { SearchSelectComponent } from '../search-select/search-select.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        NavigationComponent,
        HeaderComponent,
        FooterComponent,
        DayViewSchedulerComponent,
        AreYouSureDialogComponent,
        NotificationDropdownComponent,
        NotificationCardComponent,
        GooglePlacesDirective,
        SearchSelectComponent
    ],
    imports: [
        CommonModule,
        FlexLayoutModule,
        RouterModule,
        MatButtonModule,
        MatDialogModule,
        StickyModule,
        NgxLoadingModule,
        MatMenuModule,
        MatIconModule,
        MatListModule,
        MatBadgeModule,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
    ],
    exports: [
        DayViewSchedulerComponent,
        NotificationDropdownComponent,
        NotificationCardComponent,
        GooglePlacesDirective,
        SearchSelectComponent
    ],
    entryComponents: [
        AreYouSureDialogComponent
    ]
})
export class UiModule {
}
