import {Component, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

    public logout = `${environment.login_url}/logout`;

    ngOnInit() {
        localStorage.clear();
        window.location.href = this.logout;
    }

}
