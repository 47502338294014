import Echo from "laravel-echo";
import * as Pusher from 'pusher-js';
import { SessionService } from "../session.service";
import { AuthenticationService } from "../authentication.service";
import { NotificationService } from "../notification.service";
import { environment } from "../../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "../session.service";
import * as i2 from "../notification.service";
import * as i3 from "../authentication.service";
var SocketService = /** @class */ (function () {
    function SocketService(sessionService, notificationService, authService) {
        this.sessionService = sessionService;
        this.notificationService = notificationService;
        this.authService = authService;
    }
    SocketService.prototype.initializeConnection = function () {
        var _this = this;
        var wsHost = environment.websocket_host;
        var wsPort = environment.websocket_port;
        var authEndpoint = environment.api_url + "/broadcasting/auth";
        var pusherKey = environment.pusher_key;
        var user = this.sessionService.user;
        this.echo = new Echo({
            broadcaster: 'pusher',
            authEndpoint: authEndpoint,
            namespace: 'GStyle',
            disableStats: true,
            client: new Pusher(pusherKey, {
                wsHost: wsHost,
                disableStats: true,
                authEndpoint: authEndpoint,
                wsPort: wsPort,
                enabledTransports: ['ws'],
                auth: {
                    headers: {
                        Authorization: 'Bearer ' + this.authService.token
                    },
                },
            })
        });
        this.echo.private("users." + user.id)
            .notification(function (notification) {
            _this.notificationService.addUnreadNotification(notification);
        });
    };
    SocketService.ngInjectableDef = i0.defineInjectable({ factory: function SocketService_Factory() { return new SocketService(i0.inject(i1.SessionService), i0.inject(i2.NotificationService), i0.inject(i3.AuthenticationService)); }, token: SocketService, providedIn: "root" });
    return SocketService;
}());
export { SocketService };
