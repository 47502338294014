import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormBuilder, Validators} from '@angular/forms';
import {CertificationService} from '../../../common/certification.service';
import {CertificationUserService} from '../../../common/certification-user.service';
import { SearchableEndpoint } from '../../search-select/search-select.component';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-edit-certification-dialog',
    templateUrl: './edit-certification-dialog.component.html',
    styleUrls: ['./edit-certification-dialog.component.scss']
})
export class EditCertificationDialogComponent implements  OnInit {

    constructor(
        private formBuilder: FormBuilder,
        private certificationService: CertificationService,
        private certificationUserService: CertificationUserService,
        public dialogRef: MatDialogRef<EditCertificationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    public form;
    public certifications = [];
    public loading = false;
    public submitted = false;
    public multiple = true;

    ngOnInit(): void {

        this.form = this.formBuilder.group({
            certification: ['', Validators.required],
            expiration_date: ['', Validators.required]
        });

        this.certificationService.find().subscribe((response: any) => {
            this.certifications = response.data;
        });


        if (this.data.certification) {
            this.multiple = false;
            this.form.patchValue({
                certification: this.data.certification,
                expiration_date: new Date(this.data.certification.pivot.expires_at)
            });
        }
    }

    onSubmit() {
        this.submitted = true;

        if (!this.form.valid) {
            return;
        }

        this.loading = true;
        let formValue = this.form.value;
        let payload;
        let certId = null;

        if (!Array.isArray(formValue.certification)) {
            formValue.certification = new Array(formValue.certification);
            if (this.data.certification) {
                certId = this.data.certification.id;
            }
        }

        for (let cert of formValue.certification) {
            payload = {
                certification_id: cert.id,
                expires_at: formValue.expiration_date.toLocaleDateString('en-us')
            };

            this.certificationUserService.saveOrUpdate(this.data.userId, payload, certId).pipe(
                finalize(() => this.loading = false)
            ).subscribe(() => {
                this.dialogRef.close({ saved: true });
            });
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    getCertificationEndpoint() {
        return ((search: String, page = 1): SearchableEndpoint => {
            return this.certificationService.find(page, 25, {
                sortBy: 'name',
                search,
            }) as any;
        }).bind(this);
    }

}
