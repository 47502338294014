import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {catchError, finalize } from 'rxjs/operators';
import {Observable, BehaviorSubject, of} from 'rxjs';
import {Well, WellService} from '../../../common/well.service';


/**
 * Data source for the WellsTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class WellsTableDataSource implements DataSource<Well> {
    private wellsSubject = new BehaviorSubject<Well[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();
    public pageCount = 0;


    public filters: any = {};

    constructor(private wellService: WellService) {

    }

    connect(collectionViewer: CollectionViewer): Observable<Well[]> {
        return this.wellsSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.wellsSubject.complete();
        this.loadingSubject.complete();
    }

    loadWells(page: number, limit: number) {
        this.loadingSubject.next(true);

        if (this.filters) {
            if (this.filters.status === 'all') {
                this.filters.status = undefined;
            }
        }

        this.wellService.find(page, limit, this.filters).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
            .subscribe((rigs: any) => {
                this.pageCount = rigs.meta.total;
                this.wellsSubject.next(rigs.data);
            });
    }
}

