import {Component, NgZone, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {

  constructor( private zone: NgZone,
               private router: Router) { }

  ngOnInit() {
    this.router.navigate(['/dispatch']);
  }

}
