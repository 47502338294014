import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from "@angular/common/http";
import {AuthenticationService} from "./authentication.service";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    private readonly baseUrl;

    constructor(protected http: HttpClient,
                private authenticationService: AuthenticationService) {

        this.baseUrl = environment.api_url;
    }


    public get<T>(uri, options: any = {}) {
        const url = this.getUrl(uri);

        options.headers = {...this.getHeaders(), ...options.headers};

        let params = new HttpParams();
        for(let key in options.params) {
            params = params.set(key, options.params[key])
        }

        options.params = params;

        return this.http.get<T>(url, options);
    }

    public post(uri, payload = {}, options: any = {}) {
        const url = this.getUrl(uri);
        options.headers = this.getHeaders();

        return this.http.post(url, payload, options);
    }

    public put(uri, payload = {}, options: any = {}) {
        const url = this.getUrl(uri);
        options.headers = this.getHeaders();

        return this.http.put(url, payload, options);
    }

    public delete(uri, options: any = {}) {
        const url = this.getUrl(uri);
        options.headers = this.getHeaders();

        return this.http.delete(url, options);
    }

    private getToken() {
        return this.authenticationService.token;
    }

    private getUrl(uri) {
        return this.baseUrl + '/' + uri;
    }

    private getHeaders() {
        return {
            'Content-Type':  'application/json',
            'Authorization': 'Bearer ' + this.getToken()
        };
    }
}
