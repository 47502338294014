import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {map, shareReplay} from 'rxjs/operators';
import { Observable } from 'rxjs';

export interface Truck {
    name: string;
    id: number;
}

@Injectable({
    providedIn: 'root'
})
export class TruckService {

    public cachedGetAll$ = null;

    constructor(protected apiService: ApiService) {
        this.getAll();
    }

    public find(sortOrder = 'asc', pageNumber = 1, limit = 25) {

        return this.apiService.get('trucks', {
            params: {
                page: pageNumber.toString(),
                limit: limit.toString()
            }
        });
    }

    public findFiltered(sortOrder = 'asc', pageNumber = 1, limit = 25, filters = null) {

        let options = {
            params: {
                page: pageNumber.toString(),
                limit: limit.toString(),
            }
        };

        if (filters !== null && filters.search !== undefined && filters.search !== null) {
            options.params['search'] = filters.search;
        }
        if (filters !== null && filters.availableAt !== undefined && filters.availableAt !== null) {
            options.params['available_at'] = filters.availableAt;
        }

        return this.apiService.get('bol-trucks', options);
    }

    public getAll() {
        if (this.cachedGetAll$ === null) {
            this.cachedGetAll$ = this.apiService.get('trucks').pipe(shareReplay(1));
        }

        return this.cachedGetAll$;
    }

    public findOne(id) {
        return this.apiService.get('trucks/' + id).pipe(
            map(res => res['data'])
        );
    }

    public saveOrUpdate(payload: { name: string }, id = null) {
        if (id == null) {
            return this.apiService.post('trucks', payload);
        }

        return this.apiService.put('trucks/' + id, payload);
    }

    public destroy(id) {
        return this.apiService.delete('trucks/' + id);
    }
}
