import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { KeepTruckinUserService } from '../../../common/keep-truckin-user.service';
import { SearchableEndpoint } from '../../search-select/search-select.component';

@Component({
    selector: 'app-keep-truckin-truck-dialog',
    templateUrl: './keep-truckin-truck-dialog.component.html',
    styleUrls: ['./keep-truckin-truck-dialog.component.scss']
})
export class KeepTruckinTruckDialogComponent implements OnInit {

    public form;
    public filteredUsers = [];
    public isLoading = false;

    constructor(
        private formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<KeepTruckinTruckDialogComponent>,
        private keepTruckinUserService: KeepTruckinUserService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            truck: null
        });
    }

    displayFn(user) {
        if (user) { return user.first_name; }
    }

    onSubmit() {
        this.dialogRef.close({ selected: true, truck: this.form.value });
    }

    onNoClick(): void {
        this.dialogRef.close({ selected: false});
    }

    formatFieldName() {
        return (truck) => {
            if (truck.vehicle) {
                let atruck = truck.vehicle;
                return (atruck.number +
                    '  |  Plate: ' + atruck.license_plate_number +
                    '  |  Vin: ' + atruck.vin +
                    '  |  Keep Truckin Id: ' + atruck.id
                );
            }
        };
    }

    getEndpoint(): SearchableEndpoint {
        return ((search: String, page = 1) => {
            return this.keepTruckinUserService.searchTrucks(page, 25, { search: search });
        }).bind(this);
      }
}
