import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

export interface User {
    id: number;
    email: string;
    role: string;
}

@Injectable({
    providedIn: 'root'
})
export class UserService {
    constructor(protected apiService: ApiService) {
    }

    public find(pageNumber = 1, limit = 10, filters = null) {

        let options = {
            params: {
                page: pageNumber.toString(),
                limit: limit.toString(),
                role: 'all'
            }
        };

        if (filters !== null) {
            if (filters.sortOrder !== undefined && filters.sortOrder !== null) {
                options.params['sort_order'] = filters.sortOrder;
            }

            if (filters.sortBy !== undefined && filters.sortBy !== null) {
                options.params['sort_by'] = filters.sortBy;
            }

            if (filters.role !== undefined && filters.role !== null ) {
                options.params['role'] = filters.role;
            }

            if (filters.search !== undefined && filters.search !== null && filters.search !== '') {
                options.params['search'] = filters.search;
            }
        }

        return this.apiService.get('users', options);
    }

    public getAvailableDrivers(status, department, search, page) {
        let uri = `schedulable-drivers?page=${page}&status=${status}&search=${search}&department=${department}`;
        return this.apiService.get(uri);
    }

    public findOne(id) {
        return this.apiService.get('users/' + id).pipe(
            map(res => res['data'])
        );
    }

    public saveOrUpdate(payload: { email: string }, id = null) {
        if (id == null) {
            return this.apiService.post('users', payload)
        }

        return this.apiService.put('users/' + id, payload);
    }

    public destroy(id) {
        return this.apiService.delete('users/' + id);
    }

    public findDispatchers(options = {}) {
        return this.apiService.get(`dispatchers`, options);
    }

    public uploadAvatar(payload) {
        return this.apiService.post('me/avatar', payload);
    }
}
