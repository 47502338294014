/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./wells.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "./wells-table/wells-table.component.ngfactory";
import * as i5 from "./wells-table/wells-table.component";
import * as i6 from "../../common/well.service";
import * as i7 from "@angular/material/snack-bar";
import * as i8 from "@angular/forms";
import * as i9 from "@angular/material/dialog";
import * as i10 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i11 from "@angular/material/button";
import * as i12 from "@angular/cdk/platform";
import * as i13 from "@angular/cdk/a11y";
import * as i14 from "@angular/platform-browser/animations";
import * as i15 from "@angular/router";
import * as i16 from "@angular/common";
import * as i17 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i18 from "@angular/material/icon";
import * as i19 from "./wells.component";
var styles_WellsComponent = [i0.styles];
var RenderType_WellsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WellsComponent, data: {} });
export { RenderType_WellsComponent as RenderType_WellsComponent };
export function View_WellsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["fxLayout", "column"], ["style", "width: 80%; margin: auto"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Wells"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-wells-table", [], null, null, null, i4.View_WellsTableComponent_0, i4.RenderType_WellsTableComponent)), i1.ɵdid(5, 4308992, null, 0, i5.WellsTableComponent, [i6.WellService, i7.MatSnackBar, i8.FormBuilder, i9.MatDialog], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 6, "a", [["class", "new-button"], ["color", "primary"], ["mat-fab", ""]], [[1, "tabindex", 0], [1, "disabled", 0], [1, "aria-disabled", 0], [2, "_mat-animation-noopable", null], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7)._haltDisabledEvents($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i10.View_MatAnchor_0, i10.RenderType_MatAnchor)), i1.ɵdid(7, 180224, null, 0, i11.MatAnchor, [i12.Platform, i13.FocusMonitor, i1.ElementRef, [2, i14.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(8, 671744, null, 0, i15.RouterLinkWithHref, [i15.Router, i15.ActivatedRoute, i16.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(9, 1), (_l()(), i1.ɵeld(10, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i17.View_MatIcon_0, i17.RenderType_MatIcon)), i1.ɵdid(11, 9158656, null, 0, i18.MatIcon, [i1.ElementRef, i18.MatIconRegistry, [8, null], [2, i18.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["add"]))], function (_ck, _v) { var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); _ck(_v, 5, 0); var currVal_7 = "primary"; _ck(_v, 7, 0, currVal_7); var currVal_8 = _ck(_v, 9, 0, "./new"); _ck(_v, 8, 0, currVal_8); _ck(_v, 11, 0); }, function (_ck, _v) { var currVal_1 = (i1.ɵnov(_v, 7).disabled ? (0 - 1) : (i1.ɵnov(_v, 7).tabIndex || 0)); var currVal_2 = (i1.ɵnov(_v, 7).disabled || null); var currVal_3 = i1.ɵnov(_v, 7).disabled.toString(); var currVal_4 = (i1.ɵnov(_v, 7)._animationMode === "NoopAnimations"); var currVal_5 = i1.ɵnov(_v, 8).target; var currVal_6 = i1.ɵnov(_v, 8).href; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_9 = i1.ɵnov(_v, 11).inline; var currVal_10 = (((i1.ɵnov(_v, 11).color !== "primary") && (i1.ɵnov(_v, 11).color !== "accent")) && (i1.ɵnov(_v, 11).color !== "warn")); _ck(_v, 10, 0, currVal_9, currVal_10); }); }
export function View_WellsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-wells", [], null, null, null, View_WellsComponent_0, RenderType_WellsComponent)), i1.ɵdid(1, 114688, null, 0, i19.WellsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WellsComponentNgFactory = i1.ɵccf("app-wells", i19.WellsComponent, View_WellsComponent_Host_0, {}, {}, []);
export { WellsComponentNgFactory as WellsComponentNgFactory };
