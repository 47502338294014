import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import { tap } from 'rxjs/internal/operators/tap';
import * as Sentry from '@sentry/browser';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { throwError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SessionService {

    private _user = null;

    get user() {
        return this._user;
    }

    set user(user) {
        this._user = user;
        // Add a little extra sentry context
        Sentry.configureScope(scope => {
            scope.setUser({
                id: user.id,
                role: user.role,
                title: user.title,
                email: user.email,
            });
        });
    }

    constructor(
        private apiService: ApiService,
        private authService: AuthenticationService,
    ) {}

    public fetch() {
        return this.apiService.get('me').pipe(
            catchError((error: any) => {
                if (error.status === 401) {
                    this.authService.logout();
                }
                return throwError(error);
            }),
            tap(response => {
            this.user = response;
        }));
    }
}
