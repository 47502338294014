import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable} from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';

import { map, catchError } from 'rxjs/operators';
import {Router} from "@angular/router";
import {environment} from "../../environments/environment";

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {

    constructor(private router: Router) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(map(event => {
            return event;
        }), catchError(err => {
            // not logged in so redirect to login page
            const apiUrl = environment.api_url;
            const clientId = environment.api_client_id;

            if(err.status == 401) {
                window.location.href = `${apiUrl}/redirect?client_id=${clientId}`;
            }

            return observableThrowError(err);
        }));
    }

}

