import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material";
import {ActivatedRoute, Router} from "@angular/router";
import {LocationsService} from "../../common/locations.service";
import {LocationTypesService} from "../../common/location-types.service";
import {ValidateCoordinates} from "../../common/coordinates.validator";
import  * as Coordinates from 'coordinate-parser';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-edit-location',
    templateUrl: './edit-location.component.html',
    styleUrls: ['./edit-location.component.scss']
})
export class EditLocationComponent implements OnInit {
    public form: FormGroup;
    public mode: 'new' | 'edit' = 'new';
    public id?: number = null;
    public loading: boolean = true;
    public types = [];
    public submitted = false;
    public addressEnabled = false;
    public selectedAddress = '';

    @ViewChild('addressInput') addressInput;

    constructor(private formBuilder: FormBuilder,
                private locationsService: LocationsService,
                private locationTypesService: LocationTypesService,
                private snackbar: MatSnackBar,
                private router: Router,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            name: ['', Validators.required],
            type_id: [null, Validators.required],
            format: ['gps', Validators.required],
            address: null,
            location: ['', Validators.compose([Validators.required, ValidateCoordinates])],
            notes: null
        });

        const idParam = this.route.snapshot.params['id'];

        if (idParam && idParam !== 'new') {
            this.mode = 'edit';
            this.id = +idParam;
            this.find(this.id);
        } else {
            this.loading = false;
        }

        this.locationTypesService.find().subscribe((response: any) => {
            this.types = response.data;
        });

        this.form.get('format').valueChanges.subscribe(event => {
            if(event === 'address') {
                this.addressEnabled = true;
                this.form.get('location').disable();
            } else {
                this.addressEnabled = false;
                this.form.get('location').enable();
            }
        });
    }

    find(id: number) {
        this.locationsService.findOne(id).pipe(
            finalize(() => this.loading = false)
        ).subscribe((response: any) => {
            this.form.patchValue({
                name: response.data.name,
                type_id: response.data.type_id,
                format: response.data.format,
                location: response.data.location,
                address: response.data.address,
                notes: response.data.notes
            });

            this.selectedAddress = response.data.address;
            this.addressInput.nativeElement.value = this.selectedAddress;
        });
    }


    onAutocompleteSelected(result) {
        this.form.patchValue({
            address: result.formatted_address
        });
    }

    onLocationSelected(location) {
        this.form.patchValue({
            location: `${location.latitude}, ${location.longitude}`,
        });
    }

    onSubmit() {
        this.submitted = true;

        if (!this.form.valid) {
            return;
        }

        const formValue = this.form.getRawValue();

        let payload = {...formValue};

        Object.keys(payload).forEach((key) => (payload[key] == null) && delete payload[key]);

        this.loading = true;
        this.locationsService.saveOrUpdate(payload, this.id).pipe(
            finalize(() => this.loading = false)
        ).subscribe(response => {
            this.snackbar.open('Location saved successfully', 'Close', {
                duration: 3000
            });

            this.router.navigate(['../'], {
                relativeTo: this.route
            });
        }, () => {
            this.snackbar.open('Unable to save location', 'Close', {
                duration: 3000
            });
        });
    }


}
