import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map, flatMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JobSafetyAnalysisService {

  constructor(protected apiService: ApiService) {
  }

  signatureImages = [];

  // make methods for gathering data for JSA forms 

  public find(filters = null): any {
    let options = { params: {} };

    if (filters !== undefined && filters !== null) {
      if (filters.page !== undefined && filters.page !== null) {
        options.params['page'] = filters.page;
      }
      if (filters.limit !== undefined && filters.limit !== null) {
        options.params['limit'] = filters.limit;
      }
      if (filters.bol_id !== undefined && filters.bol_id !== null) {
        options.params['bol_id'] = filters.bol_id;
      }
    }

    return this.apiService.get('jsas/', options);
  }

  saveOrUpdate(jsaData, id = null) {
    if (id == null) {
      return this.apiService.post('jsas', jsaData);
    }
    return this.apiService.put(`jsas/${id}`, jsaData)
  }

  findByIds(ids): Observable<any> {
    return this.apiService.get('jsas', {
      params: {
        ids: ids
      }
    });
  }

  findSignature(signatureType, jsaId) {
    return this.apiService.get(`signatures/${signatureType}/${jsaId}`, { responseType: 'blob' }).pipe(flatMap(response => {
      let blob = response as unknown as Blob;
      return this.readImageData(blob);
    }));
  }

  // This takes the blob and returns an observable converting it to base64 data
  readImageData(blob: Blob): Observable<any> {
    return Observable.create(observer => {
      let reader = new FileReader();
      reader.onloadend = function () {
        observer.next(reader.result);
        observer.complete();
      };
      reader.readAsDataURL(blob);
    });
  }
}
