import { Injectable, ErrorHandler } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from '../../environments/environment';

class SentryErrorHandler implements ErrorHandler {

  constructor() {
    Sentry.init({
      dsn: environment.sentry_dsn,
      // remove some duplication https://github.com/getsentry/sentry-javascript/issues/2169
      integrations(integrations) {
        return integrations.filter(i => i.name !== 'TryCatch');
      }
    });
  }

  handleError(error) {
    Sentry.configureScope(function (scope) {
      if (error.error) {
        scope.setExtra('error', JSON.stringify(error.error));
      }
      const eventId = Sentry.captureException(error.originalError || error);
      // collect user feedback.
      Sentry.showReportDialog({ eventId });
      if (!environment.production) {
        console.error(error);
      }
    });
  }
}

export function getErrorHandler(): ErrorHandler {
  if (environment.sentry_dsn && environment.sentry_dsn !== '') {
    return new SentryErrorHandler();
  }
  return new ErrorHandler();
}
