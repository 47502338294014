<div class="materials-edit mat-elevation-z2 wrapper" style="position: relative">
    <ngx-loading [show]="loading"></ngx-loading>

    <h1 *ngIf="mode === 'edit'">Edit Well</h1>
    <h1 *ngIf="mode === 'new'">New Well</h1>

    <form autocomplete="nope" class="wells-edit-form" [formGroup]="form" (ngSubmit)="onSubmit()">

        <mat-checkbox *ngIf="mode === 'new'" formControlName='is_otr' color="primary" class="otr-checkbox">Is OTR</mat-checkbox>

        <mat-form-field [floatLabel]="'auto'" style="width: 100%; margin-bottom: 15px;">
            <input autocomplete="off" name="well_name" matInput placeholder="Name" required [formControlName]="'name'">
            <mat-error *ngIf="(form.get('name').hasError('required') || form.get('name').hasError('invalidWhitespace')) && submitted">
                Well name is required.
            </mat-error>
        </mat-form-field>


        <mat-form-field [floatLabel]="'auto'" style="width: 100%; margin-bottom: 25px;">
            <input name="location" autocomplete="off" matInput placeholder="Location" [formControlName]="'location'">
            <mat-error *ngIf="submitted && form.get('location').errors &&
            form.get('location').errors.invalidCoordinates">
                Field must contain valid GPS coordinates.
            </mat-error>
            <mat-error *ngIf="submitted && form.get('location').errors &&
            form.get('location').errors.required">
                GPS Coordinates are required.
            </mat-error>
            <mat-hint>GPS Coordinates</mat-hint>
        </mat-form-field>

        <app-search-select class="search-select" formControlName="rig" [endpoint]="getEndpointRig()" [placeholder]="'Rig'" [shouldRefresh]="this.shouldRefresh" ></app-search-select>


        <app-search-select class="search-select" formControlName="customer" [endpoint]="getEndpointCustomer()" [placeholder]="'Customer'" [shouldRefresh]="this.shouldRefresh" >
        </app-search-select>
        <span *ngIf="form.get('customer').hasError('required') && submitted" class="mat-error error">Customer is required</span>


        <mat-slide-toggle style="margin-bottom: 20px"
                          formControlName="active"
                          color="primary">
            Active
        </mat-slide-toggle>
        <br style="clear: both"/>

        <button mat-stroked-button [disabled]="loading">
            Save
        </button>
    </form>

</div>
