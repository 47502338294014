import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatMenuTrigger } from "@angular/material";
import {SocketService} from "../common/sockets/socket.service";
import {SessionService} from "../common/session.service";

@Component({
    selector: 'app-dispatch',
    templateUrl: './dispatch.component.html',
    styleUrls: ['./dispatch.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DispatchComponent implements OnInit {

    @ViewChild(MatMenuTrigger) userMenuTrigger: MatMenuTrigger;
    @ViewChild(MatMenuTrigger) jobMenuTrigger: MatMenuTrigger;
    @ViewChild(MatMenuTrigger) vehicleMenuTrigger: MatMenuTrigger;
    @ViewChild(MatMenuTrigger) locationMenuTrigger: MatMenuTrigger;
    @ViewChild(MatMenuTrigger) peopleMenuTrigger: MatMenuTrigger;
    @ViewChild(MatMenuTrigger) workMenuTrigger: MatMenuTrigger;
    @ViewChild(MatMenuTrigger) ddTrigger: MatMenuTrigger;

    public user;

    constructor(private socketService: SocketService, private sessionService: SessionService) {
    }

    ngOnInit() {
        this.socketService.initializeConnection();
        this.user = this.sessionService.user;
    }

}
