import { ApiService } from './api.service';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
var CertExpirationService = /** @class */ (function () {
    function CertExpirationService(apiService) {
        this.apiService = apiService;
        this.uri = 'cert-expiration-subscriptions';
    }
    CertExpirationService.prototype.find = function (pageNumber, limit, embed) {
        if (pageNumber === void 0) { pageNumber = 1; }
        if (limit === void 0) { limit = 5; }
        if (embed === void 0) { embed = 'user'; }
        var options = {
            params: {
                page: pageNumber,
                limit: limit,
                embed: embed
            }
        };
        return this.apiService.get(this.uri, options);
    };
    CertExpirationService.prototype.show = function (id) {
        return this.apiService.get(this.uri + '/' + id);
    };
    CertExpirationService.prototype.delete = function (id) {
        return this.apiService.delete(this.uri + '/' + id);
    };
    CertExpirationService.prototype.save = function (certExpiration) {
        var payload = {
            user_id: certExpiration.user_id
        };
        return this.apiService.post(this.uri, payload);
    };
    CertExpirationService.ngInjectableDef = i0.defineInjectable({ factory: function CertExpirationService_Factory() { return new CertExpirationService(i0.inject(i1.ApiService)); }, token: CertExpirationService, providedIn: "root" });
    return CertExpirationService;
}());
export { CertExpirationService };
