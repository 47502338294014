import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {Observable} from "rxjs";
import {ApiResponse} from "./interfaces/api-response.interface";
import {map} from "rxjs/operators";


export interface BillingType {
    name: string;
    id: number;
    rate: number;
}


@Injectable({
    providedIn: 'root'
})
export class BillingTypeService {

    constructor(protected apiService: ApiService) {
    }

    public find(pageNumber = 1, limit = 10, filters = null) {

        let options = {
            params: {
                page: pageNumber.toString(),
                limit: limit.toString(),
            }
        };

        if (filters !== null) {
            if (filters.sortOrder !== undefined && filters.sortOrder !== null) {
                options.params['sort_order'] = filters.sortOrder;
            }

            if (filters.sortBy !== undefined && filters.sortBy !== null) {
                options.params['sort_by'] = filters.sortBy;
            }

            if (filters.search !== undefined && filters.search !== null && filters.search !== '') {
                options.params['search'] = filters.search;
            }
        }

        return this.apiService.get('billing-types', options);
    }

    public findOne(id) {
        return this.apiService.get('billing-types/' + id).pipe(
            map(res => res["data"])
        );
    }

    public saveOrUpdate(payload: { name: string }, id = null) {
        if (id == null) {
            return this.apiService.post('billing-types', payload)
        }

        return this.apiService.put('billing-types/' + id, payload);
    }

    public destroy(id) {
        return this.apiService.delete('billing-types/' + id);
    }

}
